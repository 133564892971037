.mainLoad{
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%; 
    overflow: hidden;
    z-index: 1000;
}

.homeLoader{
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: black;
    /* background-image: url("https://graha.s3.us-east-2.amazonaws.com/GRAHA_b8d84b5a72.gif"); */
    /* transition: all 1s ease-out; */
}

@media screen and (max-width: 900px) and (orientation: portrait) {
    .homeLoader{
        background-image: url("https://graha.zetgo.in/uploads/ezgif_4_33bbf62fa9_c24f8baee7.gif");
    }
}

@-webkit-keyframes fadeOut {
    0% { opacity: 1;}
    99% { opacity: 0.01;width: 100%; height: 100%;}
    100% { opacity: 0;width: 0; height: 0;}
}  
@keyframes fadeOut {
    0% { opacity: 1;}
    99% { opacity: 0.01;width: 100%; height: 100%;}
    100% { opacity: 0;width: 0; height: 0;}
}
