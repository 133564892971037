.gallery{
    position:fixed;
    top:0;
    left:0;
    bottom:0;
    right:0;
    height:100%;
    width:100%;
    background: rgba(255,255,255,.97);
    z-index: 1000;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.gallery_container{
    overflow-y: hidden;
    overflow-x: scroll;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(auto-fill,30vw);
    margin: 3em 3em;
}

.thumb_image{
    box-shadow: none !important;
}


@media only screen 
    and (min-width: 900px){

        .gallery_container{
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(auto-fill,27.5vw);
            column-gap: 15px;
            margin-top: 3em;
            margin-left: 1.4em;
            margin-right: 1.4em;
        }

        .thumb_image{
            width: 45vw;
            height: 25vw;
            box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
        }
}

@media only screen 
    and (max-width: 790px){
        .gallery_container{
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(auto-fill,25vw);
            grid-column-gap: 15px;
            column-gap: 15px;
            grid-row-gap: 15px;
            row-gap: 20px;
            margin-top: 3em;
            margin-left: 1.4em;
            margin-right: 1.4em;
        }
    
        .thumb_image{
            width: 45vw;
            height: 25vw;
            box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
        }
}


@media only screen 
    and (min-width: 790px){

    .gallery_container{
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(auto-fill,25.5vw);
        column-gap: 15px;
        row-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
    }

    .thumb_image{
        width: 45vw;
        height: 25vw;
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

}


/* Smartphone portrait */

@media only screen 
  and (min-width: 600px)
  and (max-width : 720px)
  and (orientation : portrait){
    .gallery{
        display: none;
    }
}

@media only screen
  and (min-width : 360px) 
  and (max-width : 428px)
  and (orientation : portrait){
    .gallery{
        display: none;
    }
}

@media only screen 
  and (min-width : 240px)
  and (max-width : 320px)
  and (orientation : portrait){

    .gallery{
        display: none;
    }
}


/* Smartphone Landscape */

@media only screen  
  and (min-width : 320px)
  and (max-width : 460px)
  and (orientation : landscape){
    .gallery{
        display: none;
    }
}

@media only screen  
  and (min-width : 500px)
  and (max-width : 850px)
  and (orientation : landscape){
  
    .gallery{
        /* display: none; */
    }
}


@media only screen  
  and (min-width : 896px)
  and (max-width : 900px)
  and (orientation : landscape){
    .gallery{
        display: none;
    }
}

@media only screen 
and (max-width: 768px)
and (orientation : portrait) {
    .gallery_container{
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(auto-fill,25vw);
        row-gap: 100px;
        column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        row-gap: 15px;
        margin-right: 1.4em;
    }
    .thumb_image{
        width: 45vw;
        height: 25vw;
    }
}

@media only screen 
  and (min-width: 768px) 
  and (max-width: 880px) 
  and (orientation : portrait){
    .gallery_container{
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(auto-fill,31vw);
        row-gap: 100px;
        column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        row-gap: 15px;
        margin-right: 1.4em;
    }

    .thumb_image{
        width: 45vw;
        height: 20vh;
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }
}

@media only screen 
  and (min-width: 768px) 
  and (min-height: 1280px) 
  and (orientation : portrait){
    .gallery_container{
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(auto-fill,32vw);
        row-gap: 100px;
        column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
        row-gap: 15px;
    }

    .thumb_image{
        width: 45vw;
        height: 20vh;
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }
}

/*Ipad Potrait*/
@media only screen 
and (min-width : 768px) 
and (max-width : 1024px)
and (max-height: 1024px) 
and (orientation : portrait) {
    .gallery_container{
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(auto-fill,25vw);
        row-gap: 100px;
        column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
        row-gap: 15px;
    }

    .thumb_image{
        width: 45vw;
        height: 25vw;
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }
}

/* IPad Pro 10.5 Portrait */
@media only screen 
  and (min-width: 834px)
  and (max-width: 834px) 
  and (orientation: portrait) {
    .gallery_container{
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(auto-fill,28vw);
        column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
        row-gap: 15px;
    }

    .thumb_image{
        width: 45vw;
        height: 20vh;
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }
}

/* IPad Pro 12.5 Portrait */
@media only screen
and (min-width: 1024px)
and (max-width: 1024px)
and (orientation: portrait){
    .gallery_container{
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(auto-fill,26.5vw);
        column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
    }

    .thumb_image{
        width: 45vw;
        height: 20vh;
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }
}

@media only screen 
  and (min-width: 1024px) 
  and (max-width: 1080px)  
  and (min-height : 800px) 
  and (max-height : 900px) {

    .gallery_container{
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(auto-fill,26.5vw);
        column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
        row-gap: 15px;
    }

    .thumb_image{
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }
}

@media only screen  and (min-width : 1280px) {

    .gallery_container{
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(auto-fill,21vw);
        column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
        row-gap: 15px;
    }

    .thumb_image{
        width: 30vw;
        height: 20vw;
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }
}

@media only screen 
  and (min-width : 1280px) 
  and (min-height : 740px) 
  and (max-height : 800px) {

    .gallery_container{
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(auto-fill,20.5vw);
        column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
        row-gap: 15px;
    }

    .thumb_image{
        width: 30vw;
        height: 20vw;
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }
}

/* Basic Laptop Screens */
@media only screen and (min-width : 1440px) {

    .gallery_container{
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(auto-fill,20.5vw);
        column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
        row-gap: 15px;
    }

    .thumb_image{
        width: 30vw;
        height: 20vw;
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }
}

@media only screen and (min-width: 1500px){

    .gallery_container{
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(auto-fill,16.5vw);
        column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
        row-gap: 15px;
    }

    .thumb_image{
        width: 23vw;
        height: 16vw;
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }
}

/* Macbook Pro */
@media only screen 
  and (min-width: 1700px) 
  and (max-width: 1800px)  
  and (min-height : 1080px) 
  and (max-height : 1100px) {
   .gallery_container{
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(auto-fill,16.5vw);
        row-gap: 85px;
        column-gap: 10px;
   }

   .thumb_image{
    width: 23vw;
    height: 16vw;
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

}



/* Landscape Orientation for Tablets and Mobiles */


/* Tablets and IPads */

@media only screen 
  and (min-width: 960px) 
  and (max-width: 1023px) 
  and (orientation : landscape){

    .gallery_container{
        grid-template-rows: repeat(auto-fill,25.5vw);
        row-gap: 15px;
        column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
    }

    .thumb_image{
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }


}

@media only screen 
  and (min-width: 1179px) 
  and (max-width: 1023px) 
  and (orientation : landscape){

    .gallery_container{
        grid-template-rows: repeat(auto-fill,20vw);
        row-gap: 15px;
        column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
    }

    .thumb_image{
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }


}

/*Ipad Landscape*/
@media only screen 
and (min-height : 1024px)
and (max-height : 1024px) 
and (min-width : 768px)
and (max-width : 768px) 
and (orientation : landscape) {

    .gallery_container{
        grid-template-rows: repeat(auto-fill,23.5vw);
        row-gap: 15px;
        column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
    }

    .thumb_image{
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }

}

/* IPad Pro 10.5 Landscape */
@media only screen 
  and (min-width: 834px)
  and (max-width: 834px) 
  and (orientation: landscape) {
    .gallery_container{
        
        row-gap: 15px;
        column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
    }

    .thumb_image{
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }
}

/* IPad Pro 12.5 Landscape */
@media only screen
and (min-width: 1024px)
and (max-width: 1024px)
and (orientation: landscape){
    .gallery_container{
        grid-template-rows: repeat(auto-fill,25.5vw);
        row-gap: 15px;
        column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
    }

    .thumb_image{
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }
}

@media only screen
  and (min-width: 1366px)
  and (max-width: 1366px)
  and (orientation: landscape){
    .gallery_container{
        grid-template-rows: repeat(auto-fill,20vw);
        row-gap: 15px;
        column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
    }

    .thumb_image{
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }
}

@media only screen
  and (min-width: 1112px)
  and (max-width: 1112px)
  and (orientation: landscape){
    .gallery_container{
        grid-template-rows: repeat(auto-fill,18.5vw);
        row-gap: 15px;
        column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
    }

    .thumb_image{
        height: 25vh;
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }
}
