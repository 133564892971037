body {
  /* animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  appearance: none; */
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.carousel-control-prev , .carousel-control-next{
  visibility: hidden !important;
}

.mediaBox, .carousel-item {
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: black;
  /* background-image: url("https://graha.s3.us-east-2.amazonaws.com/GRAHA_POSTER_gif_d73a03689c.gif"); */
}


.full_screen_video_wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%; 
  overflow: hidden;
  z-index: -2;
  background-color: black;
}

.backgroundImage {
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: black;
  /* background-image: url("https://graha.s3.us-east-2.amazonaws.com/GRAHA_POSTER_gif_d73a03689c.gif"); */
}

