@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");

 

.studioPage {
  font-family: "Roboto", sans-serif;
  width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  margin-top: 1em !important;
}
  
.studioPage h2 {
  letter-spacing: 5px;
  color: #9e9e9e;
  margin: 12vh 0;
}

.nav{
  height: 5.2vw;
  background-color: white;
}


.nav > ul {
  color: #9e9e9e;
  list-style-type: none;
  display: flex;
  font-family: Roboto;
  justify-content: space-between;
  margin: 0 30vw 2vw 30vw;
  font-size: 1.1em;
  position: relative;
  top: 50%;
}

.nav_links,
.nav_links:hover{
  color: #9e9e9e;
  text-decoration: none;
  
}

.activeLink{
  transform: scale(1.5);
}

.container{
  height: auto;
  padding-bottom: 10vh;
  margin: 2em 0;
}

  @media only screen 
    and (max-width: 900px)
    and (orientation : portrait){

    .nav{
      height: 25vw;
    }

    .nav > ul{
      margin: 0 25vw;
      justify-content: space-between;
    }
    
  }

  @media only screen 
    and (max-width : 900px)
    and (orientation : landscape){

    .nav > ul{
      margin: 0 20vw;
      justify-content: space-between;
    }

  }
  
/* Smartphone portrait */

@media only screen 
  and (max-width:240px){

    .container{
        margin: 2em 9vw;
    } 

}


@media only screen 
  and (min-width : 240px)
  and (max-width : 360px)
  and (orientation : portrait){

    .container{
        margin: 2em 8vw;
    } 
}

@media only screen
  and (min-width : 360px) 
  and (max-width : 399px)
  and (orientation : portrait){

    .container{
        margin: 2em 8vw;
    }     
}


@media only screen
  and (min-width : 400px) 
  and (max-width : 428px)
  and (orientation : portrait){
    .container{
        margin: 2em 7.5vw;
    } 
}

@media only screen
  and (min-width : 428px) 
  and (max-width : 500px)
  and (orientation : portrait){
    .container{
        margin: 2em 9vw;
    } 
}

@media only screen
  and (min-width : 500px) 
  and (max-width : 600px)
  and (orientation : portrait){

    .container{
        margin: 2em 9vw;
    } 
    
}

@media only screen 
  and (min-width: 600px)
  and (max-width : 800px)
  and (orientation : portrait){

    .container{
        margin: 2em 5.3vw;
    } 
    

}



/* Smartphone Landscape */

@media only screen  
  and (min-width : 320px)
  and (max-width : 900px)
  and (orientation : landscape){
    .container{
        margin : 2em 4.9vw;
    } 
}

@media only screen  
  and (min-width : 320px)
  and (max-width : 499px)
  and (orientation : landscape){

}

@media only screen  
  and (min-width : 500px)
  and (max-width : 599px)
  and (orientation : landscape){

 
}

@media only screen  
  and (min-width : 600px)
  and (max-width : 650px)
  and (orientation : landscape){
 
}

@media only screen  
  and (min-width : 650px)
  and (max-width : 700px)
  and (orientation : landscape){
 
}



@media only screen  
  and (min-width : 700px)
  and (max-width : 750px)
  and (orientation : landscape){
 
}

@media only screen  
  and (min-width : 750px)
  and (max-width : 799px)
  and (orientation : landscape){
 
}

@media only screen  
  and (min-width : 800px)
  and (max-width : 890px)
  and (orientation : landscape){
 
}


@media only screen  
  and (min-width : 890px)
  and (max-width : 900px)
  and (orientation : landscape){
  
}







/* Desktops and laptops ----------- */

@media only screen  
  and (min-width : 720px) 
  and (min-height: 600px){

    .container{
        margin: 2em 5.3vw;
    } 
  
}

@media only screen  
  and (min-width : 900px){
    
    .container{
        margin : 2em 5vw;
    } 
}

@media only screen and (min-width: 1000px) {
    .container{
        margin : 2em 3.3vw;
    } 
}



@media only screen and (min-width : 1280px) {
    .container{
        margin : 2em 3.3vw;
    } 
}



/*LCD Displays*/
@media only screen 
  and (min-width: 1024px) 
  and (max-width: 1080px)  
  and (min-height : 800px) 
  and (max-height : 900px) {
    .container{
        margin : 2em 3.3vw;
    } 
}


/* Basic Laptop Screens */
@media only screen and (min-width : 1440px) {
    .container{
        margin : 2em 3.3vw;
    } 

}

/* Larger laptop screens */
@media only screen  and (min-width : 1500px) {
    .container{
        margin : 2em 3.3vw;
    } 
}


/* Large screens ----------- */
@media only screen  and (min-width : 1600px) {
  
    .container{
        margin : 2em 3.3vw;
    }   
}

@media only screen  and (min-width : 1700px) {
    .container{
        margin : 2em 3.3vw;
    }   
}

@media only screen  and (min-width : 1900px) {
    .container{
        margin : 2em 3.3vw;
    }  
 
}


@media only screen  and (min-width : 2000px) {
    .container{
        margin : 2em 3.3vw;
    }  
  
}


/* Macbook Pro */
@media only screen 
  and (min-width: 1700px) 
  and (max-width: 1800px)  
  and (min-height : 1080px) 
  and (max-height : 1100px) {
    .container{
        margin : 2em 3.3vw;
    } 
}


/* Potrait Orientation for Tablets and Mobiles */
@media only screen 
  and (min-width: 600px)
  and (orientation : portrait)
  and (-webkit-min-device-pixel-ratio: 1){
    .container{
        margin : 2em 5.3vw;
    } 
}

@media only screen 
  and (min-width: 600px)
  and (max-width: 720px)
  and (orientation : portrait)
  and (-webkit-min-device-pixel-ratio: 1){
    .container{
        margin : 2em 5.3vw;
    } 
}

@media only screen 
  and (min-width: 720px) 
  and (max-width: 769px) 
  and (orientation : portrait)
  and (-webkit-device-pixel-ratio: 1){
    .container{
        margin : 2em 5.3vw;
    }
}


@media only screen 
  and (min-width: 769px) 
  and (max-width: 850px)
  and (max-height : 1300px)
  and (orientation : portrait){
    .container{
        margin : 2em 5.3vw;
    }
}

@media only screen 
  and (min-width: 850px) 
  and (max-width: 899px) 
  and (orientation : portrait){
    .container{
        margin : 2em 5.3vw;
    } 
}




@media only screen 
  and (min-width: 900px) 
  and (max-width: 959px) 
  and (orientation : portrait){
    .container{
        margin : 2em 5.3vw;
    }
}


/*Ipad Potrait*/
@media only screen 
and (min-width : 768px) 
and (max-width : 1024px)
and (max-height: 1024px) 
and (orientation : portrait) 
and (-webkit-min-device-pixel-ratio: 1)
{
    .container{
        margin : 2em 5.3vw;
    }
  

}

/* IPad Pro 10.5 Portrait */
@media only screen 
  and (min-width: 834px)
  and (max-width: 834px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1)
  {
    .container{
        margin : 2em 5.3vw;
    }
}

/* IPad Pro 12.5 Portrait */
@media only screen
and (min-width: 1024px)
and (max-width: 1024px)
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 1)
{
    .container{
        margin : 2em 5.3vw;
    }

}







/* Landscape Orientation for Tablets and Mobiles */


/* Tablets and IPads */




@media only screen 
  and (min-width: 960px) 
  and (max-width: 1023px) 
  and (orientation : landscape){
    .container{
        margin : 2em 3.3vw;
    }
 
} 

@media only screen 
  and (min-width: 1023px) 
  and (max-width: 1179px) 
  and (orientation : landscape)
  and (-webkit-min-device-pixel-ratio: 2){

    .container{
        margin : 2em 3.3vw;
    }
  
}


@media only screen
  and (min-width: 1180px)
  and (max-width: 1194px)
  and (orientation: landscape){
    .container{
        margin : 2em 3.3vw;
    } 
}

@media only screen 
  and (min-width : 1280px) 
  and (min-height : 740px) 
  and (max-height : 750px) {
    .container{
        margin : 2em 3.3vw;
    } 

}

/*Ipad Landscape*/
@media only screen 
and (min-height : 768px)
and (max-width : 1024px)
and (orientation : landscape)
and (-webkit-min-device-pixel-ratio: 1) {
    .container{
        margin : 2em 3.3vw;
    }
 

}

/* IPad Pro 10.5 Landscape */
@media only screen 
  and (min-height: 834px)
  and (max-width: 1112px) 
  and (orientation: landscape){

    .container{
        margin : 2em 3.3vw;
    }  
}

/* IPad Pro 12.5 Landscape */
@media only screen
  and (min-height: 1024px)
  and (max-height: 1024px)
  and (orientation: landscape){
    .container{
        margin : 2em 3.3vw;
    }
}



