@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");

.footer {
  margin-top: 3em;
  color: #9e9e9e;
  font-family: "Roboto", sans-serif;
  width: 100vw;
  height: 15vh;
  position: relative;
  float: left;

}

.footer span {
  padding: 20px;
  font-size: 0.8em;
  letter-spacing: 2px;
}

.socialIcons {
  margin-top: 1.5em;
  margin-bottom: 5em;
  width: 100%;
  justify-content: center;
  column-gap: 2vw;
  display: flex;
}

.socialIcons li {
  list-style: none;
}

.socialIcons a {
  color: #646464;
}

.socialIcons a:hover {
  color: unset;
}

.socialIcons svg{
  width: 1.3em;
}

@media screen and (max-width: 768px) {
  .footer span {
    padding: 3em 3em 0 3em;
    font-size: 0.55em;
    letter-spacing: 2px;
  }
}

@media only screen and (min-width: 1440px),
  only screen and (-webkit-min-device-pixel-ratio : 1.5),
  only screen and (min-device-pixel-ratio : 1.5){
  .socialIcons a {
    padding: 1vw;
    color: #646464;
  }

}

