.carousel-indicators {
  bottom: unset !important;
  right: unset !important;
  top: 90vh;
  width: 100vw !important;
  margin-left: 0% !important;
  margin-right: 0% !important;
}

.carousel-indicators button {
  margin: 0 4vw !important;
  height: 5px !important;
  width: 35px !important;
  border: none;
  border-radius: 25%;
}

.carousel-inner {
  width: 100%;
  height: 100%;
  overflow: visible !important;
  position: static !important;
}

.carousel-control-prev , .carousel-control-next{
  visibility: hidden !important;
}

