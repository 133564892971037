*{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}


.mainLoad {
  background-color: #fbfcf8;
  font-family: Roboto;
}
.loader {
  margin: 4em 4em;
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 0;
  left: 0;
  text-align: left;
  line-height: 1.1em;
}

.animatedTitle {
  font-size: 2.5rem;
  font-weight: 200;
  line-height:normal;
}

.loadingText {
  font-size: 1.5rem;
}


@media only screen 
  and (min-width: 300px){
  .animatedTitle {
    font-size: 1.7rem;
    font-weight: 200;
  }
  
  .loadingText {
    font-size: 0.7rem;
  }
}

@media only screen 
  and (min-width: 700px){
  .animatedTitle {
    font-size: 1.8rem;
    font-weight: 200;
  }
  
  .loadingText {
    font-size: 0.8rem;
  }
}

@media only screen 
  and (min-width: 900px){
  .animatedTitle {
    font-size: 2rem;
    font-weight: 200;
  }
  
  .loadingText {
    font-size: 1rem;
    word-wrap: normal;
    line-height: 2em;
  }
}



