.about_container{
  scroll-snap-type: y mandatory;
  margin: 0 0 4em 0 !important;
  line-height: 2.1em;
}

.mediaBox img{
  height: 100% !important;
  width: 100%;
}

.mediaBox,  .mediaBox > img {
  position: relative; 
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
  height: 35vw;
  width: 100% !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: overlay;
}

.mediaBox::before{
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

}
.mediaBox > h1{
  position: relative;
  color: #ffffff;
  font-weight: bold;  
  font-size: 8em;
  line-height: 0.9;
  text-align: center;
}

.left {
  color: rgb(0, 0, 0);
  text-align: left;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: 30px;
}
  
.right {
  color: #808080;
  padding-left: 50%;
  text-align: right;
  margin-top: 30px;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

@media only screen and (max-width: 900px) {
  .mediaBox{
    height: 60vw !important;
  }

}

/* Smartphone portrait */

@media only screen 
and (max-width:240px){


  .left , .right{
    font-size: 0.9rem;
    text-align: justify;
    line-height: 2em;
  }

    
  .right {
    padding-left: 0%;
  }

}


@media only screen 
and (min-width : 240px)
and (max-width : 360px)
and (orientation : portrait){
  .mediaBox,  .mediaBox > img {
      height: 35vw;
    }
    .left , .right{
      font-size: 0.9rem;
      text-align: justify;
      line-height: 2em;
    }
  
    .right {
      padding-left: 0%;
    }
}

@media only screen
and (min-width : 360px) 
and (max-width : 399px)
and (orientation : portrait){


  .left , .right{
    font-size: 0.9rem;
    text-align: justify;
    line-height: 2em;
  }

    
  .right {
    padding-left: 0%;
  }
}


@media only screen
and (min-width : 400px) 
and (max-width : 428px)
and (orientation : portrait){

    .left , .right{
      font-size: 0.9rem;
      text-align: justify;
      line-height: 2em;
    }
  
      
    .right {
      padding-left: 0%;
    }
}

@media only screen
and (min-width : 428px) 
and (max-width : 500px)
and (orientation : portrait){

    .left , .right{
      font-size: 0.9rem;
      text-align: justify;
      line-height: 2em;
    }
  
      
    .right {
      padding-left: 0%;
    }
}

@media only screen
and (min-width : 500px) 
and (max-width : 600px)
and (orientation : portrait){


    .left , .right{
      font-size: 0.9rem;
      text-align: justify;
      line-height: 2em;
    }
  
      
    .right {
      padding-left: 0%;
    }
  
}

@media only screen 
and (min-width: 600px)
and (max-width : 800px)
and (orientation : portrait){


    .left , .right{
      font-size: 0.9rem;
      text-align: justify;
      line-height: 2em;
    }
  
      
    .right {
      padding-left: 0%;
    }

}



/* Smartphone Landscape */

@media only screen  
and (min-width : 320px)
and (max-width : 900px)
and (orientation : landscape){

  
    .left , .right{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .left {
      width: 70%;
    }
      
    .right {
      padding-left: 30%;
    }
}

@media only screen  
and (min-width : 320px)
and (max-width : 499px)
and (orientation : landscape){

  
    .left , .right{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .left {
      width: 70%;
    }
      
    .right {
      padding-left: 30%;
    }
}

@media only screen  
and (min-width : 500px)
and (max-width : 599px)
and (orientation : landscape){

    .left , .right{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .left {
      width: 70%;
    }
      
    .right {
      padding-left: 30%;
    }
}

@media only screen  
and (min-width : 600px)
and (max-width : 650px)
and (orientation : landscape){

  
    .left , .right{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .left {
      width: 70%;
    }
      
    .right {
      padding-left: 30%;
    }
}

@media only screen  
and (min-width : 650px)
and (max-width : 700px)
and (orientation : landscape){

  
    .left , .right{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .left {
      width: 70%;
    }
      
    .right {
      padding-left: 30%;
    }
}



@media only screen  
and (min-width : 700px)
and (max-width : 750px)
and (orientation : landscape){

  
    .left , .right{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .left {
      width: 70%;
    }
      
    .right {
      padding-left: 30%;
    }
}

@media only screen  
and (min-width : 750px)
and (max-width : 799px)
and (orientation : landscape){

  
    .left , .right{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .left {
      width: 70%;
    }
      
    .right {
      padding-left: 30%;
    }
}

@media only screen  
and (min-width : 800px)
and (max-width : 890px)
and (orientation : landscape){

  
    .left , .right{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .left {
      width: 70%;
    }
      
    .right {
      padding-left: 30%;
    }
}


@media only screen  
and (min-width : 890px)
and (max-width : 900px)
and (orientation : landscape){

  
    .left , .right{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .left {
      width: 70%;
    }
      
    .right {
      padding-left: 30%;
    }
}







/* Desktops and laptops ----------- */

@media only screen  
and (min-width : 720px) 
and (min-height: 600px){


  
    .left , .right{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .left {
      width: 70%;
      text-align: left;
    }
      
    .right {
      padding-left: 30%;
      text-align: right;
    }

}

@media only screen  
and (min-width : 900px){
  

  
    .left , .right{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .left {
      width: 70%;
    }
      
    .right {
      padding-left: 30%;
    }
} 



@media only screen and (min-width: 1000px) {

  
    .left , .right{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .left {
      width: 70%;
    }
      
    .right {
      padding-left: 30%;
    }

}



@media only screen and (min-width : 1280px) {

  
    .left , .right{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .left {
      width: 70%;
    }
      
    .right {
      padding-left: 30%;
    } 

}



/*LCD Displays*/
@media only screen 
and (min-width: 1024px) 
and (max-width: 1080px)  
and (min-height : 800px) 
and (max-height : 900px) {

  
    .left , .right{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .left {
      width: 70%;
    }
      
    .right {
      padding-left: 30%;
    }

}


/* Basic Laptop Screens */
@media only screen and (min-width : 1440px) {

  
    .left , .right{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .left {
      width: 70%;
    }
      
    .right {
      padding-left: 30%;
    }

}

/* Larger laptop screens */
@media only screen  and (min-width : 1500px) {

  
    .left , .right{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .left {
      width: 70%;
    }
      
    .right {
      padding-left: 30%;
    } 
}


/* Large screens ----------- */
@media only screen  and (min-width : 1600px) {


  
    .left , .right{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .left {
      width: 70%;
    }
      
    .right {
      padding-left: 30%;
    }   
}

@media only screen  and (min-width : 1700px) {

  
    .left , .right{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .left {
      width: 70%;
    }
      
    .right {
      padding-left: 30%;
    }  
}

@media only screen  and (min-width : 1900px) {

  
    .left , .right{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .left {
      width: 70%;
    }
      
    .right {
      padding-left: 30%;
    } 

}


@media only screen  and (min-width : 2000px) {

  
    .left , .right{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .left {
      width: 70%;
    }
      
    .right {
      padding-left: 30%;
    }

}


/* Macbook Pro */
@media only screen 
and (min-width: 1700px) 
and (max-width: 1800px)  
and (min-height : 1080px) 
and (max-height : 1100px) {

  
    .left , .right{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .left {
      width: 70%;
    }
      
    .right {
      padding-left: 30%;
    }
}


/* Potrait Orientation for Tablets and Mobiles */

@media only screen 
and (min-width: 600px)
and (max-width: 720px)
and (orientation : portrait)
and (-webkit-min-device-pixel-ratio: 1){

  
    .left , .right{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .left {
      width: 70%;
      text-align: left;
    }
      
    .right {
      padding-left: 30%;
      text-align: right;
    }
}

@media only screen 
and (min-width: 720px) 
and (max-width: 769px) 
and (orientation : portrait)
and (-webkit-device-pixel-ratio: 1){

  
    .left , .right{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .left {
      width: 70%;
      text-align: left;
    }
      
    .right {
      padding-left: 30%;
      text-align: right;
    }
}


@media only screen 
and (min-width: 769px) 
and (max-width: 850px)
and (max-height : 1300px)
and (orientation : portrait){

  
    .left , .right{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .left {
      width: 70%;
      text-align: left;
    }
      
    .right {
      padding-left: 30%;
      text-align: right;
    }
}

@media only screen 
and (min-width: 850px) 
and (max-width: 899px) 
and (orientation : portrait){

  
    .left , .right{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .left {
      width: 70%;
      text-align: left;
    }
      
    .right {
      padding-left: 30%;
      text-align: right;
    }
}




@media only screen 
and (min-width: 900px) 
and (max-width: 959px) 
and (orientation : portrait){
  .mediaBox,  .mediaBox > img {
      height: 25vh;
    }
  
    .left, .right{
      line-height: 1.8em;
    }
  
    .left {
      width: 70%;
      text-align: left;
    }
      
    .right {
      padding-left: 30%;
      text-align: right;
    }
}


/*Ipad Potrait*/
@media only screen 
and (min-width : 768px) 
and (max-width : 1024px)
and (max-height: 1024px) 
and (orientation : portrait) 
and (-webkit-min-device-pixel-ratio: 1)
{
  .mediaBox,  .mediaBox > img {
      height: 25vh;
    }
  
    .left, .right{
      line-height: 1.8em;
    }

    .left {
      width: 70%;
      text-align: left;
    }
      
    .right {
      padding-left: 30%;
      text-align: right;
    }


}

/* IPad Pro 10.5 Portrait */
@media only screen 
and (min-width: 834px)
and (max-width: 834px) 
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 1){
    
  .mediaBox,  .mediaBox > img {
      height: 25vh;
    }
  
    .left, .right{
      line-height: 1.8em;
    }
  
    .left {
      width: 70%;
      text-align: left;
    }
      
    .right {
      padding-left: 30%;
      text-align: right;
    }
}

/* IPad Pro 12.5 Portrait */
@media only screen
and (min-width: 1024px)
and (max-width: 1024px)
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 1)
{
  .mediaBox,  .mediaBox > img {
      height: 25vh;
    }
  
    .left, .right{
      line-height: 1.8em;
    }
  
    .left {
      width: 70%;
      text-align: left;
    }
      
    .right {
      padding-left: 30%;
      text-align: right;
    }

}







/* Landscape Orientation for Tablets and Mobiles */


/* Tablets and IPads */




@media only screen 
and (min-width: 960px) 
and (max-width: 1023px) 
and (orientation : landscape){
  .mediaBox,  .mediaBox > img {
      height: 45vh;
    }
  
    .left , .right{
      font-size: 1.1rem;
    }
  
    .left {
      width: 70%;
    }
      
    .right {
      padding-left: 30%;
    }
} 

@media only screen 
and (min-width: 1023px) 
and (max-width: 1179px) 
and (orientation : landscape)
and (-webkit-min-device-pixel-ratio: 2){

  .mediaBox,  .mediaBox > img {
      height: 45vh;
    }
  
    .left , .right{
      font-size: 1.1rem;
    }
  
    .left {
      width: 70%;
    }
      
    .right {
      padding-left: 30%;
    }

}


@media only screen
and (min-width: 1180px)
and (max-width: 1194px)
and (orientation: landscape){
  .mediaBox,  .mediaBox > img {
      height: 45vh;
    }
  
    .left , .right{
      font-size: 1.1rem;
    }
  
    .left {
      width: 70%;
    }
      
    .right {
      padding-left: 30%;
    }
}

@media only screen 
and (min-width : 1280px) 
and (min-height : 740px) 
and (max-height : 750px) {
  .mediaBox,  .mediaBox > img {
      height: 45vh;
    }
  
    .left , .right{
      font-size: 1.1rem;
    }
  
    .left {
      width: 70%;
    }
      
    .right {
      padding-left: 30%;
    }
}

/*Ipad Landscape*/
@media only screen 
and (min-height : 768px)
and (max-width : 1024px)
and (orientation : landscape)
and (-webkit-min-device-pixel-ratio: 1) {
  .mediaBox,  .mediaBox > img {
      height: 45vh;
    }
  
    .left , .right{
      font-size: 1.1rem;
    }
  
    .left {
      width: 70%;
    }
      
    .right {
      padding-left: 30%;
    }


}

/* IPad Pro 10.5 Landscape */
@media only screen 
and (min-height: 834px)
and (max-width: 1112px) 
and (orientation: landscape){

  .mediaBox,  .mediaBox > img {
      height: 45vh;
    }
  
    .left , .right{
      font-size: 1.1rem;
    }
  
    .left {
      width: 70%;
    }
      
    .right {
      padding-left: 30%;
    }
}

/* IPad Pro 12.5 Landscape */
@media only screen
and (min-height: 1024px)
and (max-height: 1024px)
and (orientation: landscape){
  .mediaBox,  .mediaBox > img {
      height: 45vh;
    }
  
    .left , .right{
      font-size: 1.1rem;
    }
  
    .left {
      width: 70%;
    }
      
    .right {
      padding-left: 30%;
    }
}



