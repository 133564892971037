@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
  font-family: 'Roboto', sans-serif;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1em;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.App {
  text-align: center;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* @media only screen and (min-width: 1700px) {
  h1 {
    font-size: xxx-large !important;
  }
  h2 {
    font-size: xx-large !important;
  }
  span {
    font-size: x-large !important;
  }
  li {
    font-size: x-large !important;
  }
} */

.homeHeader_header__3MyLB {
  font-family: "Roboto", sans-serif;
  font-size: 1.3rem;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  margin: 2em 1.6em;
  align-items: center;
}

.homeHeader_header__3MyLB > .homeHeader_navMenu__3NvtM {
  margin: 0;
}

.homeHeader_logo__1DzlL {
  float: left;
  height: 30px;
  object-fit: cover;
  z-index: 1;
}


.homeHeader_toggler__1gn7C {
  display: block;
  position: absolute;
  width: 1.6rem;
  height: 1.6rem;
  z-index: 1001;
  opacity: 0;
  cursor: pointer;
}

.homeHeader_hamburger__2Q0uz {
  position: relative;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 1.6rem;
  height: 1.6rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1000;
}

.homeHeader_hamburger__2Q0uz > div {
  width: 1.7rem;
  height: 0.15rem;
  border-radius: 10px;
  transition: all 0.2s linear;
  position: relative;
  -webkit-transform-origin: 1px;
          transform-origin: 1px;
  -webkit-clip-path: inset(0 0 0 0);
          clip-path: inset(0 0 0 0);
}

/* .toggler:hover + .hamburger > div:nth-child(2) {
  clip-path: inset(0 0 0 30%);
}

.toggler:hover + .hamburger > div:nth-child(3) {
  clip-path: inset(0 0 0 50%);
} */

.homeHeader_toggler__1gn7C:checked + .homeHeader_hamburger__2Q0uz > div:first-child {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.homeHeader_toggler__1gn7C:checked + .homeHeader_hamburger__2Q0uz > div:nth-child(2) {
  opacity: 0;
}

.homeHeader_toggler__1gn7C:checked + .homeHeader_hamburger__2Q0uz > div:nth-child(3) {
  -webkit-clip-path: inset(0 0 0 0);
          clip-path: inset(0 0 0 0);
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}


.homeHeader_hamburger__2Q0uz:focus {
  outline: none;
}




/* Smartphone portrait */

@media only screen 
  and (min-width : 240px)
  and (max-width : 720px)
  and (orientation : portrait){

  .homeHeader_logo__1DzlL{
    height: 25px;
  }

}



@media only screen 
  and (min-width : 240px)
  and (max-width : 320px)
  and (orientation : portrait){

  .homeHeader_header__3MyLB{
    margin: 2em 9vw;
  }

    
}

@media only screen
  and (min-width : 320px) 
  and (max-width : 400px)
  and (orientation : portrait){
  .homeHeader_header__3MyLB{
    margin: 2em 8vw;
  }
}

@media only screen
  and (min-width : 400px) 
  and (max-width : 428px)
  and (orientation : portrait){
  .homeHeader_header__3MyLB{
    margin: 2em 7.5vw;
  }
}

@media only screen
  and (min-width : 429px) 
  and (max-width : 600px)
  and (orientation : portrait){

  .homeHeader_header__3MyLB{
    margin: 2em 9vw;
  }
  
}

  @media only screen 
  and (min-width: 600px)
  and (max-width : 800px)
  and (orientation : portrait){


    .homeHeader_header__3MyLB{
      margin: 2em 5.3vw;
    }

}





/* Smartphone Landscape */

@media only screen  
  and (min-width : 320px)
  and (max-width : 460px)
  and (orientation : landscape){

  .homeHeader_header__3MyLB{
    margin : 2em 4.9vw;
  }    
}

@media only screen  
  and (min-width : 500px)
  and (max-width : 850px)
  and (orientation : landscape){

  .homeHeader_header__3MyLB{
    margin : 2em 4.9vw;
  }


}


@media only screen  
  and (min-width : 890px)
  and (max-width : 900px)
  and (orientation : landscape){

  .homeHeader_header__3MyLB{
    margin : 2em 4.9vw;
  }
}




/* Desktops and laptops ----------- */

@media only screen  
  and (min-width : 720px) 
  and (min-height: 600px){
  .homeHeader_header__3MyLB{
    margin: 2em 5.3vw;
  }
}

@media only screen  
  and (min-width : 900px){

  .homeHeader_header__3MyLB{
    margin : 2em 5vw;
  }

}

@media only screen  
  and (min-width : 1000px){

  .homeHeader_header__3MyLB{
    margin : 2em 3.3vw;
  }

}


@media only screen and (min-width : 1110px) {
  .homeHeader_header__3MyLB{
    margin: 2em 3.3vw;
  }
}


@media only screen and (min-width : 1280px) {
  .homeHeader_header__3MyLB{
    margin: 2em 3.3vw;
  }

}

@media only screen 
  and (min-width : 1279px) 
  and (min-height : 740px) 
  and (max-height : 800px) {
    .homeHeader_header__3MyLB{
      margin: 2em 3.3vw;
    }
    
}

/* Basic Laptop Screens */
@media only screen and (min-width : 1440px) {
  .homeHeader_header__3MyLB{
    margin: 2em 3.3vw;
  }
  
}

/* Larger laptop screens */
@media only screen  and (min-width : 1500px) {
  
  .homeHeader_header__3MyLB{
    margin: 2em 3.3vw;
  }
}


/*LCD Displays*/
@media only screen 
  and (min-width: 1024px) 
  and (max-width: 1080px)  
  and (min-height : 800px) 
  and (max-height : 900px) {
    .homeHeader_header__3MyLB{
      margin: 2em 3.3vw;
    }
    
}

/* Large screens ----------- */
@media only screen  and (min-width : 100em) {
  .homeHeader_header__3MyLB{
    margin: 2em 3.3vw;
  }
  
}

/* Macbook Pro */
@media only screen 
  and (min-width: 1700px) 
  and (max-width: 1800px)  
  and (min-height : 1080px) 
  and (max-height : 1100px) {
    .homeHeader_header__3MyLB{
      margin: 2em 3.3vw;
    }
    
}



/* Potrait Orientation for Tablets and Mobiles */


/* Tablets and IPads */

@media only screen 
  and (min-width: 600px)
  and (max-width: 718px)
  and (orientation : portrait){

  .homeHeader_header__3MyLB{
    margin: 2em 5.3vw;
  }

  
}

@media only screen 
  and (min-width: 720px) 
  and (max-width: 768px) 
  and (orientation : portrait){

  .homeHeader_header__3MyLB{
    margin: 2em 5.3vw;
  }

    
}


@media only screen 
  and (min-width: 768px) 
  and (max-width: 880px) 
  and (orientation : portrait){

  .homeHeader_header__3MyLB{
    margin: 2em 5.3vw;
  }
    
}

/*Ipad Potrait*/
@media only screen 
and (min-width : 768px) 
and (max-width : 1024px)
and (max-height: 1024px) 
and (orientation : portrait) {

  .homeHeader_header__3MyLB{
    margin: 2em 5.3vw;
  }
  
}

/* IPad Pro 10.5 Portrait */
@media only screen 
  and (min-width: 834px)
  and (max-width: 834px) 
  and (orientation: portrait) {

  .homeHeader_header__3MyLB{
    margin: 2em 5.3vw;
  }

}

/* IPad Pro 12.5 Portrait */
@media only screen
and (min-width: 1024px)
and (max-width: 1024px)
and (orientation: portrait){

  .homeHeader_header__3MyLB{
    margin: 2em 5.3vw;
  }

  .homeHeader_navList__1jqUx > a{
    color: #9e9e9e;
    font-weight: 200;
    margin-left: 4vw;
  }

}







/* Landscape Orientation for Tablets and Mobiles */


/* Tablets and IPads */


@media only screen 
  and (min-width: 960px) 
  and (max-width: 1023px) 
  and (orientation : landscape){

  .homeHeader_header__3MyLB{
    margin: 2em 3.3vw;
  }

}

@media only screen 
  and (min-width: 1023px) 
  and (max-width: 1179px) 
  and (orientation : landscape)
  and (-webkit-min-device-pixel-ratio: 2){
  
  .homeHeader_header__3MyLB{
    margin: 2em 3.3vw;
  }

}


@media only screen
  and (min-width: 1180px)
  and (max-width: 1194px)
  and (orientation: landscape){

  .homeHeader_header__3MyLB{
    margin: 2em 3.3vw;
  }

}

@media only screen 
  and (min-width : 1280px) 
  and (min-height : 740px) 
  and (max-height : 800px) {

  .homeHeader_header__3MyLB{
    margin: 2em 3.3vw;
  }

}

/*Ipad Landscape*/
@media only screen 
and (min-height : 768px)
and (max-width : 1024px)
and (orientation : landscape)
and (-webkit-min-device-pixel-ratio: 1) {

  .homeHeader_header__3MyLB{
    margin: 2em 3.3vw;
  }

}

/* IPad Pro 10.5 Landscape */
@media only screen 
  and (min-height: 834px)
  and (max-width: 1112px) 
  and (orientation: landscape){

  .homeHeader_header__3MyLB{
    margin: 2em 3.3vw;
  }

}

/* IPad Pro 12.5 Landscape */
@media only screen
  and (min-height: 1024px)
  and (max-height: 1024px)
  and (orientation: landscape){

  .homeHeader_header__3MyLB{
    margin: 2em 3.3vw;
  }

}


.homeDropdown_overlayMenu__3_8SI {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
  width: 100%;
  height: 100%;   
}

.homeDropdown_overlayMenu__3_8SI > nav {
  font-size: 1.5rem;
  z-index: 100;
  display: flex;
  flex-direction: column;
  text-align: right;
  position: relative;
  margin-right: 4vw;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.homeDropdown_overlayMenu__3_8SI > nav > a {
  display: block;
  padding-top: 1.3em;
  color: #fff;
  cursor: pointer;
  transition: all 0.4s ease;
}

.homeDropdown_overlayMenu__3_8SI > nav > a:hover {
  text-decoration: none;
  color: #9e9e9e;
}

@media only screen 
  and (max-width:900px){
  .homeDropdown_overlayMenu__3_8SI > nav {
    text-align: center;
    position: fixed;
    top: 47.5%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}

@media only screen 
  and (max-width:1080px)
  and (orientation : portrait){
  .homeDropdown_overlayMenu__3_8SI > nav {
    text-align: center;
    position: fixed;
    top: 47.5%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}


@media only screen and
  (min-width: 400px){
  .homeDropdown_overlayMenu__3_8SI > nav {
      font-size: 1.5rem;
      
  }
}
body {
  /* animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  appearance: none; */
}

@-webkit-keyframes homePage_fadeInAnimation__1HCnK {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes homePage_fadeInAnimation__1HCnK {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.homePage_carousel-control-prev__1aEkJ , .homePage_carousel-control-next__Vt3GF{
  visibility: hidden !important;
}

.homePage_mediaBox__1_FK5, .homePage_carousel-item__1bRoH {
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: black;
  /* background-image: url("https://graha.s3.us-east-2.amazonaws.com/GRAHA_POSTER_gif_d73a03689c.gif"); */
}


.homePage_full_screen_video_wrap__1FAaZ {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%; 
  overflow: hidden;
  z-index: -2;
  background-color: black;
}

.homePage_backgroundImage__1MsUn {
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: black;
  /* background-image: url("https://graha.s3.us-east-2.amazonaws.com/GRAHA_POSTER_gif_d73a03689c.gif"); */
}


.carousel-indicators {
  bottom: unset !important;
  right: unset !important;
  top: 90vh;
  width: 100vw !important;
  margin-left: 0% !important;
  margin-right: 0% !important;
}

.carousel-indicators button {
  margin: 0 4vw !important;
  height: 5px !important;
  width: 35px !important;
  border: none;
  border-radius: 25%;
}

.carousel-inner {
  width: 100%;
  height: 100%;
  overflow: visible !important;
  position: static !important;
}

.carousel-control-prev , .carousel-control-next{
  visibility: hidden !important;
}


.homeLoader_mainLoad__3L0_g{
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%; 
    overflow: hidden;
    z-index: 1000;
}

.homeLoader_homeLoader__236b8{
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: black;
    /* background-image: url("https://graha.s3.us-east-2.amazonaws.com/GRAHA_b8d84b5a72.gif"); */
    /* transition: all 1s ease-out; */
}

@media screen and (max-width: 900px) and (orientation: portrait) {
    .homeLoader_homeLoader__236b8{
        background-image: url("https://graha.zetgo.in/uploads/ezgif_4_33bbf62fa9_c24f8baee7.gif");
    }
}

@-webkit-keyframes homeLoader_fadeOut__189HY {
    0% { opacity: 1;}
    99% { opacity: 0.01;width: 100%; height: 100%;}
    100% { opacity: 0;width: 0; height: 0;}
}  
@keyframes homeLoader_fadeOut__189HY {
    0% { opacity: 1;}
    99% { opacity: 0.01;width: 100%; height: 100%;}
    100% { opacity: 0;width: 0; height: 0;}
}

*{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

.works_worksPage__1Sc5R {
  font-family: "Roboto", sans-serif;
  display: block;
  overflow-y: auto;
}

.works_secondaryNav__1-ueL{
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}

.works_navbarDiv__14Hui {
  float: right;
  display: flex;
  width: 100%;
}

.works_miniNav__2k1WU{
  width: 96vw;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin: -1.5em 2em;
  z-index: 4;
}

.works_navbarItem__1LJhv {
  height: 3vh;
  font-size: 0.9em;
  margin-left: 2.5em;
  /* flex: 1 1 auto; */
  text-align: right;
  letter-spacing: 2px;
  cursor: pointer;
}

.works_searchBar__3I5CF{
  display: flex;
  flex-direction: row-reverse;
  margin: 1em 2em;
}

.works_searchBar__3I5CF > svg{
  height: 1em;
}

.works_mobileMiniNav__2erUx > button > svg{
  margin: 0 !important;
}

.works_searchInput__3Zkhk{
  overflow: visible;
  text-align: right;
  border-radius: 46.5px;
  border-width: thin;
  box-sizing:border-box;
  height: 20px !important;
  width: 20px;
  max-width: 20vw;
  border-color: rgb(0 , 0 ,0 , 0.45);
  padding-right: 10px;
  z-index: 5;
  transition: width 1s;
  color: white;
}

.works_searchBar__3I5CF:hover::before, .works_searchInput__3Zkhk:focus::before{
  transition: width 1s;
}

.works_searchInput__3Zkhk::-webkit-input-placeholder{
  color: white;
}

.works_searchInput__3Zkhk:-ms-input-placeholder{
  color: white;
}

.works_searchInput__3Zkhk::placeholder{
  color: white;
}

.works_searchInput__3Zkhk:hover::-webkit-input-placeholder, .works_searchInput__3Zkhk:focus::-webkit-input-placeholder{
  color: #9e9e9e;
}

.works_searchInput__3Zkhk:hover:-ms-input-placeholder, .works_searchInput__3Zkhk:focus:-ms-input-placeholder{
  color: #9e9e9e;
}

.works_searchInput__3Zkhk:hover::placeholder, .works_searchInput__3Zkhk:focus::placeholder{
  color: #9e9e9e;
}

.works_searchInput__3Zkhk:hover, .works_searchInput__3Zkhk:focus, .works_searchInput__3Zkhk select{
  color: #9e9e9e;
  width: 10vw;
  outline: none;
  opacity: 1;
}


.works_gridContainer__qGsMh {
  display: grid;
  margin: 2em 3em;
  grid-template-columns: repeat(auto-fill,23vw);
  grid-template-rows: repeat(auto-fill ,16vw);
}

.works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
  width: 23vw;
  height: 16vw;
  margin-bottom: 2vh;
  object-fit: cover;
}

.works_hover__MzJNv:hover{
  /* opacity: 1;
  transition: opacity 400ms; */
  -webkit-animation: works_hoverEnter__FJHPt 400ms linear forwards;
  animation: works_hoverEnter__FJHPt 400ms linear forwards;
}


.works_gridItem__OOEE7 embed {
  object-fit: cover;
}

.works_imageCover__3Uk5e {
  position: absolute;
  display: none;
}

.works_mobileBlog__1isb8 {
  display: none;
}

.works_gridItem__OOEE7:hover , .works_imageCover__3Uk5e:hover , .works_hover__MzJNv:hover{
  /* transition: ease-in 2s all; */
}

.works_hover__MzJNv {
  /* opacity: 0; */
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
}

@-webkit-keyframes works_hoverEnter__FJHPt {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes works_hoverEnter__FJHPt {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes works_hoverExit__19b1q {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes works_hoverExit__19b1q {
  0% { opacity: 1; }
  100% { opacity: 0; }
}


.works_hover__MzJNv h1 {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  font-weight: 100;
  font-style: normal;
  font-size: 1em !important;
}

.works_hover__MzJNv .works_caption__3u-L8 {
  text-align: right;
  color: white;
  font-size: 0.7vw;
  padding: 30px 30px 20px 30px;
  position: absolute;
  line-height: 1.6 !important;
  font-weight: 300 !important;
  bottom: 0;
  right: 0;
  
}

.works_moreProjects__2Ce7y {
  width: 150px;
  color: #9e9e9e;
  margin: 20vh auto 40vh auto;
  cursor: pointer;
}

.works_moreProjects__2Ce7y span {
  font-size: 1.4em;
}

.works_mobileMiniNav__2erUx{
  display: none;
  margin: 0;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  flex-direction: column;
  z-index: 3;
}

.works_mobileMiniNav__2erUx > button{
  background: none;
  border: none;
  transition: all .5s linear;
  align-self: flex-end;
}

.works_mobileMiniNav__2erUx > FontAwesomeIcon{
  transition: all .5s linear;
}

.works_dropdown__2M8Fl{
  line-height: 30px;
  justify-content: center;
  text-align-last: center;
  transition: height 5s ease-in;
  max-height: 100%;
  overflow: hidden;
}


.works_dropdown_hidden__23gg0{
  height: 0;
  overflow: hidden;
}

.works_dropdown__2M8Fl > .works_navbarItem__1LJhv {
  font-size: 0.9em;
  /* flex: 1 1 auto; */
  height: 2vw;
  letter-spacing: 2px;
  cursor: pointer;
  text-align: center;
  margin: 0 0;
  align-items: center;
  display: block;
  margin-bottom: 2em;
}

.works_dropdown_hidden__23gg0 ~ .works_dropdown__2M8Fl > .works_navbarItem__1LJhv{
  opacity: 0;
}

.works_rotate__2n4fZ{
  transition: all .2s linear;
  -webkit-transform:rotate(45deg);
  transform:rotate(45deg);
}


.works_icons__yuY9z {
  display: none;
}




/* Toggling the mini Nav design */
@media only screen 
  and (max-width : 900px){
    .works_secondaryNav__1-ueL{
      margin: 2em 2em;
      margin-right: 2em;
      flex-direction: row;
      flex-wrap: nowrap;
    }
    .works_miniNav__2k1WU{
      display: none;
    }
    .works_mobileMiniNav__2erUx{
      /* display: none; */
      display: flex !important;
    }
    .works_searchBar__3I5CF{
      margin: 0 0;
      flex-direction: row;
      grid-column-gap: 20px;
      -webkit-column-gap: 20px;
              column-gap: 20px;
    }

    .works_searchInput__3Zkhk{
      width: 20px;
    }
    .works_searchInput__3Zkhk:hover, .works_searchInput__3Zkhk:focus{
      z-index: 10;
      width: 40vw;
      text-align: left;
      padding-left: 5px;
    }
  }

@media only screen 
  and (min-width: 900px){
  .works_dropdown__2M8Fl{
    display: none;
  }
}

/* Smartphone portrait */

@media only screen 
  and (max-width:240px){
    .works_gridContainer__qGsMh{
      grid-template-columns: repeat(1 , 1fr);
      grid-template-rows: repeat(auto-fill , 48vw);
      margin: 2em 8.5vw;
    }
  
    .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
      width: 83vw;
      height: 45vw;
    }

    .works_secondaryNav__1-ueL{
      margin: 2em 8.5vw;
    }
  
    .works_miniNav__2k1WU{
      width: 96.7vw;
    }
    .works_searchInput__3Zkhk{
      width: 20px;
      height: 6.3vw;
    }

    .works_searchInput__3Zkhk:hover, .works_searchInput__3Zkhk:focus, .works_searchInput__3Zkhk select{
      min-width: 15vw;
    }

    .works_mobileMiniNav__2erUx > button > .works_svg-inline--fa__1lQLl.works_fa-w-14__38EFz{
      margin: 0;
    }
    
    .works_hover__MzJNv h1{
      font-size: 7vw;
    }

    .works_hover__MzJNv .works_caption__3u-L8{
      font-size: 2.5vw;
    }
}


@media only screen 
  and (min-width : 240px)
  and (max-width : 360px)
  and (orientation : portrait){

    .works_gridContainer__qGsMh{
      grid-template-columns: repeat(1 , 1fr);
      grid-template-rows: repeat(auto-fill , 48vw);
      margin: 2em 8.5vw;
    }
  
    .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
      width: 83vw;
      height: 45vw;
    }

    .works_secondaryNav__1-ueL{
      margin: 2em 8.5vw;
    }
  
    .works_miniNav__2k1WU{
      width: 96.7vw;
    }
    .works_searchInput__3Zkhk{
      width: 20px;
      height: 6.3vw;
    }

    .works_searchInput__3Zkhk:hover, .works_searchInput__3Zkhk:focus, .works_searchInput__3Zkhk select{
      min-width: 40vw;
    }

    .works_mobileMiniNav__2erUx > button > .works_svg-inline--fa__1lQLl.works_fa-w-14__38EFz{
      margin: 0;
    }
    
    .works_hover__MzJNv h1{
      font-size: 7vw;
    }

    .works_hover__MzJNv .works_caption__3u-L8{
      font-size: 2.5vw;
    }

}

@media only screen
  and (min-width : 360px) 
  and (max-width : 399px)
  and (orientation : portrait){

    .works_gridContainer__qGsMh{
      grid-template-columns: repeat(1 , 1fr);
      grid-template-rows: repeat(auto-fill , 50vw);
      margin: 2em 8vw;
    }
  
    .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
      width: 84vw;
      height: 45vw;
    }

    .works_secondaryNav__1-ueL{
      margin: 2em 8vw;
    }

    .works_searchInput__3Zkhk{
      width: 20px;
      height: 6.3vw;
    }

    .works_searchInput__3Zkhk:hover, .works_searchInput__3Zkhk:focus, .works_searchInput__3Zkhk select{
      min-width: 40vw;
    }
  
    .works_miniNav__2k1WU{
      width: 96.7vw;
    }
    
    .works_svg-inline--fa__1lQLl.works_fa-w-14__38EFz{
      float: right;
      margin-bottom: 4px;
      width: 0.9em;
      height: auto;
    }

    .works_hover__MzJNv h1{
      font-size: 7vw;
    }

    .works_hover__MzJNv .works_caption__3u-L8{
      font-size: 2.5vw;
    }
      
}


@media only screen
  and (min-width : 400px) 
  and (max-width : 428px)
  and (orientation : portrait){

    .works_gridContainer__qGsMh{
      grid-template-columns: repeat(1 , 1fr);
      grid-template-rows: repeat(auto-fill , 50vw);
      margin: 2em 8vw;
    }
  
    .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
      width: 84.5vw;
      height: 45vw;
    }

    .works_searchInput__3Zkhk{
      width: 20px;
      height: 6.3vw;
    }

    .works_searchInput__3Zkhk:hover, .works_searchInput__3Zkhk:focus, .works_searchInput__3Zkhk select{
      min-width: 40vw;
    }
  
    .works_miniNav__2k1WU{
      width: 96.7vw;
    }
    
    .works_svg-inline--fa__1lQLl.works_fa-w-14__38EFz{
      float: right;
      margin-top: 5px;
      margin-left: 10px;
      width: 0.9em;
      height: auto;
    }

    .works_hover__MzJNv h1{
      font-size: 7vw;
    }

    .works_hover__MzJNv .works_caption__3u-L8{
      font-size: 2.5vw;
    }
      
}

@media only screen
  and (min-width : 428px) 
  and (max-width : 500px)
  and (orientation : portrait){

    .works_gridContainer__qGsMh{
      grid-template-columns: repeat(1 , 1fr);
      grid-template-rows: repeat(auto-fill , 50vw);
      margin: 2em 9vw;
    }
  
    .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
      width: 82vw;
      height: 45vw;
    }

    .works_secondaryNav__1-ueL{
      margin: 2em 9vw;
    }

    .works_searchInput__3Zkhk{
      width: 20px;
      height: 6.3vw;
    }

    .works_searchInput__3Zkhk:hover, .works_searchInput__3Zkhk:focus, .works_searchInput__3Zkhk select{
      min-width: 40vw;
    }
  
    .works_miniNav__2k1WU{
      width: 96.7vw;
    }
    
    .works_svg-inline--fa__1lQLl.works_fa-w-14__38EFz{
      float: right;
      margin-top: 5px;
      margin-left: 10px;
      width: 0.9em;
      height: auto;
    }

    .works_hover__MzJNv h1{
      font-size: 7vw;
    }

    .works_hover__MzJNv .works_caption__3u-L8{
      font-size: 2.5vw;
    }
      
}

@media only screen
  and (min-width : 500px) 
  and (max-width : 600px)
  and (orientation : portrait){

    .works_gridContainer__qGsMh{
      grid-template-columns: repeat(1 , 1fr);
      grid-template-rows: repeat(auto-fill , 50vw);
      margin: 2em 9vw;
    }
  
    .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
      width: 82vw;
      height: 45vw;
    }

    .works_searchInput__3Zkhk{
      width: 20px;
      height: 6.3vw;
    }

    .works_secondaryNav__1-ueL{
      margin: 2em 9vw;
    }

    .works_searchInput__3Zkhk:hover, .works_searchInput__3Zkhk:focus, .works_searchInput__3Zkhk select{
      min-width: 40vw;
    }
  
    .works_miniNav__2k1WU{
      width: 96.7vw;
    }
    
    .works_svg-inline--fa__1lQLl.works_fa-w-14__38EFz{
      float: right;
      margin-top: 5px;
      margin-left: 10px;
      width: 0.9em;
      height: auto;
    }

    .works_hover__MzJNv h1{
      font-size: 7vw;
    }

    .works_hover__MzJNv .works_caption__3u-L8{
      font-size: 2.5vw;
    }
      
}

@media only screen 
  and (min-width: 600px)
  and (max-width : 800px)
  and (orientation : portrait){
    .works_gridContainer__qGsMh{
      grid-template-columns: repeat(1 , 1fr);
      grid-template-rows: repeat(auto-fill , 50vw);
      margin: 2em 9vw;
    }
  
    .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
      width: 84.5vw;
      height: 45vw;
    }

    .works_searchInput__3Zkhk{
      width: 20px;
    }
  
    .works_miniNav__2k1WU{
      width: 96.7vw;
    }
      

}



/* Smartphone Landscape */

@media only screen  
  and (min-width : 320px)
  and (max-width : 499px)
  and (orientation : landscape){
    .works_secondaryNav__1-ueL{
      margin: 2em 2.5em;
      /* margin-right: 2em; */
      flex-direction: row;
      flex-wrap: nowrap;
    }
  
    .works_gridContainer__qGsMh{
      grid-template-columns: repeat(1 , 1fr);
      grid-template-rows: repeat(auto-fill , 53vw);
      margin: 1em 5vw;
      /* grid-row-gap: 4em; */
      /* row-gap: 1vw; */
      /* grid-column-gap: 19px; */
      grid-column-gap: 20px;
      -webkit-column-gap: 20px;
              column-gap: 20px;
    }
  
    .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
      width: 90vw;
      height: 50vw;
    }

    .works_secondaryNav__1-ueL{
      margin: 1em 4.9vw;
    }
  
    .works_miniNav__2k1WU{
      width: 96.7vw;
    }

    .works_searchInput__3Zkhk{
      width: 20px;
      height: 3.3vw;
    }
  
    .works_searchInput__3Zkhk:hover, .works_searchInput__3Zkhk:focus, .works_searchInput__3Zkhk select{
      min-width: 40vw;
    }

    .works_svg-inline--fa__1lQLl.works_fa-w-14__38EFz{
      margin: 0;
    }

    .works_hover__MzJNv h1 {
      font-size: 5vw;
    }
    
    .works_hover__MzJNv .works_caption__3u-L8 {
      font-size: 2.5vw;
    }
    
}

@media only screen  
  and (min-width : 500px)
  and (max-width : 599px)
  and (orientation : landscape){

    .works_secondaryNav__1-ueL{
      margin: 2em 4.9vw;
      /* margin-right: 2em; */
      flex-direction: row;
      flex-wrap: nowrap;
    }
  
    .works_gridContainer__qGsMh{
      grid-template-columns: repeat(2 , 1fr);
      grid-template-rows: repeat(auto-fill , 32vw);
      margin: 2em 5vw;
      /* grid-row-gap: 4em; */
      grid-row-gap: 1vw;
      row-gap: 1vw;
      /* grid-column-gap: 19px; */
      grid-column-gap: 19px;
      -webkit-column-gap: 19px;
              column-gap: 19px;
    }
  
    .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
      width: 43.4vw;
      height: 30vw;
    }
  
    .works_miniNav__2k1WU{
      width: 96.7vw;
    }

    .works_searchInput__3Zkhk{
      width: 20px;
      height: 3.3vw;
      padding-top: 10px;
    }
  
    .works_searchInput__3Zkhk:hover, .works_searchInput__3Zkhk:focus, .works_searchInput__3Zkhk select{
      min-width: 40vw;
    }

    .works_svg-inline--fa__1lQLl.works_fa-w-14__38EFz{
      margin: 0;
    }

    .works_hover__MzJNv h1 {
      font-size: 5vw;
    }
    
    .works_hover__MzJNv .works_caption__3u-L8 {
      font-size: 1.5vw;
    }
  
}

@media only screen  
  and (min-width : 600px)
  and (max-width : 650px)
  and (orientation : landscape){

    .works_gridContainer__qGsMh{
      grid-template-columns: repeat(2 , 1fr);
      grid-template-rows: repeat(auto-fill , 36vw);
      margin: 0;
      margin: 2em 5vw;
      /* margin-left: 1.6em; */
      /* grid-row-gap: 0px; */
      /* grid-row-gap: 5px; */
      grid-row-gap: 10px;
      row-gap: 10px;
      /* grid-column-gap: 15px; */
      /* grid-column-gap: 15px; */
      grid-column-gap: 17px;
      -webkit-column-gap: 17px;
              column-gap: 17px;
    }
  
    .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
      width: 43.6vw;
      height: 34.2vw;
    }
  
    .works_secondaryNav__1-ueL{
      margin: 2em 4.9vw;
    }

    .works_searchInput__3Zkhk{
      width: 20px;
      height: 3.3vw;
    }
  
    .works_searchInput__3Zkhk:hover, .works_searchInput__3Zkhk:focus, .works_searchInput__3Zkhk select{
      min-width: 40vw;
    }


    .works_svg-inline--fa__1lQLl.works_fa-w-14__38EFz{
      margin: 0;
      margin-bottom: 4px;
    }


    .works_hover__MzJNv h1 {
      font-size: 5vw;
    }
    
    .works_hover__MzJNv .works_caption__3u-L8 {
      font-size: 1.5vw;
    }
  
}

@media only screen  
  and (min-width : 650px)
  and (max-width : 700px)
  and (orientation : landscape){

    .works_gridContainer__qGsMh{
      grid-template-columns: repeat(2 , 1fr);
      grid-template-rows: repeat(auto-fill , 36vw);
      margin: 0;
      margin: 2em 5vw;
      /* margin-left: 1.6em; */
      /* grid-row-gap: 0px; */
      /* grid-row-gap: 5px; */
      grid-row-gap: 10px;
      row-gap: 10px;
      /* grid-column-gap: 15px; */
      /* grid-column-gap: 15px; */
      grid-column-gap: 17px;
      -webkit-column-gap: 17px;
              column-gap: 17px;
    }
  
    .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
      width: 43.6vw;
      height: 34.7vw;
    }
  
    .works_secondaryNav__1-ueL{
      margin: 2em 4.9vw;
    }

    .works_searchInput__3Zkhk{
      width: 20px;
      height: 3.3vw;
    }
  
    .works_searchInput__3Zkhk:hover, .works_searchInput__3Zkhk:focus, .works_searchInput__3Zkhk select{
      min-width: 40vw;
    }


    .works_svg-inline--fa__1lQLl.works_fa-w-14__38EFz{
      margin: 0;
      margin-bottom: 4px;
    }

    .works_hover__MzJNv h1 {
      font-size: 5vw;
    }
    
    .works_hover__MzJNv .works_caption__3u-L8 {
      font-size: 1.5vw;
    }
  
}



@media only screen  
  and (min-width : 700px)
  and (max-width : 750px)
  and (orientation : landscape){

    .works_gridContainer__qGsMh{
      grid-template-columns: repeat(2 , 1fr);
      grid-template-rows: repeat(auto-fill , 38vw);
      margin: 0;
      margin: 2em 5vw;
      /* margin-left: 1.6em; */
      /* grid-row-gap: 0px; */
      /* row-gap: 0px; */
      /* grid-column-gap: 19px; */
      grid-column-gap: 19px;
      -webkit-column-gap: 19px;
              column-gap: 19px;
    }
  
    .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
      width: 43.7vw;
      height: 36vw;
    }
  
    .works_secondaryNav__1-ueL{
      margin: 2em 4.9vw;
    }

    .works_searchInput__3Zkhk{
      width: 20px;
      height: 3.3vw;
    }
  
    .works_searchInput__3Zkhk:hover, .works_searchInput__3Zkhk:focus, .works_searchInput__3Zkhk select{
      min-width: 40vw;
    }


    .works_svg-inline--fa__1lQLl.works_fa-w-14__38EFz{
      margin: 0;
      margin-bottom: 4px;
    }

    
    .works_hover__MzJNv h1 {
      font-size: 5vw;
    }
    
    .works_hover__MzJNv .works_caption__3u-L8 {
      font-size: 1.5vw;
    }
}

@media only screen  
  and (min-width : 750px)
  and (max-width : 799px)
  and (orientation : landscape){

    .works_gridContainer__qGsMh{
      grid-template-columns: repeat(2 , 1fr);
      grid-template-rows: repeat(auto-fill , 40vw);
      margin: 0;
      margin: 2em 4.9vw;
      /* margin-left: 1.6em; */
      /* grid-row-gap: 0px; */
      grid-row-gap: 5px;
      row-gap: 5px;
      /* grid-column-gap: 15px; */
      grid-column-gap: 15px;
      -webkit-column-gap: 15px;
              column-gap: 15px;
    }
  
    .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
      width: 44.1vw;
      height: 37.7vw;
    }
  
    .works_secondaryNav__1-ueL{
      margin: 2em 5vw;
    }

    .works_searchInput__3Zkhk{
      width: 20px;
      height: 3.3vw;
    }
  
    .works_searchInput__3Zkhk:hover, .works_searchInput__3Zkhk:focus, .works_searchInput__3Zkhk select{
      min-width: 40vw;
    }


    .works_svg-inline--fa__1lQLl.works_fa-w-14__38EFz{
      margin: 0;
      margin-bottom: 4px;
    } 

    .works_hover__MzJNv h1 {
      font-size: 5vw;
    }
    
    .works_hover__MzJNv .works_caption__3u-L8 {
      font-size: 1.5vw;
    }
}

@media only screen  
  and (min-width : 800px)
  and (max-width : 890px)
  and (orientation : landscape){

    .works_gridContainer__qGsMh{
      grid-template-columns: repeat(2 , 1fr);
      grid-template-rows: repeat(auto-fill , 40vw);
      margin: 0;
      margin: 2em 5vw;
      /* margin-left: 1.6em; */
      /* grid-row-gap: 0px; */
      grid-row-gap: 5px;
      row-gap: 5px;
      /* grid-column-gap: 15px; */
      grid-column-gap: 15px;
      -webkit-column-gap: 15px;
              column-gap: 15px;
    }
  
    .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
      width: 44vw;
      height: 37.7vw;
    }
  
    .works_secondaryNav__1-ueL{
      margin: 2em 5vw;
    }

    .works_searchInput__3Zkhk{
      width: 20px;
      height: 3.3vw;
    }
  
    .works_searchInput__3Zkhk:hover, .works_searchInput__3Zkhk:focus, .works_searchInput__3Zkhk select{
      min-width: 40vw;
    }


    .works_svg-inline--fa__1lQLl.works_fa-w-14__38EFz{
      margin: 0;
      margin-bottom: 4px;
    } 

    
    .works_hover__MzJNv h1 {
      font-size: 5vw;
    }
    
    .works_hover__MzJNv .works_caption__3u-L8 {
      font-size: 1.5vw;
    }
}


@media only screen  
  and (min-width : 890px)
  and (max-width : 900px)
  and (orientation : landscape){
    .works_gridContainer__qGsMh{
      grid-template-columns: repeat(2 , 1fr);
      grid-template-rows: repeat(auto-fill , 40vw);
      margin: 0;
      margin: 2em 5vw;
      /* margin-left: 1.6em; */
      /* grid-row-gap: 0px; */
      grid-row-gap: 5px;
      row-gap: 5px;
      /* grid-column-gap: 15px; */
      grid-column-gap: 15px;
      -webkit-column-gap: 15px;
              column-gap: 15px;
    }
  
    .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
      width: 44.2vw;
      height: 37.7vw;
    }
  
    .works_secondaryNav__1-ueL{
      margin: 2em 4.9vw;
    }

    .works_searchInput__3Zkhk{
      width: 20px;
      height: 3.3vw;
    }
  
    .works_searchInput__3Zkhk:hover, .works_searchInput__3Zkhk:focus, .works_searchInput__3Zkhk select{
      min-width: 40vw;
    }


    .works_svg-inline--fa__1lQLl.works_fa-w-14__38EFz{
      margin: 0;
      margin-bottom: 4px;
    }

    
    .works_hover__MzJNv h1{
      font-size: 4vw;
    }

    .works_hover__MzJNv .works_caption__3u-L8{
      font-size: 1.5vw;
    }

    .works_hover__MzJNv h1 {
      font-size: 5vw;
    }
    
    .works_hover__MzJNv .works_caption__3u-L8 {
      font-size: 1.5vw;
    }
    
  
    
}







/* Desktops and laptops ----------- */

@media only screen  
  and (min-width : 720px) 
  and (min-height: 600px){
    
  .works_gridContainer__qGsMh{
    grid-template-columns: repeat(2 , 1fr);
    grid-template-rows: repeat(auto-fill , 32vw);
    margin: 2em 5.3vw;
    /* grid-row-gap: 0px; */
    /* row-gap: 0px; */
    /* grid-column-gap: 19px; */
    grid-column-gap: 19px;
    -webkit-column-gap: 19px;
            column-gap: 19px;
  }

  .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
    width: 43.4vw;
    height: 30vw;
    /* margin-bottom: 0; */
  }


  .works_secondaryNav__1-ueL{
    margin: 2em 5.3vw;
  }

  .works_miniNav__2k1WU{
    margin: -1.5em 0;
  }

  .works_searchBar__3I5CF{
      margin: 0;
  }

  .works_hover__MzJNv h1 {
    font-size: 4.5vw;
  }
  
  .works_hover__MzJNv .works_caption__3u-L8 {
    font-size: 1.5vw;
  }
      
}

@media only screen  
  and (min-width : 900px){
    .works_gridContainer__qGsMh{
      grid-template-columns: repeat(2 , 1fr);
      grid-template-rows: repeat(auto-fill , 40vw);
      margin: 0;
      margin : 1em 5vw;
      /* margin-left: 1.6em; */
      /* grid-row-gap: 0px; */
      grid-row-gap: 5px;
      row-gap: 5px;
      /* grid-column-gap: 15px; */
      grid-column-gap: 15px;
      -webkit-column-gap: 15px;
              column-gap: 15px;
    }
  
    .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
      width: 44.2vw;
      height: 37.7vw;
    }
  
    .works_secondaryNav__1-ueL{
      margin: 1em 4.9vw 0 4.9vw;
    }

    .works_miniNav__2k1WU{
      margin: -1.5em 0;
    }

    .works_searchBar__3I5CF{
      margin: 1em 0;
    }

    .works_searchInput__3Zkhk{
      width: 20px;
      height: 3.3vw;
    }
  
    .works_searchInput__3Zkhk:hover, .works_searchInput__3Zkhk:focus, .works_searchInput__3Zkhk select{
      min-width: 15vw;
    }


    .works_svg-inline--fa__1lQLl.works_fa-w-14__38EFz{
      margin: 0;
      margin-bottom: 4px;
    }

    
    .works_hover__MzJNv h1{
      font-size: 4vw;
    }

    .works_hover__MzJNv .works_caption__3u-L8{
      font-size: 1.5vw;
    }

    .works_hover__MzJNv h1 {
      font-size: 5vw;
    }
    
    .works_hover__MzJNv .works_caption__3u-L8 {
      font-size: 1.5vw;
    }
    
  
    
}

@media only screen and (min-width: 1024px) {
  .works_secondaryNav__1-ueL{
    margin: -1.5em 3.3vw 1em 3.3vw;
  }
  
  .works_gridContainer__qGsMh{
    grid-template-columns: repeat(3 , 1fr);
    grid-template-rows: repeat(auto-fill , 20vw);
    margin: 1em 3.3vw;
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 19px;
    -webkit-column-gap: 19px;
            column-gap: 19px;
  }

  .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
    width: 29.8vw;
    height: 20vw;
    margin-bottom: 0;
  }

  .works_searchInput__3Zkhk{
    width: 20px;
    height: 1.5vw;
  }

  
  .works_miniNav__2k1WU , .works_searchBar__3I5CF{
    margin: 0;
    margin-bottom: 1em;
  }

  .works_hover__MzJNv h1 {
    font-size: 2.5vw;
  }
  
  .works_hover__MzJNv .works_caption__3u-L8 {
    font-size: 1vw;
  }

}

@media only screen and (min-width : 1280px) {

  .works_secondaryNav__1-ueL{
    margin: 1em 3.3vw -1em 3.3vw;
  }

  .works_miniNav__2k1WU{
    margin: -1.5em 0;
  }

  .works_searchBar__3I5CF{
    margin: 1em 0;
  }

  .works_searchInput__3Zkhk:hover, .works_searchInput__3Zkhk:focus, .works_searchInput__3Zkhk select{
    min-width: 10vw;
  }

  .works_gridContainer__qGsMh{
    grid-template-columns: repeat(4 , 1fr);
    grid-template-rows: repeat(auto-fill , 17.5vw);
    margin: 2em 3.3vw;
    grid-row-gap: 0px;
    row-gap: 0px;
    grid-column-gap: 19px;
    -webkit-column-gap: 19px;
            column-gap: 19px;
  }

  .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
    width: 22vw;
    height: 16vw;
    margin-bottom: 1.5vw;
  }

  .works_hover__MzJNv h1 {
    font-size: 2.4vw;
  }
  
  .works_hover__MzJNv .works_caption__3u-L8 {
    font-size: 0.8vw;
  }

  .works_searchInput__3Zkhk{
    width: 20px;
    height: 1.5vw;
  }


}


@media only screen and (min-width : 1300px) {

  .works_secondaryNav__1-ueL{
    margin: 1em 3.3vw -1em 3.3vw;
  }

  .works_miniNav__2k1WU{
    margin: -1.5em 0;
  }

  .works_searchBar__3I5CF{
    margin: 1em 0;
  }

  .works_searchInput__3Zkhk:hover, .works_searchInput__3Zkhk:focus, .works_searchInput__3Zkhk select{
    min-width: 10vw;
  }

  .works_gridContainer__qGsMh{
    grid-template-columns: repeat(4 , 1fr);
    grid-template-rows: repeat(auto-fill , 17.5vw);
    margin: 2em 3.3vw;
    grid-row-gap: 0px;
    row-gap: 0px;
    grid-column-gap: 19px;
    -webkit-column-gap: 19px;
            column-gap: 19px;
  }

  .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
    width: 22vw;
    height: 16vw;
    margin-bottom: 2vw;
  }

  .works_hover__MzJNv h1 {
    font-size: 2.4vw;
  }
  
  .works_hover__MzJNv .works_caption__3u-L8 {
    font-size: 0.8vw;
  }

  .works_searchInput__3Zkhk{
    width: 20px;
    height: 1.5vw;
  }


}


/*LCD Displays*/
@media only screen 
  and (min-width: 1024px) 
  and (max-width: 1080px)  
  and (min-height : 800px) 
  and (max-height : 900px) {

    .works_gridContainer__qGsMh{
      grid-template-columns: repeat(4 , 1fr);
      grid-template-rows: repeat(auto-fill , 16vw);
      margin: 2em 3.3vw;
      grid-row-gap: 20px;
      row-gap: 20px;
      grid-column-gap: 19px;
      -webkit-column-gap: 19px;
              column-gap: 19px;
    }

    .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
      width: 22.9vw;
      height: 16vw;
    }

    .works_secondaryNav__1-ueL{
      margin: 2em 3.3vw;
    }

    .works_miniNav__2k1WU{
      margin: -1.5em 0;
    }

    .works_searchBar__3I5CF{
      margin: 1em 0;
    }
    .works_searchInput__3Zkhk:hover, .works_searchInput__3Zkhk:focus, .works_searchInput__3Zkhk select{
      min-width: 10vw;
    }

    
    .works_hover__MzJNv h1 {
      font-size: 2.4vw;
    }
    
    .works_hover__MzJNv .works_caption__3u-L8 {
      font-size: 0.8vw;
    }

    .works_searchInput__3Zkhk{
      width: 20px;
      height: 2vw;
    }

}


/* Basic Laptop Screens */
@media only screen and (min-width : 1440px) {
  .works_gridContainer__qGsMh{
    grid-template-columns: repeat(4 , 1fr);
    grid-template-rows: repeat(auto-fill , 16vw);
    margin: 1em 3.3vw;
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 19px;
    -webkit-column-gap: 19px;
            column-gap: 19px;
  }

  .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
    width: 22.3vw;
    height: 16vw;
    margin-bottom: 0;
  }

  .works_secondaryNav__1-ueL{
    margin: 1em 3.3vw;
  }

  .works_miniNav__2k1WU{
      margin: -1.5em 0;
  }

  .works_searchBar__3I5CF{
      margin: 1em 0;
  }

  
  .works_hover__MzJNv h1 {
    font-size: 2.4vw;
  }
  
  .works_hover__MzJNv .works_caption__3u-L8 {
    font-size: 0.8vw;
  }

  .works_searchInput__3Zkhk{
    width: 20px;
    height: 1.5vw;
  }



}

/* Larger laptop screens */
@media only screen  and (min-width : 1500px) {
  .works_gridContainer__qGsMh{
    grid-template-columns: repeat(4 , 1fr);
    grid-template-rows: repeat(auto-fill , 16vw);
    margin: 1em 3.3vw;
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 19px;
    -webkit-column-gap: 19px;
            column-gap: 19px;
  }

  .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
    width: 22.3vw;
    height: 16vw;
  }

  .works_secondaryNav__1-ueL{
    margin: 1em 3.3vw;
  }

  .works_miniNav__2k1WU{
    margin: -1.5em 0;
  }

  .works_searchBar__3I5CF{
    margin: 1em 0;
  }

  .works_searchInput__3Zkhk{
    width: 20px;
    height: 1.5vw;
  }

}


/* Large screens ----------- */
@media only screen  and (min-width : 1600px) {
  
  .works_gridContainer__qGsMh{
    grid-template-columns: repeat(4 , 1fr);
    grid-template-rows: repeat(auto-fill , 16.6vw);
    margin: 1em 3.3vw;
    grid-row-gap: 19px;
    row-gap: 19px;
    grid-column-gap: 19px;
    -webkit-column-gap: 19px;
            column-gap: 19px;
  }

  .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
    width: 22.4vw;
    height: 16.6vw;
  }

  .works_secondaryNav__1-ueL{
    margin: 1em 3.3vw;
  }

  .works_miniNav__2k1WU{
    margin: -1.5em 0;
  }

  .works_searchBar__3I5CF{
    margin: 1em 0;
  }
  
  .works_searchInput__3Zkhk{
    width: 20px;
    height: 1.5vw;
  }

}

@media only screen  and (min-width : 1700px) {
  
  .works_gridContainer__qGsMh{
    grid-template-columns: repeat(4 , 1fr);
    grid-template-rows: repeat(auto-fill , 16.6vw);
    margin: 1em 3.3vw;
    grid-row-gap: 19px;
    row-gap: 19px;
    grid-column-gap: 19px;
    -webkit-column-gap: 19px;
            column-gap: 19px;
  }

  .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
    width: 22.5vw;
    height: 16.6vw;
  }

  .works_secondaryNav__1-ueL{
    margin: 1em 3.3vw;
  }

  .works_miniNav__2k1WU{
    margin: -1.5em 0;
  }

  .works_searchBar__3I5CF{
    margin: 1em 0;
  }

  .works_searchInput__3Zkhk{
    width: 20px;
    height: 1.5vw;
  }
}

@media only screen  and (min-width : 1900px) {
  
  .works_gridContainer__qGsMh{
    grid-template-columns: repeat(4 , 1fr);
    grid-template-rows: repeat(auto-fill , 16.6vw);
    margin: 1em 3.3vw;
    grid-row-gap: 19px;
    row-gap: 19px;
    grid-column-gap: 19px;
    -webkit-column-gap: 19px;
            column-gap: 19px;
  }

  .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
    width: 22.5vw;
    height: 16.6vw;
  }

  .works_secondaryNav__1-ueL{
    margin: 1em 3.3vw;
  }

  .works_miniNav__2k1WU{
    margin: -1.5em 0;
  }

  .works_searchBar__3I5CF{
    margin: 1em 0;
  }

  .works_searchInput__3Zkhk{
    width: 20px;
    height: 1.5vw;
  }
  
}


@media only screen  and (min-width : 2000px) {
  
  .works_gridContainer__qGsMh{
    grid-template-columns: repeat(4 , 1fr);
    grid-template-rows: repeat(auto-fill , 16.6vw);
    margin: 1em 3.3vw;
    grid-row-gap: 19px;
    row-gap: 19px;
    grid-column-gap: 19px;
    -webkit-column-gap: 19px;
            column-gap: 19px;
  }

  .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
    width: 22.7vw;
    height: 16.6vw;
  }

  .works_secondaryNav__1-ueL{
    margin: 1em 3.3vw;
  }

  .works_miniNav__2k1WU{
    margin: -1.5em 0;
  }

  .works_searchBar__3I5CF{
    margin: 1em 0;
  }

  .works_searchInput__3Zkhk{
    width: 20px;
    height: 1.5vw;
  }
  
}


/* Macbook Pro */
@media only screen 
  and (min-width: 1700px) 
  and (max-width: 1800px)  
  and (min-height : 1080px) 
  and (max-height : 1100px) {
  .works_gridContainer__qGsMh{
    grid-template-columns: repeat(4 , 1fr);
    grid-template-rows: repeat(auto-fill , 16vw);
    margin: 1em 3.3vw;
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 19px;
    -webkit-column-gap: 19px;
            column-gap: 19px;
  }

  .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
    width: 22.5vw;
    height: 16vw;
  }

  .works_secondaryNav__1-ueL{
    margin: 1em 3.3vw;
  }

  .works_miniNav__2k1WU{
    margin: -1.5em 0;
  }

  .works_searchBar__3I5CF{
    margin: 1em 0;
  }

  .works_searchInput__3Zkhk{
    width: 20px;
    height: 1.5vw;
  }

}


/* Potrait Orientation for Tablets and Mobiles */

@media only screen 
  and (min-width: 600px)
  and (max-width: 720px)
  and (orientation : portrait)
  and (-webkit-min-device-pixel-ratio: 1){
  .works_gridContainer__qGsMh{
    grid-template-columns: repeat(2 , 1fr);
    grid-template-rows: repeat(auto-fill , 40vw);
    margin: 2em 5.3vw;
    grid-row-gap: 0px;
    row-gap: 0px;
    grid-column-gap: 19px;
    -webkit-column-gap: 19px;
            column-gap: 19px;
  }

  .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
    width: 43.4vw;
    height: 36.4vw;
  }

  .works_secondaryNav__1-ueL{
    margin: 2em 5.3vw;
  }

  .works_searchInput__3Zkhk{
    width: 20px;
    height: 4vw;
  }

  .works_hover__MzJNv h1 {
    font-size: 4.5vw;
  }
  
  .works_hover__MzJNv .works_caption__3u-L8 {
    font-size: 1.5vw;
  }

}

@media only screen 
  and (min-width: 720px) 
  and (max-width: 769px) 
  and (orientation : portrait)
  and (-webkit-device-pixel-ratio: 1){
  .works_gridContainer__qGsMh{
    grid-template-columns: repeat(2 , 1fr);
    grid-template-rows: repeat(auto-fill , 16vw);
    margin: 2em 5.3vw;
    grid-row-gap: 0px;
    row-gap: 0px;
    grid-column-gap: 19px;
    -webkit-column-gap: 19px;
            column-gap: 19px;
  }

  .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
    width: 22.9vw;
    height: 16vw;
  }


  .works_secondaryNav__1-ueL{
    margin: 2em 5.3vw;
  }

  .works_hover__MzJNv h1 {
    font-size: 4.5vw;
  }
  
  .works_hover__MzJNv .works_caption__3u-L8 {
    font-size: 1.5vw;
  }

}


@media only screen 
  and (min-width: 769px) 
  and (max-width: 850px)
  and (max-height : 1300px)
  and (orientation : portrait){
  .works_gridContainer__qGsMh{
    grid-template-columns: repeat(2 , 1fr);
    grid-template-rows: repeat(auto-fill , 35vw);
    margin: 2em 5.3vw;
    grid-row-gap: 0px;
    row-gap: 0px;
    grid-column-gap: 19px;
    -webkit-column-gap: 19px;
            column-gap: 19px;
  }

  .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
    width: 43.6vw;
    height: 32.5vw;
  }


  .works_secondaryNav__1-ueL{
    margin: 2em 5.3vw;
  }

  .works_hover__MzJNv h1 {
    font-size: 4.5vw;
  }
  
  .works_hover__MzJNv .works_caption__3u-L8 {
    font-size: 1.5vw;
  }

}

@media only screen 
  and (min-width: 850px) 
  and (max-width: 899px) 
  and (orientation : portrait){
  .works_gridContainer__qGsMh{
    grid-template-columns: repeat(2 , 1fr);
    grid-template-rows: repeat(auto-fill , 16vw);
    margin: 2em 5.3vw;
    grid-row-gap: 0px;
    row-gap: 0px;
    grid-column-gap: 19px;
    -webkit-column-gap: 19px;
            column-gap: 19px;
  }

  .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
    width: 22.9vw;
    height: 16vw;
  }


  .works_secondaryNav__1-ueL{
    margin: 2em 5.3vw;
  }

  .works_hover__MzJNv h1 {
    font-size: 4.5vw;
  }
  
  .works_hover__MzJNv .works_caption__3u-L8 {
    font-size: 1.5vw;
  }

}




@media only screen 
  and (min-width: 900px) 
  and (max-width: 959px) 
  and (orientation : portrait){
  .works_gridContainer__qGsMh{
    grid-template-columns: repeat(2 , 1fr);
    grid-template-rows: repeat(auto-fill , 16vw);
    margin: 2em 5.3vw;
    grid-row-gap: 0px;
    row-gap: 0px;
    grid-column-gap: 19px;
    -webkit-column-gap: 19px;
            column-gap: 19px;
  }

  .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
    width: 22.9vw;
    height: 16vw;
  }


  .works_secondaryNav__1-ueL{
    margin: 2em 5.3vw;
  }

  .works_hover__MzJNv h1 {
    font-size: 4.5vw;
  }
  
  .works_hover__MzJNv .works_caption__3u-L8 {
    font-size: 1.5vw;
  }

}


/*Ipad Potrait*/
@media only screen 
and (min-width : 768px) 
and (max-width : 1024px)
and (max-height: 1024px) 
and (orientation : portrait) 
and (-webkit-min-device-pixel-ratio: 1)
{

  .works_gridContainer__qGsMh{
    grid-template-columns: repeat(2 , 1fr);
    grid-template-rows: repeat(auto-fill , 35vw);
    margin: 2em 5.3vw;
    grid-row-gap: 0px;
    row-gap: 0px;
    grid-column-gap: 19px;
    -webkit-column-gap: 19px;
            column-gap: 19px;
  }

  .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
    width: 43.6vw;
    height: 32.5vw;
  }

  .works_secondaryNav__1-ueL{
    margin: 2em 5.3vw;
  }

  .works_hover__MzJNv h1 {
    font-size: 4.5vw;
  }
  
  .works_hover__MzJNv .works_caption__3u-L8 {
    font-size: 1.5vw;
  }
  .works_searchBar__3I5CF{
    margin: 0;
  }

}

/* IPad Pro 10.5 Portrait */
@media only screen 
  and (min-width: 834px)
  and (max-width: 834px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1)
  {
    .works_gridContainer__qGsMh{
      grid-template-columns: repeat(2 , 1fr);
      grid-template-rows: repeat(auto-fill , 35vw);
      margin: 2em 5.3vw;
      grid-row-gap: 0px;
      row-gap: 0px;
      grid-column-gap: 19px;
      -webkit-column-gap: 19px;
              column-gap: 19px;
    }
  
    .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
      width: 43.6vw;
      height: 32.5vw;
    }

    .works_secondaryNav__1-ueL{
      margin: 2em 5.3vw;
    }
  
    .works_hover__MzJNv h1 {
      font-size: 4.5vw;
    }
    
    .works_hover__MzJNv .works_caption__3u-L8 {
      font-size: 1.5vw;
    }
  

}

/* IPad Pro 12.5 Portrait */
@media only screen
and (min-width: 1024px)
and (max-width: 1024px)
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 1)
{
  .works_gridContainer__qGsMh{
    grid-template-columns: repeat(2 , 1fr);
    grid-template-rows: repeat(auto-fill , 35vw);
    margin: 2em 5.3vw;
    grid-row-gap: 0px;
    row-gap: 0px;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
  }

  .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
    width: 44vw;
    height: 32.5vw;
  }

  .works_secondaryNav__1-ueL{
    margin: 2em 5.3vw;
  }

  .works_miniNav__2k1WU{
    margin: -1.5em 0em;
  }

  .works_searchBar__3I5CF{
    margin: 1em 0 0 0;
  }

  .works_searchInput__3Zkhk{
    width: 20px;
    height: 2.5vw;
  }

  .works_hover__MzJNv h1 {
    font-size: 4.5vw;
  }
  
  .works_hover__MzJNv .works_caption__3u-L8 {
    font-size: 1.5vw;
  }


}







/* Landscape Orientation for Tablets and Mobiles */


/* Tablets and IPads */




@media only screen 
  and (min-width: 960px) 
  and (max-width: 1023px) 
  and (orientation : landscape){

  .works_miniNav__2k1WU , .works_searchBar__3I5CF{
    margin: 0;
    margin-bottom: 1em;
  }

  .works_secondaryNav__1-ueL{
    margin: -1.5em 3.3vw;
  }

  .works_gridContainer__qGsMh{
    grid-template-columns: repeat(3 , 1fr);
    grid-template-rows: repeat(auto-fill , 20vw);
    margin: 2em 3.3vw;
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 19px;
    -webkit-column-gap: 19px;
            column-gap: 19px;
  }

  .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
    width: 29.8vw;
    height: 20vw;
    margin-bottom: 0;
  }

  .works_searchInput__3Zkhk{
    width: 20px;
    height: 2.4vw;
  }

  .works_searchInput__3Zkhk:hover, .works_searchInput__3Zkhk:focus{
    width: 30vw;
  }

  .works_hover__MzJNv h1 {
    font-size: 2.5vw;
  }
  
  .works_hover__MzJNv .works_caption__3u-L8 {
    font-size: 1vw;
  }
} 

@media only screen 
  and (min-width: 1023px) 
  and (max-width: 1179px) 
  and (orientation : landscape)
  and (-webkit-min-device-pixel-ratio: 2){

  .works_secondaryNav__1-ueL{
    margin: -1.5em 3.3vw;
  }
  
  .works_gridContainer__qGsMh{
    grid-template-columns: repeat(3 , 1fr);
    grid-template-rows: repeat(auto-fill , 20vw);
    margin: 1em 3.3vw;
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 19px;
    -webkit-column-gap: 19px;
            column-gap: 19px;
  }

  .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
    width: 29.8vw;
    height: 20vw;
    margin-bottom: 0;
  }

  .works_searchInput__3Zkhk{
    width: 20px;
    height: 2.4vw;
  }

  
  .works_miniNav__2k1WU , .works_searchBar__3I5CF{
    margin: 0;
    margin-bottom: 1em;
  }

  .works_hover__MzJNv h1 {
    font-size: 2.5vw;
  }
  
  .works_hover__MzJNv .works_caption__3u-L8 {
    font-size: 1vw;
  }

}


@media only screen
  and (min-width: 1180px)
  and (max-width: 1194px)
  and (orientation: landscape){

  .works_gridContainer__qGsMh{
    grid-template-columns: repeat(3 , 1fr);
    grid-template-rows: repeat(auto-fill , 20vw);
    margin: 1em 3.3vw;
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 21px;
    -webkit-column-gap: 21px;
            column-gap: 21px;
  }

  .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
    width: 29.8vw;
    height: 20vw;
    margin-bottom: 0;
  }

  .works_searchInput__3Zkhk{
    width: 20px;
    height: 2.4vw;
  }
  
  .works_miniNav__2k1WU , .works_searchBar__3I5CF{
    margin: 0;
    margin-bottom: 1em;
  }

  .works_hover__MzJNv h1 {
    font-size: 2.5vw;
  }
  
  .works_hover__MzJNv .works_caption__3u-L8 {
    font-size: 1vw;
  }

}

@media only screen 
  and (min-width : 1280px) 
  and (min-height : 740px) 
  and (max-height : 750px) {

  .works_gridContainer__qGsMh{
    grid-template-columns: repeat(3 , 1fr);
    grid-template-rows: repeat(auto-fill , 20vw);
    margin: 1em 3.3vw;
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 19px;
    -webkit-column-gap: 19px;
            column-gap: 19px;
  }

  .works_searchInput__3Zkhk{
    width: 20px;
    height: 2.4vw;
  }
  

  .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
    width: 29.8vw;
    height: 20vw;
    margin-bottom: 0;
  }

  .works_secondaryNav__1-ueL{
    margin: -1.5em 3.3vw;
  }

    
  .works_miniNav__2k1WU , .works_searchBar__3I5CF{
    margin: 0;
    margin-bottom: 1em;
  }

  .works_hover__MzJNv h1 {
    font-size: 2.5vw;
  }
  
  .works_hover__MzJNv .works_caption__3u-L8 {
    font-size: 1vw;
  }

}

/*Ipad Landscape*/
@media only screen 
and (min-height : 768px)
and (max-width : 1024px)
and (orientation : landscape)
and (-webkit-min-device-pixel-ratio: 1) {

  .works_gridContainer__qGsMh{
    grid-template-columns: repeat(3 , 1fr);
    grid-template-rows: repeat(auto-fill , 20vw);
    margin: 1em 3.3vw;
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 19px;
    -webkit-column-gap: 19px;
            column-gap: 19px;
  }

  .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
    width: 29.8vw;
    height: 20vw;
    margin-bottom: 0;
  }

  .works_secondaryNav__1-ueL{
    margin: -1.5em 3.3vw 0 3.3vw;
  }


  .works_searchInput__3Zkhk{
    width: 20px;
    height: 2.4vw;
  }

  .works_searchInput__3Zkhk:hover , .works_searchInput__3Zkhk:focus{
    width: 30vw;
  }
  
  .works_miniNav__2k1WU , .works_searchBar__3I5CF{
    margin: 0;
    margin-bottom: 1em;
  }

  .works_hover__MzJNv h1 {
    font-size: 2.5vw;
  }
  
  .works_hover__MzJNv .works_caption__3u-L8 {
    font-size: 1vw;
  }


}

/* IPad Pro 10.5 Landscape */
@media only screen 
  and (min-height: 834px)
  and (max-width: 1112px) 
  and (orientation: landscape){

  .works_gridContainer__qGsMh{
    grid-template-columns: repeat(3 , 1fr);
    grid-template-rows: repeat(auto-fill , 20vw);
    margin: 1em 3.3vw;
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 19px;
    -webkit-column-gap: 19px;
            column-gap: 19px;
  }

  .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
    width: 29.8vw;
    height: 20vw;
    margin-bottom: 0;
  }

  .works_secondaryNav__1-ueL{
    margin: -1.5em 3.3vw;
  }

  .works_searchInput__3Zkhk{
    width: 20px;
    height: 2.4vw;
  }

  .works_searchInput__3Zkhk:hover , .works_searchInput__3Zkhk:focus{
    width: 30vw;
  }

  .works_searchBar__3I5CF{
    margin: 1em 0 0 0;
  }

  
  .works_miniNav__2k1WU , .works_searchBar__3I5CF{
    margin: 0;
    margin-bottom: 1em;
  }

  .works_hover__MzJNv h1 {
    font-size: 2.5vw;
  }
  
  .works_hover__MzJNv .works_caption__3u-L8 {
    font-size: 1vw;
  } 

}

/* IPad Pro 12.5 Landscape */
@media only screen
  and (min-height: 1024px)
  and (max-height: 1024px)
  and (orientation: landscape){
  .works_gridContainer__qGsMh{
    grid-template-columns: repeat(3 , 1fr);
    grid-template-rows: repeat(auto-fill , 23vw);
    margin: 1em 3.3vw;
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 19px;
    -webkit-column-gap: 19px;
            column-gap: 19px;
  }

  .works_gridItem__OOEE7, .works_gridItem__OOEE7 embed, .works_imageCover__3Uk5e , .works_hover__MzJNv{
    width: 30.2vw;
    height: 23vw;
    margin-bottom: 0;
  }
  
  .works_secondaryNav__1-ueL{
    margin: -1.5em 3.3vw;
  }


  .works_searchInput__3Zkhk{
    width: 20px;
    height: 2vw;
  }

  .works_searchInput__3Zkhk:hover , .works_searchInput__3Zkhk:focus{
    width: 30vw;
  }
  
  .works_miniNav__2k1WU , .works_searchBar__3I5CF{
    margin: 0;
    margin-bottom: 1em;
  }

  .works_hover__MzJNv h1 {
    font-size: 2.5vw;
  }
  
  .works_hover__MzJNv .works_caption__3u-L8 {
    font-size: 1vw;
  }

}




.header_header__CHpwH {
  font-family: "Roboto", sans-serif;
  font-size: 1.3rem;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  margin: 2em 2em;
  align-items: center;
}

.header_header__CHpwH > .header_navMenu__3uIM5 {
  margin: 0;
}

.header_logo__Aoi3- {
  float: left;
  height: 30px;
  object-fit: cover;
  z-index: 1;
}

.header_navList__RLQ9q{
  display: inline-block;

}

.header_navList__RLQ9q > a{
  color: #9e9e9e;
  font-weight: 200;
  margin-left: 3vw;
}

.header_navList__RLQ9q > a:hover {
  text-decoration: none;
  color: #484848;
}

.header_toggler__1EGDW {
  display: none;
  position: absolute;
  width: 1.6rem;
  height: 1.6rem;
  z-index: 999;
  opacity: 0;
}

.header_hamburger__voF3T {
  align-self: flex-end;
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 1.6rem;
  height: 1.6rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1000;
}

.header_hamburger__voF3T > div {
  width: 1.7rem;
  height: 0.15rem;
  border-radius: 10px;
  transition: all 0.2s linear;
  position: relative;
  -webkit-transform-origin: 1px;
          transform-origin: 1px;
  -webkit-clip-path: inset(0 0 0 0);
          clip-path: inset(0 0 0 0);
}

/* .toggler:hover + .hamburger > div:nth-child(2) {
  clip-path: inset(0 0 0 30%);
}

.toggler:hover + .hamburger > div:nth-child(3) {
  clip-path: inset(0 0 0 50%);
} */


.header_toggler__1EGDW:checked + .header_hamburger__voF3T > div:first-child {
  -webkit-transform: rotate(45deg) translateX(1px);
          transform: rotate(45deg) translateX(1px);
}

.header_toggler__1EGDW:checked + .header_hamburger__voF3T > div:nth-child(2) {
  opacity: 0;
}

.header_toggler__1EGDW:checked + .header_hamburger__voF3T > div:nth-child(3) {
  -webkit-clip-path: inset(0 0 0 0);
          clip-path: inset(0 0 0 0);
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}


.header_hamburger__voF3T:focus {
  outline: none;
}


@media only screen
  and (max-width:900px) {
    
  .header_hamburger__voF3T{
    display: flex;
  }
  .header_toggler__1EGDW{
    display: block;
  }
  .header_navList__RLQ9q{
    display: none;
  }

}



/* Smartphone portrait */



@media only screen 
  and (min-width : 240px)
  and (max-width : 320px)
  and (orientation : portrait){

  .header_header__CHpwH{
    margin: 2em 9vw;
  }

    
}

@media only screen
  and (min-width : 320px) 
  and (max-width : 400px)
  and (orientation : portrait){
  .header_header__CHpwH{
    margin: 2em 8vw;
  }
}

@media only screen
  and (min-width : 400px) 
  and (max-width : 428px)
  and (orientation : portrait){
  .header_header__CHpwH{
    margin: 2em 9vw;
  }
}

@media only screen
  and (min-width : 429px) 
  and (max-width : 600px)
  and (orientation : portrait){

  .header_header__CHpwH{
    margin: 2em 9vw;
  }
  
}

  @media only screen 
  and (min-width: 600px)
  and (max-width : 800px)
  and (orientation : portrait){


    .header_header__CHpwH{
      margin: 2em 5.3vw;
    }

}





/* Smartphone Landscape */

@media only screen  
  and (min-width : 320px)
  and (max-width : 460px)
  and (orientation : landscape){

  .header_header__CHpwH{
    margin : 2em 4.9vw;
  }    
}

@media only screen  
  and (min-width : 500px)
  and (max-width : 850px)
  and (orientation : landscape){

  .header_header__CHpwH{
    margin : 2em 4.9vw;
  }


}


@media only screen  
  and (min-width : 890px)
  and (max-width : 900px)
  and (orientation : landscape){

  .header_header__CHpwH{
    margin : 2em 4.9vw;
  }
}




/* Desktops and laptops ----------- */

@media only screen  
  and (min-width : 720px) 
  and (min-height: 600px){
  .header_header__CHpwH{
    margin: 2em 5.3vw;
  }
}

@media only screen  
  and (min-width : 900px){

  .header_header__CHpwH{
    margin : 2em 5vw;
  }

}

@media only screen  
  and (min-width : 1000px){

  .header_header__CHpwH{
    margin : 2em 3.3vw;
  }

}


@media only screen and (min-width : 1110px) {
  .header_header__CHpwH{
    margin: 2em 3.3vw;
  }
}


@media only screen and (min-width : 1280px) {
  .header_header__CHpwH{
    margin: 2em 3.3vw;
  }

}

@media only screen 
  and (min-width : 1279px) 
  and (min-height : 740px) 
  and (max-height : 800px) {
    .header_header__CHpwH{
      margin: 2em 3.3vw;
    }
    
}

/* Basic Laptop Screens */
@media only screen and (min-width : 1440px) {
  .header_header__CHpwH{
    margin: 2em 3.3vw;
  }
  
}

/* Larger laptop screens */
@media only screen  and (min-width : 1500px) {
  
  .header_header__CHpwH{
    margin: 2em 3.3vw;
  }
}


/*LCD Displays*/
@media only screen 
  and (min-width: 1024px) 
  and (max-width: 1080px)  
  and (min-height : 800px) 
  and (max-height : 900px) {
    .header_header__CHpwH{
      margin: 2em 3.3vw;
    }
    
}

/* Large screens ----------- */
@media only screen  and (min-width : 100em) {
  .header_header__CHpwH{
    margin: 2em 3.3vw;
  }
  
}

/* Macbook Pro */
@media only screen 
  and (min-width: 1700px) 
  and (max-width: 1800px)  
  and (min-height : 1080px) 
  and (max-height : 1100px) {
    .header_header__CHpwH{
      margin: 2em 3.3vw;
    }
    
}



/* Potrait Orientation for Tablets and Mobiles */


/* Tablets and IPads */

@media only screen 
  and (min-width: 600px)
  and (max-width: 718px)
  and (orientation : portrait){

  .header_header__CHpwH{
    margin: 2em 5.3vw;
  }

  
}

@media only screen 
  and (min-width: 720px) 
  and (max-width: 768px) 
  and (orientation : portrait){

  .header_header__CHpwH{
    margin: 2em 5.3vw;
  }

    
}


@media only screen 
  and (min-width: 768px) 
  and (max-width: 880px) 
  and (orientation : portrait){

  .header_header__CHpwH{
    margin: 2em 5.3vw;
  }
    
}

/*Ipad Potrait*/
@media only screen 
and (min-width : 768px) 
and (max-width : 1024px)
and (max-height: 1024px) 
and (orientation : portrait) {

  .header_header__CHpwH{
    margin: 2em 5.3vw;
  }
  
}

/* IPad Pro 10.5 Portrait */
@media only screen 
  and (min-width: 834px)
  and (max-width: 834px) 
  and (orientation: portrait) {

  .header_header__CHpwH{
    margin: 2em 5.3vw;
  }

}

/* IPad Pro 12.5 Portrait */
@media only screen
and (min-width: 1024px)
and (max-width: 1024px)
and (orientation: portrait){

  .header_header__CHpwH{
    margin: 2em 5.3vw;
  }

  .header_navList__RLQ9q > a{
    color: #9e9e9e;
    font-weight: 200;
    margin-left: 4vw;
  }

}







/* Landscape Orientation for Tablets and Mobiles */


/* Tablets and IPads */


@media only screen 
  and (min-width: 960px) 
  and (max-width: 1023px) 
  and (orientation : landscape){

  .header_header__CHpwH{
    margin: 2em 3.3vw;
  }

}

@media only screen 
  and (min-width: 1023px) 
  and (max-width: 1179px) 
  and (orientation : landscape)
  and (-webkit-min-device-pixel-ratio: 2){
  
  .header_header__CHpwH{
    margin: 2em 3.3vw;
  }

}


@media only screen
  and (min-width: 1180px)
  and (max-width: 1194px)
  and (orientation: landscape){

  .header_header__CHpwH{
    margin: 2em 3.3vw;
  }

}

@media only screen 
  and (min-width : 1280px) 
  and (min-height : 740px) 
  and (max-height : 800px) {

  .header_header__CHpwH{
    margin: 2em 3.3vw;
  }

}

/*Ipad Landscape*/
@media only screen 
and (min-height : 768px)
and (max-width : 1024px)
and (orientation : landscape)
and (-webkit-min-device-pixel-ratio: 1) {

  .header_header__CHpwH{
    margin: 2em 3.3vw;
  }

}

/* IPad Pro 10.5 Landscape */
@media only screen 
  and (min-height: 834px)
  and (max-width: 1112px) 
  and (orientation: landscape){

  .header_header__CHpwH{
    margin: 2em 3.3vw;
  }

}

/* IPad Pro 12.5 Landscape */
@media only screen
  and (min-height: 1024px)
  and (max-height: 1024px)
  and (orientation: landscape){

  .header_header__CHpwH{
    margin: 2em 3.3vw;
  }

}


.overlayMenu_overlayMenu__1SnbE {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 999;
    width: 100%;
    height: 100%;   
}

.overlayMenu_overlayMenu__1SnbE > nav {
    font-size: 1.5rem;
    z-index: 100;
    display: flex;
    flex-direction: column;
    text-align: center;
    position: fixed;
    top: 47.5%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.overlayMenu_overlayMenu__1SnbE > nav > a {
    display: block;
    padding-top: 1.3em;
    color: #fff;
    cursor: pointer;
    transition: all 0.4s ease;
}

.overlayMenu_overlayMenu__1SnbE > nav > a:hover {
    text-decoration: none;
    color: #9e9e9e;
}

@media only screen
  and (min-width:900px) {
   .overlayMenu_overlayMenu__1SnbE{
     display: none;
   }
}

@media only screen and
    (min-width: 400px){
    .overlayMenu_overlayMenu__1SnbE > nav {
        font-size: 1.5rem;
    }
}
.footer_footer__2xHla {
  margin-top: 3em;
  color: #9e9e9e;
  font-family: "Roboto", sans-serif;
  width: 100vw;
  height: 15vh;
  position: relative;
  float: left;

}

.footer_footer__2xHla span {
  padding: 20px;
  font-size: 0.8em;
  letter-spacing: 2px;
}

.footer_socialIcons__2ZYhw {
  margin-top: 1.5em;
  margin-bottom: 5em;
  width: 100%;
  justify-content: center;
  grid-column-gap: 2vw;
  -webkit-column-gap: 2vw;
          column-gap: 2vw;
  display: flex;
}

.footer_socialIcons__2ZYhw li {
  list-style: none;
}

.footer_socialIcons__2ZYhw a {
  color: #646464;
}

.footer_socialIcons__2ZYhw a:hover {
  color: unset;
}

.footer_socialIcons__2ZYhw svg{
  width: 1.3em;
}

@media screen and (max-width: 768px) {
  .footer_footer__2xHla span {
    padding: 3em 3em 0 3em;
    font-size: 0.55em;
    letter-spacing: 2px;
  }
}

@media only screen and (min-width: 1440px),
  only screen and (-webkit-min-device-pixel-ratio : 1.5),
  only screen and (min-device-pixel-ratio : 1.5){
  .footer_socialIcons__2ZYhw a {
    padding: 1vw;
    color: #646464;
  }

}


.scrolltotop_button__3WTRe {
  cursor: pointer;
  position: fixed;
  right: 4em;
  top: 60%;
  padding-top: 15%;
  right: 2em;
}

.scrolltotop_chev_icon__3BJyS, .scrolltotop_thumbnail__S788-{
  width: 2.5em;
  height: 2.5em;
  opacity: 0.5;
}

@media only screen 
  and (max-width:900px){
  .scrolltotop_scroll_icon__3xxid{
    
  }
}


@media screen and (orientation: portrait) {
  .scrolltotop_button__3WTRe{
    top: 80% !important;
  }
}

@media only screen and (max-width: 768px) {
  .scrolltotop_button__3WTRe {
    top: 70%;
    margin-bottom: 9em;
    /* right: 1em !important; */
  }
}

@media only screen and (max-width: 375px) {
  .scrolltotop_button__3WTRe {
    top: 80% !important;
    /* right: 2em !important; */
  }
}

@media only screen and (max-width: 425px) {
  .scrolltotop_button__3WTRe {
    top: 80% !important;
    /* right: 2em !important; */
  }
}

@media only screen and (width: 768px) {
  .scrolltotop_button__3WTRe {
    top: 80% !important;
    /* right: 5em !important; */
  }
}

@media only screen 
  and (max-height: 575.98px) 
  and (orientation: landscape) {
  
  .scrolltotop_button__3WTRe {
    top: 50% !important;
    /* right: 4em !important; */
  }

}

*{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}


.loader_mainLoad__1OhOB {
  background-color: #fbfcf8;
  font-family: Roboto;
}
.loader_loader__ccUqK {
  margin: 4em 4em;
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 0;
  left: 0;
  text-align: left;
  line-height: 1.1em;
}

.loader_animatedTitle__Up90E {
  font-size: 2.5rem;
  font-weight: 200;
  line-height:normal;
}

.loader_loadingText__1HMSO {
  font-size: 1.5rem;
}


@media only screen 
  and (min-width: 300px){
  .loader_animatedTitle__Up90E {
    font-size: 1.7rem;
    font-weight: 200;
  }
  
  .loader_loadingText__1HMSO {
    font-size: 0.7rem;
  }
}

@media only screen 
  and (min-width: 700px){
  .loader_animatedTitle__Up90E {
    font-size: 1.8rem;
    font-weight: 200;
  }
  
  .loader_loadingText__1HMSO {
    font-size: 0.8rem;
  }
}

@media only screen 
  and (min-width: 900px){
  .loader_animatedTitle__Up90E {
    font-size: 2rem;
    font-weight: 200;
  }
  
  .loader_loadingText__1HMSO {
    font-size: 1rem;
    word-wrap: normal;
    line-height: 2em;
  }
}




* {
  /* box-sizing: border-box; */
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  line-height: 1.5em;
}

.top_section{
  display: flex;
  flex-direction: column;
}


.project_container {
  align-self: left;
}
.project_container .selected{
  width: 100.1% !important;
}

.project_container .selected, .slick-slide, .slick-slider {
  background-size: cover;
  background-repeat: no-repeat;
  height: 50vw;
  /* background-color: black; */
}

.modal_header{
  border: none !important;
}

.selected{
}

.selected, .slick-slide, .slick-slider{
  background-size: contain;
  /* background-color: black; */
}

.fullscreen_view{
  margin: 0 !important;
  height: 100vh !important;
  width: 100vw !important;
  background-color: black;
}

.slick-slider .slick-list{
  margin: 0 !important;
  height: 100% !important;
  width: 100% !important;
  /* background-color: black; */
}

.slick-slider.fullscreen_view.slick-initialized{
  margin: 0;
  height: 100vh;
  width: 100vw;
  background-color: black;
}

.blogMain{
  margin: 0 0 0 12.2vw;
  width: 100% !important;
  display: flex;
  flex-direction: column;
}

.blogMain > h1{
  font-size: 1.25rem;
}


.blogMain > .blog_heading{
  text-align: right;
}
.blog_heading h1{
  font-size: 1.35em !important;
  font-weight: 400 !important;
  margin-bottom: 15px;
}

.blog_heading h5{
  font-weight: 200 !important;
  font-size: 0.8em !important;
}

.blogMain > .blog_content{
  overflow-wrap: break-word;
  align-self: flex-end;
  line-height: 2.1em !important;
  width: 20vw;
  font-size: 0.7rem;
  text-align: justify !important;
  text-align-last: left !important;
  
}

.toolbar{
  width: 100% !important;
}

.blog_heading{
  font-size: 1rem;
}

.slider_container{
  display: flex;
  justify-content:  center;
  margin-top: 0.1em;
}

.thumb_image{
  width: 100%;
	height: 100%;
	object-fit: cover;
}

.svg-inline--fa.fa-w-14{
  width: 1.8em;
  opacity: 0.6;
  cursor: pointer;
}

.thumbnail{
  opacity: 0.6;
  cursor: pointer;
}

.overlay{
  background:rgba(0,0,0,.75);
  text-align:center;
  padding:45px 0 66px 0;
  opacity:0;
  transition: opacity .25s ease;
  -moz-transition: opacity .25s ease;;
  -webkit-transition: opacity .25s ease;
}

.awssld__controls__arrow-left:before, 
.awssld__controls__arrow-left:after, 
.awssld__controls__arrow-right:before, 
.awssld__controls__arrow-right:after{
  background-color: #6F7C80 !important;
  transition: background-color 0.1s ease-out, -webkit-transform 0.1s ease-out !important;
  transition: transform 0.1s ease-out, background-color 0.1s ease-out !important;
  transition: transform 0.1s ease-out, background-color 0.1s ease-out, -webkit-transform 0.1s ease-out !important;
}

.awssld__controls__arrow-right--active:after,
.awssld__controls__arrow-left--active:after
.awssld__controls__arrow-right--active:before,
.awssld__controls__arrow-left--active:before{
  -webkit-transform: none !important;
          transform: none !important;
}

.awssld__controls__arrow-left, .awssld__controls__arrow-right{
  width: 10%;
}

.aws-sld--exit, .aws-sld--exit.aws-sld--moveRight, .aws-sld--exit.aws-sld--moveRight.aws-sld--moveLeft {
  -webkit-animation: scaleOut  4s linear forwards !important;
  animation: scaleOut  4s linear forwards !important;
}

.slick-prev {
  left: 27px;
}

.slick-next {
  right: 30px;
}

.slick-next:before{
  /* content: '→'; */
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAMAAAAp4XiDAAAAAXNSR0IB2cksfwAAAJNQTFRFAAAAAAABAAEAAQAA////AAEBAQAB//////////////////////////7//v////////////7///////7////+jIyMAAEAgICAmZmZAAAAe3p7dHR0gICB//7+bW1tAAAB///+Z2dngYCAYGBgWVlZWVhYX19fZWVl//7/bGxs///+c3Nzenp6/v//l5eX/v///v7/bkhioQAAADF0Uk5TAAAAAAYAAFmd0PP/Foz/jGqdrv////////////////+d/////////9D/Wf//8/+daqugLO4AAAAJcEhZcwAAPEkAADxJAaE7ap4AAAIvSURBVHicjVYJltsgDAXhxA64thunydB2Ot33aXv/09UYhCWBp9V7STD6+tqwiFJKaZWFLImAfDTlZp2CgMwO/t8CJhPgpwwLdmLfywlpZBqAG1SB690cjHyEfTJuVFIC7hmpA8ZTqAU25900TKNXu0Mi2aqjjxHddifrnD11bbOidRF/8KyTF6X6ZwvcDW4VO/Y0hrpMNqGT2CnxVtHBU+cKeR5VwEqyLc8cPV/C91nQsggrPoJ01XzW5RSINxfbz7SwV5PpbQK/uN64G9uX6DXEERF33t9yodd0xny4D9SssdgN9zLaEDeN1pVXoiUpvPL+NSmcc60SFpq2ZAHcz2/8w80RjrdKHcsKnFjb3/mH9/lhcKcy9UVsSjXJhyWfIdfccmzsSyP65z6yGvB3IfaxMPnkr3dbZ6OJeN2wkQn2+YpO5i0wmT7xMM9f0GJYE/yaXnzDKs3O5DfRzI6xY3jfSVg/ZPtbUi/I6yYn4376dDAvqVePomBomo/lLfogXRpZPNkL9HaOsF++fvjFvAgMk+jMPS6mSkyyaANGtfr9HXQHqDhSxbiIcn5ywpqOpn1hLcn3FDLgPPhjuQtL8jDy8owDWfUjGs1iwIqAgMSbxvhjGuMpCBIe87vpm0q///vqRgcVg/U+ModSEeIOFscM3PCEzqRdyAjqBMLrCdQ+6HUxhjMTEFNgahl23IfAVrlqQDxH0bWrDzMyKhWKz1QONvx3779NVWDPROP2X1OdJ6i5Z0LzAAAAAElFTkSuQmCC);
}

.slick-prev:before{
  /* content: '←'; */
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAMAAAAp4XiDAAAAAXNSR0IB2cksfwAAAIpQTFRFAAAAAAABAAEAAQAA/////////////////////v/////////////////////+AQAB/v////7////+/////v7/AQEAgICAAAAAjIyMmZmZe3t7AAEAdHR0bW1tZ2dnYGBgWVlZWFhYgIGAX19fZWRlgYCAbGxsc3Jzenp6AAABl5eX/v////7////+36YaXwAAAC50Uk5TAAAAAAZZndDz//8WjGrzAJ3//67/AP///////////////////////////2qd0PORsFkAAAAJcEhZcwAAPEkAADxJAaE7ap4AAAH+SURBVHiclVYJe8IgDC1HW4rWWd3KnLs3d+///70VLJCUoF0+xR6Bl/cSgkVBGHMjpx7ONUE95LkX51YSYgpPRJM+EgmJHETeDa3qgk98owuHP5k1RRFF4P9UFWAKEkNQl1KOaJz2hPeskGVVq0aruiolBZHYYql0MLVcRErMrcqmJEULJlhbtcDdm9dGXAzD2vk1o3+3Ga6qkF9Gibi97HRi23FlwIm5jwWuNsjXg60JsTwP79vhWU0LvK6QVpG5Rdv05toLt8jsjCWm0Buz89dLeoZ0IF0XZ9xoz03JcRcGzRxsiQj0Zn8LIMsEwSpWAR4W4w5KUUVHn8bhW2Me90i+muSi0IwHrIUKRRuzWMgo8DDj8RhgE+bIIu0UMhLZGfOkJybPBPZszMskMKpkxIT+K0kfdzovcmOjOxjzpgGZiizMUkM7mP07uIWp9JuA2YJx1diE2D68hHolsbe/xGXZ9Oaz93FRZclZKP6NJdA5nK+RjlpQElv7niZjzJNu6b5kBfmB+dZhd1q5+DF83KDtDt1ijOOUbbI8g4JXCYSre1aEwacyHkTT1qfa9FTESeVJgz3Vk8Mb18YH91/bxhlc9ujCwAhNhmpnMBrsOP8Qn3VOYwPJie3jP8B5ojloPuePwsyjmp/0DMd2Do+PQ+Z8Di7xwA7hicQ3F8gfhoAjZ4+IY9QAAAAASUVORK5CYII=);
}

.slick-next:before, .slick-prev:before{
  font-size: 40px;
}

.slick-slide{
  width: 100%;
}


.slick-next, .slick-prev {
  font-size: 0;
  line-height: 0;
  top: 50%;
  width: 40px;
  height: 40px;
  -webkit-transform: translate(0,-50%);
  transform: translate(0,-50%);
  cursor: pointer;
  color: #6F7C80;
  border: black;
  outline: 0;
  z-index: 2 !important;
  background: 0 0;
}

.slick-next:before, .slick-prev:before {
  font-size: 25px;
  line-height: 1;
  opacity: .75;
  color: #6F7C80;
}
/* @media only screen and (max-width:900px) {
  
  .slick-next:before{
    content: url(../../../assets/images/chevron-right-solid-m.svg);
  }

  .slick-prev:before{
    content: '←';
  }
} */



@media only screen and (max-width:700px) {

  .slick-next, .slick-prev{
    /* top: 40% !important; */
  }

  .slick-prev {
    left: 10px;
  }
  
  .slick-next {
    right: 10px !important;
  }

  .blogMain > .blog_heading{
    margin-top: 1em;
  }
}

@media only screen and (max-width:300px) {

  .slick-next, .slick-prev{
    /* top: 40%; */
  }
  
}


@-webkit-keyframes scaleOut  {
  0%,100% { opacity: 0; }
  50% { opacity: 1; }
}

@keyframes scaleOut  {
  0%,100% { opacity: 0; }
  50% { opacity: 1; }
}

@media only screen and (aspect-ratio: 16/10){
  .fullscreen_view .slick-slide{
    -webkit-transform: translateY(6%);
            transform: translateY(6%);
  }
}



@media screen and (min-width: 200px) and (max-width: 300px) and (max-height: 600px) and (orientation : portrait) {
  .fullscreen_view  .slick-slide{
    /* height: 50% !important; */
    /* width: fit-content !important; */
    object-position: center;
    object-fit: cover !important;
    /* transform: translateY(150%); */
    margin-top: 37vh;
  }
}

@media screen and (min-width: 300px) and (max-width: 350px) and (orientation : portrait) {
  .fullscreen_view  .slick-slide{
    /* height: 50% !important; */
    /* width: fit-content !important; */
    object-position: center;
    object-fit: cover !important;
    /* transform: translateY(130%); */
    margin-top: 35vh; 
  }
}

@media screen and (min-width: 350px) and (max-width: 400px) and (orientation : portrait) {
  .fullscreen_view  .slick-slide{
    /* height: 50% !important; */
    /* width: fit-content !important; */
    object-position: center;
    object-fit: cover !important;
    /* transform: translateY(130%); */
    margin-top: 36vh; 
  }
}

@media screen and (min-width: 400px) and (max-width: 500px) and (orientation : portrait) {
  .fullscreen_view  .slick-slide{
    /* height: 50% !important; */
    /* width: fit-content !important; */
    object-position: center;
    object-fit: cover !important;
    /* transform: translateY(130%); */
    margin-top: 36vh; 
  }
}


@media screen and (min-width: 500px) and (max-width: 600px) and (orientation : portrait) {
  .fullscreen_view  .slick-slide{
    /* height: 50% !important; */
    /* width: fit-content !important; */
    object-position: center;
    object-fit: cover !important;
    /* transform: translateY(150%); */
    margin-top: 36vh; 
  }
}

@media screen and (min-width: 600px) and (max-width: 700px) and (orientation : portrait) {
  .fullscreen_view  .slick-slide{
    /* height: 50% !important; */
    /* width: fit-content !important; */
    object-position: center;
    object-fit: cover !important;
    /* transform: translateY(150%); */
    margin-top: 36vh; 
  }
}


@media screen and (min-width: 700px) and (max-width: 800px) and (orientation : portrait) {
  .fullscreen_view  .slick-slide{
    /* height: 50% !important; */
    /* width: fit-content !important; */
    object-position: center;
    object-fit: cover !important;
    /* transform: translateY(150%); */
    margin-top: 28vh; 
  }
}



@media screen and (min-width: 800px) and (max-width: 900px) and (orientation : portrait) {
  .fullscreen_view  .slick-slide{
    /* height: 50% !important; */
    /* width: fit-content !important; */
    object-position: center;
    object-fit: cover !important;
    /* transform: translateY(130%); */
    margin-top: 30vh; 
  }
}


@media screen and (min-width: 900px) and (max-width: 1000px) and (orientation : portrait) {
  .fullscreen_view  .slick-slide{
    /* height: 50% !important; */
    /* width: fit-content !important; */
    object-position: center;
    object-fit: cover !important;
    /* transform: translateY(100%); */
    margin-top: 35vh; 
  }
}

@media screen and (min-width: 1000px) and (max-width: 1200px) and (orientation : portrait) {
  .fullscreen_view  .slick-slide{
    /* height: 50% !important; */
    /* width: fit-content !important; */
    object-position: center;
    object-fit: cover !important;
    /* transform: translateY(130%); */
    margin-top: 35vh; 
  }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) and (orientation : portrait) {
  .fullscreen_view  .slick-slide{
    /* height: 50% !important; */
    /* width: fit-content !important; */
    object-position: center;
    object-fit: cover !important;
    /* transform: translateY(130%); */
    margin-top: 34vh; 
  }
}

@media screen and (min-width: 1400px) and (max-width: 1600px) and (orientation : portrait) {
  .fullscreen_view  .slick-slide{
    /* height: 50% !important; */
    /* width: fit-content !important; */
    object-position: center;
    object-fit: cover !important;
    /* transform: translateY(130%); */
    margin-top: 32vh; 
  }
}

@media screen and (min-width: 1600px) and (max-width: 1800px) and (orientation : portrait) {
  .fullscreen_view  .slick-slide{
    /* height: 50% !important; */
    /* width: fit-content !important; */
    object-position: center;
    object-fit: cover !important;
    /* transform: translateY(130%); */
    margin-top: 30vh; 
  }
}


@media screen and (min-width: 1800px) and (max-width: 1900px) and (orientation : portrait) {
  .fullscreen_view  .slick-slide{
    /* height: 50% !important; */
    /* width: fit-content !important; */
    object-position: center;
    object-fit: cover !important;
    /* transform: translateY(130%); */
    margin-top: 28vh; 
  }
}

@media screen and (min-width: 1900px) and (max-width: 2900px) and (orientation : portrait) {
  .fullscreen_view  .slick-slide{
    /* height: 50% !important; */
    /* width: fit-content !important; */
    object-position: center;
    object-fit: cover !important;
    /* transform: translateY(130%); */
    margin-top: 28vh; 
  }
}






/* Smartphone portrait */



@media only screen 
  and (min-width : 240px)
  and (max-width : 360px)
  and (orientation : portrait){

  .project_container{
    margin: 2em 8vw;
  }

  .top_section{
    display: flex;
    flex-direction: column;
  }

  .project_container .selected, .slick-slide, .slick-slider{
    width: 84vw;
    height: 46vw;
  }

  .blogMain{
    margin: 0;
  }

  .blogMain > .blog_heading > h1{
    font-size: 1.8rem;
  }

  .blogMain > .blog_content{
    width: 100%;
    text-align: justify;
    text-align-last: center;
  }

  .toolbar{
    display: none;
    width: 100% !important;
  }

}

@media only screen
  and (min-width : 360px) 
  and (max-width : 399px)
  and (orientation : portrait){

  .project_container{
    margin: 2em 8vw;
  }

  .top_section{
    display: flex;
    flex-direction: column;
  }

  .project_container .selected, .slick-slide, .slick-slider{
    width: 84vw;
    height: 47vw;
  }

  .blogMain{
    margin: 0;
  }

  .blogMain > .blog_heading > h1{
    font-size: 1.8rem;
  }

  .blogMain > .blog_content{
    width: 100%;
    text-align: justify;
    text-align-last: center;
  }

  .toolbar{
    display: none;
  }   

}


@media only screen
  and (min-width : 400px) 
  and (max-width : 428px)
  and (orientation : portrait){

  .project_container{
    margin: 2em 8vw;
  }

  .top_section{
    display: flex;
    flex-direction: column;
  }

  .project_container .selected, .slick-slide, .slick-slider{
    width: 85vw;
    height: 46vw;
  }

  .blogMain{
    margin: 0;
  }

  .blogMain > .blog_heading > h1{
    font-size: 1.8rem;
  }

  .blogMain > .blog_content{
    width: 100%;
    text-align: justify;
    text-align-last: center;
  }

  .toolbar{
    display: none;
    width: 90vw;
  }
      
}

@media only screen
  and (min-width : 428px) 
  and (max-width : 500px)
  and (orientation : portrait){

  .project_container{
    margin: 2em 9vw;
  }

  .top_section{
    display: flex;
    flex-direction: column;
  }

  .project_container .selected, .slick-slide, .slick-slider{
    width: 86vw;
    height: 46vw;
  }

  .blogMain{
    margin: 0;
  }

  .blogMain > .blog_heading > h1{
    font-size: 1.8rem;
  }

  .blogMain > .blog_content{
    width: 100%;
    text-align: justify;
    text-align-last: center;
  }

  .toolbar{
    display: none;
    width: 90vw;
  }

}

@media only screen
  and (min-width : 500px) 
  and (max-width : 600px)
  and (orientation : portrait){

  .project_container{
    margin: 2em 9vw;
  }

  .top_section{
    display: flex;
    flex-direction: column;
  }

  .project_container .selected, .slick-slide, .slick-slider{
    width: 86vw;
    height: 46vw;
  }

  .blogMain{
    margin: 0;
  }

  .blogMain > .blog_heading > h1{
    font-size: 1.8rem;
  }

  .blogMain > .blog_content{
    width: 90%;
  }

  .toolbar{
    display: none;
    width: 93vw;
  }
      
}

@media only screen 
  and (min-width: 600px)
  and (max-width : 800px)
  and (orientation : portrait){

  .project_container{
    margin: 2em 5.3vw;
  }
    
  .top_section{
    display: flex;
    flex-direction: column;
  }

  .project_container .selected, .slick-slide, .slick-slider{
    width: 86vw;
    height: 50vw;
  }

  .blogMain{
    margin: 0;
  }

  .blogMain > .blog_heading > h1{
    font-size: 1.8rem;
  }

  .blogMain > .blog_content{
    width: 109%;
  }

  .toolbar{
    width: 93vw;
  }

  .thumb_image{
    width: 80vw;
    height: 35vh;
  }

  .svg-inline--fa.fa-w-14, .thumbnail{
    float: right;
    margin-top: 10px;
    width: 1.3em;
    height: auto;
  }

  .thumbnail{
    margin-right: 2em;
    margin-top: 0.7em;
  }
}



/* Smartphone Landscape */

@media only screen  
  and (min-width : 320px)
  and (max-width : 499px)
  and (orientation : landscape){

    .project_container{
      margin : 2em 4.9vw;
    }

    .project_container .selected, .slick-slide, .slick-slider {
      height: 40vw;
      width: 90vw;
    }
  
    .blogMain{
      margin: 3em 0 0 0;
      width: 100%;
    }
   
    .blogMain > .blog_heading{
      display: block;
      float: right;
      text-align: right;
    }
  
    .blogMain > .blog_heading > h1{
      font-size: 3rem;
      margin-bottom: 0.6em;
      
    }
  
    .blogMain > .blog_content{
      margin-top: 3em;
      width: 70%;
    }
  
    .blog_content > p{
      font-size: 1rem;
    }
  
    .toolbar{
      width: 100%;
    }
  
    .thumb_image{
      width: 30vw;
      height: 30vh;
    }
  
    .svg-inline--fa.fa-w-14, .thumbnail{
      float: right;
      margin-top: 10px;
      width: 1.3em;
      height: auto;
    }
  
    .thumbnail{
      margin-right: 2em;
      margin-top: 0.7em;
    }
    .thumbnail{
      display: none;
    }
}

@media only screen  
  and (min-width : 500px)
  and (max-width : 599px)
  and (orientation : landscape){

  .project_container{
    margin : 2em 4.9vw;
  }

  .project_container .selected, .slick-slide, .slick-slider {
    height: 50vw;
    width: 90vw;
  }

  .blogMain{
    margin: 3em 0 0 0;
    width: 100%;
  }
  
  .blogMain > .blog_heading{
    display: block;
    float: right;
    text-align: right;
  }

  .blogMain > .blog_heading > h1{
    font-size: 3rem;
    margin-bottom: 0.6em;
    
  }

  .blogMain > .blog_content{
    margin-top: 3em;
    width: 70%;
  }

  .blog_content > p{
    font-size: 1rem;
  }

  .toolbar{
    width: 100%;
  }

  .thumb_image{
    width: 30vw;
    height: 30vh;
  }

  .svg-inline--fa.fa-w-14, .thumbnail{
    float: right;
    margin-top: 10px;
    width: 1.3em;
    height: auto;
  }

  .thumbnail{
    margin-right: 2em;
    margin-top: 0.7em;
  }
  .thumbnail{
    display: none;
  }

}

@media only screen  
  and (min-width : 600px)
  and (max-width : 650px)
  and (orientation : landscape){

  .project_container{
    margin : 2em 4.9vw;
  }

  .project_container .selected, .slick-slide, .slick-slider {
    height: 50vw;
    width: 90vw;
  }

  .blogMain{
    margin: 3em 0 0 0;
    width: 100%;
  }
  
  .blogMain > .blog_heading{
    display: block;
    float: right;
    text-align: right;
  }

  .blogMain > .blog_heading > h1{
    font-size: 3rem;
    margin-bottom: 0.6em;
    
  }

  .blogMain > .blog_content{
    margin-top: 3em;
    width: 70%;
  }

  .blog_content > p{
    font-size: 1rem;
  }

  .toolbar{
    width: 100%;
  }

  .thumb_image{
    width: 30vw;
    height: 30vh;
  }

  .svg-inline--fa.fa-w-14, .thumbnail{
    float: right;
    margin-top: 10px;
    width: 1.3em;
    height: auto;
  }

  .thumbnail{
    margin-right: 2em;
    margin-top: 0.7em;
  }
  .thumbnail{
    display: none;
  }

}

@media only screen  
  and (min-width : 650px)
  and (max-width : 700px)
  and (orientation : landscape){

    
  .project_container{
    margin: 2em 5vw;
  }

  .project_container .selected, .slick-slide, .slick-slider {
    height: 50vw;
    width: 90vw;
  }

  .blogMain{
    margin: 3em 0 0 0;
    width: 100%;
  }
  
  .blogMain > .blog_heading{
    display: block;
    float: right;
    text-align: right;
  }

  .blogMain > .blog_heading > h1{
    font-size: 3rem;
    margin-bottom: 0.6em;
    
  }

  .blogMain > .blog_content{
    margin-top: 3em;
    width: 70%;
  }

  .blog_content > p{
    font-size: 1rem;
  }

  .toolbar{
    width: 100%;
  }

  .thumb_image{
    width: 30vw;
    height: 30vh;
  }

  .svg-inline--fa.fa-w-14, .thumbnail{
    float: right;
    margin-top: 10px;
    width: 1.3em;
    height: auto;
  }

  .thumbnail{
    margin-right: 2em;
    margin-top: 0.7em;
  }
  .thumbnail{
    display: none;
  }



}

@media only screen  
  and (min-width : 700px)
  and (max-width : 750px)
  and (orientation : landscape){

    
    .project_container{
      margin: 2em 5vw;
    }
  
    .project_container .selected, .slick-slide, .slick-slider {
      height: 50vw;
      width: 90vw;
    }
  
    .blogMain{
      margin: 3em 0 0 0;
      width: 100%;
    }
    
    .blogMain > .blog_heading{
      display: block;
      float: right;
      text-align: right;
    }
  
    .blogMain > .blog_heading > h1{
      font-size: 3rem;
      margin-bottom: 0.6em;
      
    }
  
    .blogMain > .blog_content{
      margin-top: 3em;
      width: 70%;
    }
  
    .blog_content > p{
      font-size: 1rem;
    }
  
    .toolbar{
      width: 100%;
    }
  
    .thumb_image{
      width: 30vw;
      height: 30vh;
    }
  
    .svg-inline--fa.fa-w-14, .thumbnail{
      float: right;
      margin-top: 10px;
      width: 1.3em;
      height: auto;
    }
  
    .thumbnail{
      margin-right: 2em;
      margin-top: 0.7em;
    }
    .thumbnail{
      display: none;
    }
  
  
  
}

@media only screen  
  and (min-width : 750px)
  and (max-width : 799px)
  and (orientation : landscape){

    
    .project_container{
      margin: 2em 4.9vw;
    }
  
    .project_container .selected, .slick-slide, .slick-slider {
      height: 50vw;
      width: 90vw;
    }
  
    .blogMain{
      margin: 3em 0 0 0;
      width: 100%;
    }
    
    .blogMain > .blog_heading{
      display: block;
      float: right;
      text-align: right;
    }
  
    .blogMain > .blog_heading > h1{
      font-size: 3rem;
      margin-bottom: 0.6em;
      
    }
  
    .blogMain > .blog_content{
      margin-top: 3em;
      width: 70%;
    }
  
    .blog_content > p{
      font-size: 1rem;
    }
  
    .toolbar{
      width: 100%;
    }
  
    .thumb_image{
      width: 30vw;
      height: 30vh;
    }
  
    .svg-inline--fa.fa-w-14, .thumbnail{
      float: right;
      margin-top: 10px;
      width: 1.3em;
      height: auto;
    }
  
    .thumbnail{
      margin-right: 2em;
      margin-top: 0.7em;
    }
    .thumbnail{
      display: none;
    }
  
  
  
}

@media only screen  
  and (min-width : 800px)
  and (max-width : 890px)
  and (orientation : landscape){

    
    .project_container{
      margin: 2em 4.9vw;
    }
  
    .project_container .selected, .slick-slide, .slick-slider {
      height: 50vw;
      width: 90vw;
    }
  
    .blogMain{
      margin: 3em 0 0 0;
      width: 100%;
    }
    
    .blogMain > .blog_heading{
      display: block;
      float: right;
      text-align: right;
    }
  
    .blogMain > .blog_heading > h1{
      font-size: 3rem;
      margin-bottom: 0.6em;
      
    }
  
    .blogMain > .blog_content{
      margin-top: 3em;
      width: 70%;
    }
  
    .blog_content > p{
      font-size: 1rem;
    }
  
    .toolbar{
      width: 100%;
    }
  
    .thumb_image{
      width: 30vw;
      height: 30vh;
    }
  
    .svg-inline--fa.fa-w-14, .thumbnail{
      float: right;
      margin-top: 10px;
      width: 1.3em;
      height: auto;
    }
  
    .thumbnail{
      margin-right: 2em;
      margin-top: 0.7em;
    }
    .thumbnail{
      display: none;
    }
  
  
  
}

@media only screen  
  and (min-width : 890px)
  and (max-width : 900px)
  and (orientation : landscape){

  .project_container{
    margin : 2em 4.9vw;
  }

  .project_container .selected, .slick-slide, .slick-slider {
    height: 50vw;
    width: 90vw;
  }

  .blogMain{
    margin: 3em 0 0 0;
    width: 100%;
  }
  
  .blogMain > .blog_heading{
    display: block;
    float: right;
    text-align: right;
  }

  .blogMain > .blog_heading > h1{
    font-size: 3rem;
    margin-bottom: 0.6em;
    
  }

  .blogMain > .blog_content{
    margin-top: 3em;
    width: 70%;
  }

  .blog_content > p{
    font-size: 1rem;
  }

  .toolbar{
    width: 100%;
  }

  .thumb_image{
    width: 30vw;
    height: 30vh;
  }

  .svg-inline--fa.fa-w-14, .thumbnail{
    float: right;
    margin-top: 10px;
    width: 1.3em;
    height: auto;
  }

  .thumbnail{
    margin-right: 2em;
    margin-top: 0.7em;
  }
  .thumbnail{
    display: none;
  }

}







/* Desktops and laptops ----------- */
@media only screen  
  and (min-width : 720px) 
  and (min-height: 600px){

  .project_container{
    margin: 2em 5.3vw;
  }

  .project_container .selected, .slick-slide, .slick-slider {
    height: 50vw;
    width: 95.6vw;
  }
  
  .blogMain{
    margin: 0;
    margin-top: 3em;
    width: 96vw;
  }
  
  .blogMain > .blog_heading{
    display: block;
    float: right;
    text-align: right;
  }

  .blogMain > .blog_heading > h1{
    font-size: 3rem;
    margin-bottom: 0.6em;
    
  }

  .blogMain > .blog_content{
    margin-top: 3em;
    width: 55%;
  }

  .blog_content > p{
    font-size: 1rem;
  }
  .toolbar{
    width: 95.6vw;
  }

  .thumb_image{
    width: 22.5vw;
    height: 27vh;
  }

  .svg-inline--fa.fa-w-14, .thumbnail{
    float: right;
    margin-top: 10px;
    width: 1.3em;
    height: auto;
  }

  .thumbnail{
    display: block !important;
    margin-right: 2em;
    margin-top: 0.7em;
  }
}


@media only screen  
  and (min-width : 900px){

  .project_container{
    margin: 2em 5vw;
  }

  .project_container .selected, .slick-slide, .slick-slider {
    height: 50vw;
    width: 95.6vw;
  }
  
  .blogMain{
    margin: 0;
    margin-top: 3em;
    width: 96vw;
  }
  
  .blogMain > .blog_heading{
    display: block;
    float: right;
    text-align: right;
  }

  .blogMain > .blog_heading > h1{
    font-size: 3rem;
    margin-bottom: 0.6em;
    
  }

  .blogMain > .blog_content{
    margin-top: 3em;
    width: 55%;
  }

  .blog_content > p{
    font-size: 1rem;
  }
  .toolbar{
    width: 95.6vw;
  }

  .thumb_image{
    width: 22.5vw;
    height: 27vh;
  }

  .svg-inline--fa.fa-w-14, .thumbnail{
    float: right;
    margin-top: 10px;
    width: 1.3em;
    height: auto;
  }

  .thumbnail{
    display: block !important;
    margin-right: 2em;
    margin-top: 0.7em;
  }
}

@media only screen and (min-width: 1000px) {

  .project_container{
    margin : 2em 3.3vw;
  }

  .project_container .selected, .slick-slide, .slick-slider {
    height: 50vw;
    width: 95.6vw;
  }
  
  .blogMain{
    margin: 0;
    margin-top: 3em;
    width: 96vw;
  }
  
  .blogMain > .blog_heading{
    display: block;
    float: right;
    text-align: right;
  }

  .blogMain > .blog_heading > h1{
    font-size: 3rem;
    margin-bottom: 0.6em;
    
  }

  .blogMain > .blog_content{
    margin-top: 3em;
    width: 55%;
  }

  .blog_content > p{
    font-size: 1rem;
  }
  .toolbar{
    width: 95.6vw;
  }

  .thumb_image{
    width: 22.5vw;
    height: 27vh;
  }

  .svg-inline--fa.fa-w-14, .thumbnail{
    float: right;
    margin-top: 10px;
    width: 1.3em;
    height: auto;
  }

  .thumbnail{
    display: block !important;
    margin-right: 2em;
    margin-top: 0.7em;
  }
}

@media only screen and (min-width : 1110px) {

  .project_container{
    margin : 2em 3.3vw;
  }

  .project_container .selected, .slick-slide, .slick-slider {
    height: 50vw;
    width: 95.6vw;
  }
  
  .blogMain{
    margin: 0;
    margin-top: 3em;
    width: 96vw;
  }
  
  .blogMain > .blog_heading{
    display: block;
    float: right;
    text-align: right;
  }

  .blogMain > .blog_heading > h1{
    font-size: 3rem;
    margin-bottom: 0.6em;
    
  }

  .blogMain > .blog_content{
    margin-top: 3em;
    width: 55%;
  }

  .blog_content > p{
    font-size: 1rem;
  }
  .toolbar{
    width: 95.6vw;
  }

  .thumb_image{
    width: 22.5vw;
    height: 27vh;
  }

  .svg-inline--fa.fa-w-14, .thumbnail{
    float: right;
    margin-top: 10px;
    width: 1.3em;
    height: auto;
  }

  .thumbnail{
    display: block !important;
    margin-right: 2em;
    margin-top: 0.7em;
  }
}

@media only screen and (min-width: 1024px) {

  .project_container{
    margin : 2em 3.3vw;
  }

  .project_container .selected, .slick-slide, .slick-slider {
    height: 50vw;
  }
  
  .blogMain{
    margin: 0;
    margin-top: 3em;
    width: 96vw;
  }
  
  .blogMain > .blog_heading{
    display: block;
    float: right;
    text-align: right;
  }

  .blogMain > .blog_heading > h1{
    font-size: 3rem;
    margin-bottom: 0.6em;
    
  }

  .blogMain > .blog_content{
    margin-top: 3em;
    width: 55%;
  }

  .blog_content > p{
    font-size: 1rem;
  }
  .toolbar{
    width: 95.6vw;
  }

  .thumb_image{
    width: 22.5vw;
    height: 27vh;
  }

  .svg-inline--fa.fa-w-14, .thumbnail{
    float: right;
    margin-top: 10px;
    width: 1.3em;
    height: auto;
  }

  .thumbnail{
    display: block !important;
    margin-right: 2em;
    margin-top: 0.7em;
  }
}


/*LCD Displays*/
@media only screen 
  and (min-width: 1024px) 
  and (max-width: 1080px)  
  and (min-height : 800px) 
  and (max-height : 900px) {

    .project_container{
      margin : 2em 3.3vw;
    }


    .top_section{
      display: block;
    }
    
    .project_container .selected, .slick-slide, .slick-slider {
      height: 50vw;
    }
  
    .blogMain{
      margin: 0;
      margin-top: 5em;
      width: 94.5vw;
    }
   
    .blogMain > .blog_heading{
      display: block;
      float: right;
      text-align: right;
    }
  
    .blogMain > .blog_heading > h1{
      font-size: 3rem;
      margin-bottom: 0.6em;
      
    }
  
    .blogMain > .blog_content{
      margin-top: 3em;
      width: 55%;
    }
  
    .blog_content > p{
      font-size: 1rem;
    }
  
    .toolbar{
      width: 94.5vw;
    }
  
    .thumb_image{
      width: 22vw;
      height: 30.5vh;
    }
  
    .svg-inline--fa.fa-w-14, .thumbnail{
      float: right;
      margin-top: 10px;
      width: 1.3em;
      height: auto;
    }
  
    .thumbnail{
      display: block !important;
      margin-right: 2em;
      margin-top: 0.7em;
    }
} 



@media only screen  and (min-width : 1280px) {

  .project_container{
    margin : 2em 3.3vw;
  }

  .project_container .selected, .slick-slide, .slick-slider {
    height: 50vw;
  }
  
  .blogMain{
    margin: 0;
    margin-top: 3em;
    width: 96vw;
  }
  
  .blogMain > .blog_heading{
    display: block;
    float: right;
    text-align: right;
  }

  .blogMain > .blog_heading > h1{
    font-size: 3rem;
    margin-bottom: 0.6em;
    
  }

  .blogMain > .blog_content{
    margin-top: 3em;
    width: 55%;
  }

  .blog_content > p{
    font-size: 1rem;
  }
  .toolbar{
    width: 95.6vw;
  }

  .thumb_image{
    width: 22.5vw;
    height: 27vh;
  }

  .svg-inline--fa.fa-w-14, .thumbnail{
    float: right;
    margin-top: 10px;
    width: 1.3em;
    height: auto;
  }

  .thumbnail{
    display: block !important;
    margin-right: 2em;
    margin-top: 0.7em;
  }
}


@media only screen  and (min-width : 1300px) {
  
  .project_container .selected, .slick-slide, .slick-slider {
    height: 50vw;
  }

}
/* Basic Laptop Screens */
@media only screen and (min-width : 1440px) {

  .project_container{
    margin : 2em 3.3vw;
  }
  
  .project_container .selected, .slick-slide, .slick-slider {
    height: 51vw;
  }
  
  .blogMain{
    margin: 0;
    margin-top: 3em;
    width: 96vw;
  }
 
  .blogMain > .blog_heading{
    display: block;
    float: right;
    text-align: right;
  }

  .blogMain > .blog_heading > h1{
    font-size: 3rem;
    margin-bottom: 0.6em;
    
  }

  .blogMain > .blog_content{
    margin-top: 3em;
    width: 50%;
  }

  .blog_content > p{
    font-size: 1rem;
  }
  .toolbar{
    width: 95.6vw;
  }

  .thumb_image{
    width: 22.5vw;
    height: 30.5vh;
  }

  .svg-inline--fa.fa-w-14, .thumbnail{
    float: right;
    margin-top: 10px;
    width: 1.3em;
    height: auto;
  }

  .thumbnail{
    display: block !important;
    margin-right: 2em;
    margin-top: 0.7em;
  }
}

/* Larger laptop screens */
@media only screen  and (min-width : 1500px) {

  .project_container{
    margin : 3em 3.3vw;
  }


  .top_section{
    display: block;
  }

  .project_container .selected, .slick-slide, .slick-slider {
    height: 50vw;
  }

  .blogMain{
    margin: 0;
    margin-top: 5em;
    width: 100%;
  }
 
  .blogMain > .blog_heading{
    display: block;
    float: right;
    text-align: right;
  }

  .blogMain > .blog_heading > h1{
    font-size: 3rem;
    margin-bottom: 0.6em;
    
  }

  .blogMain > .blog_content{
    margin-top: 3em;
    width: 40%;
  }

  .blog_content > p{
    font-size: 1rem;
  }


  .thumb_image{
    width: 23vw;
    height: 30.5vh;
  }

  .svg-inline--fa.fa-w-14, .thumbnail{
    float: right;
    margin-top: 10px;
    width: 1.3em;
    height: auto;
  }

  .thumbnail{
    display: block !important;
    margin-right: 2em;
    margin-top: 0.7em;
  }

}



/* Large screens ----------- */
@media only screen  and (min-width : 1600px) {

  .project_container{
    margin : 2em 3.3vw;
  }


  .project_container .selected, .slick-slide, .slick-slider {
    height: 52vw;
  }

  .blogMain{
    margin: 0;
    margin-top: 5em;
    width: 96vw;
  }
 
  .blogMain > .blog_heading{
    display: block;
    float: right;
    text-align: right;
  }

  .blogMain > .blog_heading > h1{
    font-size: 3rem;
    margin-bottom: 0.6em;
    
  }

  .blogMain > .blog_content{
    margin-top: 3em;
    width: 40%;
  }

  .blog_content > p{
    font-size: 1rem;
  }

  .toolbar{
    width: 96.5vw;
  }

  .thumb_image{
    width: 23vw;
    height: 30.5vh;
  }

  .svg-inline--fa.fa-w-14, .thumbnail{
    float: right;
    margin-top: 10px;
    width: 1.3em;
    height: auto;
  }

  .thumbnail{
    display: block !important;
    margin-right: 2em;
    margin-top: 0.7em;
  }

}


@media only screen  and (min-width : 1700px) {
  .project_container{
    margin : 2em 3.3vw;
  }


  .project_container .selected, .slick-slide, .slick-slider {
    height: 52vw;
  }

  .blogMain{
    margin: 0;
    margin-top: 5em;
    width: 96vw;
  }
 
  .blogMain > .blog_heading{
    display: block;
    float: right;
    text-align: right;
  }

  .blogMain > .blog_heading > h1{
    font-size: 3rem;
    margin-bottom: 0.6em;
    
  }

  .blogMain > .blog_content{
    margin-top: 3em;
    width: 40%;
  }

  .blog_content > p{
    font-size: 1rem;
  }

  .toolbar{
    width: 96.5vw;
  }

  .thumb_image{
    width: 23vw;
    height: 30.5vh;
  }

  .svg-inline--fa.fa-w-14, .thumbnail{
    float: right;
    margin-top: 10px;
    width: 1.3em;
    height: auto;
  }

  .thumbnail{
    display: block !important;
    margin-right: 2em;
    margin-top: 0.7em;
  }
}

@media only screen  and (min-width : 1900px) {
  .project_container{
    margin : 2em 3.3vw;
  }


  .project_container .selected, .slick-slide, .slick-slider {
    height: 52vw;
  }

  .blogMain{
    margin: 0;
    margin-top: 5em;
    width: 96vw;
  }
 
  .blogMain > .blog_heading{
    display: block;
    float: right;
    text-align: right;
  }

  .blogMain > .blog_heading > h1{
    font-size: 3rem;
    margin-bottom: 0.6em;
    
  }

  .blogMain > .blog_content{
    margin-top: 3em;
    width: 40%;
  }

  .blog_content > p{
    font-size: 1rem;
  }

  .toolbar{
    width: 96.5vw;
  }

  .thumb_image{
    width: 23vw;
    height: 30.5vh;
  }

  .svg-inline--fa.fa-w-14, .thumbnail{
    float: right;
    margin-top: 10px;
    width: 1.3em;
    height: auto;
  }

  .thumbnail{
    display: block !important;
    margin-right: 2em;
    margin-top: 0.7em;
  }
}

@media only screen  and (min-width : 2000px) {
  .project_container{
    margin : 2em 3.3vw;
  }


  .project_container .selected, .slick-slide, .slick-slider {
    height: 52vw;
  }

  .blogMain{
    margin: 0;
    margin-top: 5em;
    width: 96vw;
  }
 
  .blogMain > .blog_heading{
    display: block;
    float: right;
    text-align: right;
  }

  .blogMain > .blog_heading > h1{
    font-size: 3rem;
    margin-bottom: 0.6em;
    
  }

  .blogMain > .blog_content{
    margin-top: 3em;
    width: 40%;
  }

  .blog_content > p{
    font-size: 1rem;
  }

  .toolbar{
    width: 96.5vw;
  }

  .thumb_image{
    width: 23vw;
    height: 30.5vh;
  }

  .svg-inline--fa.fa-w-14, .thumbnail{
    float: right;
    margin-top: 10px;
    width: 1.3em;
    height: auto;
  }

  .thumbnail{
    display: block !important;
    margin-right: 2em;
    margin-top: 0.7em;
  }
}

/* Macbook Pro */
@media only screen 
  and (min-width: 1700px) 
  and (max-width: 1800px)  
  and (min-height : 1080px) 
  and (max-height : 1100px) {

    .project_container{
      margin : 2em 3.3vw;
    }


    .project_container .selected, .slick-slide, .slick-slider {
      height: 52vw;
    }

    .blogMain{
      margin: 0;
      margin-top: 5em;
      width: 96vw;
    }
   
    .blogMain > .blog_heading{
      display: block;
      float: right;
      text-align: right;
    }
  
    .blogMain > .blog_heading > h1{
      font-size: 3rem;
      margin-bottom: 0.6em;
      
    }
  
    .blogMain > .blog_content{
      margin-top: 3em;
      width: 40%;
    }
  
    .blog_content > p{
      font-size: 1rem;
    }
  
    .toolbar{
      width: 96.5vw;
    }
  
    .thumb_image{
      width: 23vw;
      height: 30.5vh;
    }
  
    .svg-inline--fa.fa-w-14, .thumbnail{
      float: right;
      margin-top: 10px;
      width: 1.3em;
      height: auto;
    }
  
    .thumbnail{
      display: block !important;
      margin-right: 2em;
      margin-top: 0.7em;
    }
}

/* Potrait Orientation for Tablets and Mobiles */


/* Tablets and IPads */
@media only screen 
  and (min-width: 600px)
  and (max-width: 720px)
  and (orientation : portrait){
  
  .project_container{
    margin: 2em 5.3vw;
  }

  
  .top_section{
    display: flex;
    flex-direction: column;
  }

  .project_container .selected, .slick-slide, .slick-slider{
    height: 45vw;
    width: 94vw;
    margin-bottom: 1em;
  }

  .blogMain{
    margin-top: 2em;
    margin-right: -2em;
  }

  .toolbar{
    width: 94vw;
  }

  .blogMain > .blog_content{
    width: 80%;
    font-size: 0.9rem;
  }
  .thumb_image{
    width: 30vw;
    height: 30vh;
  }

  .svg-inline--fa.fa-w-14, .thumbnail{
    float: right;
    margin-top: 10px;
    width: 1.3em;
    height: auto;
  }

  .thumbnail{
    margin-right: 2em;
    margin-top: 0.7em;
  }
  
}

@media only screen 
  and (min-width: 720px) 
  and (max-width: 769px) 
  and (orientation : portrait)
  and (-webkit-device-pixel-ratio: 1){

  .project_container{
    margin: 2em 5.3vw;
  }

  
  .top_section{
    display: flex;
    flex-direction: column;
  }

  .project_container .selected, .slick-slide, .slick-slider{
    height: 45vw;
    width: 94vw;
    margin-bottom: 1em;
  }

  .blogMain{
    margin-top: 2em;
    margin-right: -2em;
  }

  .toolbar{
    width: 94vw;
  }

  .blogMain > .blog_content{
    width: 80%;
    font-size: 0.9rem;
  }
  .thumb_image{
    width: 30vw;
    height: 30vh;
  }

  .svg-inline--fa.fa-w-14, .thumbnail{
    float: right;
    margin-top: 10px;
    width: 1.3em;
    height: auto;
  }

  .thumbnail{
    margin-right: 2em;
    margin-top: 0.7em;
  }
}


@media only screen 
  and (min-width: 769px) 
  and (max-width: 850px)
  and (max-height : 1300px)
  and (orientation : portrait){

  .project_container{
    margin: 2em 5.3vw;
  }

  
  .top_section{
    display: flex;
    flex-direction: column;
  }

  .project_container .selected, .slick-slide, .slick-slider{
    height: 45vw;
    width: 94vw;
    margin-bottom: 1em;
  }

  .blogMain{
    margin-top: 2em;
    margin-right: -2em;
  }

  .toolbar{
    width: 94vw;
  }

  .blogMain > .blog_content{
    width: 80%;
    font-size: 0.9rem;
  }
  .thumb_image{
    width: 30vw;
    height: 30vh;
  }

  .svg-inline--fa.fa-w-14, .thumbnail{
    float: right;
    margin-top: 10px;
    width: 1.3em;
    height: auto;
  }

  .thumbnail{
    margin-right: 2em;
    margin-top: 0.7em;
  }
}

@media only screen 
  and (min-width: 850px) 
  and (max-width: 899px) 
  and (orientation : portrait){

  .project_container{
    margin: 2em 5.3vw;
  }

  
  .top_section{
    display: flex;
    flex-direction: column;
  }

  .project_container .selected, .slick-slide, .slick-slider{
    height: 45vw;
    width: 94vw;
    margin-bottom: 1em;
  }

  .blogMain{
    margin-top: 2em;
    margin-right: -2em;
  }

  .toolbar{
    width: 94vw;
  }

  .blogMain > .blog_content{
    width: 80%;
    font-size: 0.9rem;
  }
  .thumb_image{
    width: 30vw;
    height: 30vh;
  }

  .svg-inline--fa.fa-w-14, .thumbnail{
    float: right;
    margin-top: 10px;
    width: 1.3em;
    height: auto;
  }

  .thumbnail{
    margin-right: 2em;
    margin-top: 0.7em;
  }

}


@media only screen 
  and (min-width: 900px) 
  and (max-width: 959px) 
  and (orientation : portrait){

    .project_container{
      margin: 2em 5.3vw;
    }
  
    
    .top_section{
      display: flex;
      flex-direction: column;
    }
  
    .project_container .selected, .slick-slide, .slick-slider{
      height: 45vw;
      width: 94vw;
      margin-bottom: 1em;
    }
  
    .blogMain{
      margin-top: 2em;
      margin-right: -2em;
    }
  
    .toolbar{
      width: 94vw;
    }
  
    .blogMain > .blog_content{
      width: 80%;
      font-size: 0.9rem;
    }
    .thumb_image{
      width: 30vw;
      height: 30vh;
    }
  
    .svg-inline--fa.fa-w-14, .thumbnail{
      float: right;
      margin-top: 10px;
      width: 1.3em;
      height: auto;
    }
  
    .thumbnail{
      margin-right: 2em;
      margin-top: 0.7em;
    }

}


/*Ipad Potrait*/
@media only screen 
and (min-width : 768px) 
and (max-width : 1024px)
and (max-height: 1024px) 
and (orientation : portrait) {
  .fullscreen_view .slick-slide{
    -webkit-transform: translateY(4%);
            transform: translateY(4%);
  }

  .project_container{
    margin: 2em 5.3vw;
  }

  .blogMain > .blog_content{
    width: 70%;
  }

  .toolbar{
    width: 94vw;
  }
 
  .thumb_image{
    width: 30vw;
    height: 30vh;
  }

  .svg-inline--fa.fa-w-14, .thumbnail{
    float: right;
    margin-top: 10px;
    width: 1.3em;
    height: auto;
  }

  .thumbnail{
    margin-right: 2em;
    margin-top: 0.7em;
  }

}

/* IPad Pro 10.5 Portrait */
@media only screen 
  and (min-width: 834px)
  and (max-width: 834px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1){
  .fullscreen_view .slick-slide{
    -webkit-transform: translateY(4%);
            transform: translateY(4%);
  }

  .project_container{
    margin: 2em 5.3vw;
  }

  
  
  .top_section{
    display: flex;
    flex-direction: column;
  }

  .project_container .selected, .slick-slide, .slick-slider{
    height: 45vw;
    width: 94vw;
    margin-bottom: 1em;
  }

  .blogMain{
    margin-top: 2em;
    margin-right: -5.9em;
  }

  .toolbar{
    width: 94vw;
  }

  .blogMain > .blog_content{
    width: 70%;
    font-size: 0.9rem;
  }
  .thumb_image{
    width: 30vw;
    height: 30vh;
  }

  .svg-inline--fa.fa-w-14, .thumbnail{
    float: right;
    margin-top: 10px;
    width: 1.3em;
    height: auto;
  }

  .thumbnail{
    margin-right: 2em;
    margin-top: 0.7em;
  }
}

/* IPad Pro 12.5 Portrait */
@media only screen
and (min-width: 1024px)
and (max-width: 1024px)
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 1){

  .fullscreen_view .slick-slide{
    -webkit-transform: translateY(4%);
            transform: translateY(4%);
  }

  .fullscreen_view  .slick-slide{
    /* height: 50% !important; */
    /* width: fit-content !important; */
    object-position: center;
    object-fit: cover !important;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  .project_container{
    margin: 2em 5.3vw;
  }

  .top_section{
    display: flex;
    flex-direction: column;
  }

  .project_container .selected, .slick-slide, .slick-slider{
    height: 45vw;
    width: 94vw;
  }

  .blogMain{
    margin-top: 2em;
    margin-right: -2em;
  }

  .toolbar{
    width: 94vw;
  }

  .blogMain > .blog_content{
    width: 70%;
    font-size: 0.9rem;
  }
  .thumb_image{
    width: 30vw;
    height: 30vh;
  }

  .svg-inline--fa.fa-w-14, .thumbnail{
    float: right;
    margin-top: 10px;
    width: 1.3em;
    height: auto;
  }

  .thumbnail{
    margin-right: 2em;
    margin-top: 0.7em;
  }
}







/* Landscape Orientation for Tablets and Mobiles */


/* Tablets and IPads */

@media only screen 
  and (min-width: 960px) 
  and (max-width: 1023px) 
  and (orientation : landscape){

  .project_container{
    margin: 2em 3.3vw;
  }
  
  .project_container .selected, .slick-slide, .slick-slider {
    height: 52vw;
    width: 94vw;
  }

  .blogMain{
    margin: 0;
    margin-top: 3em;
    margin-left: -1em;
    width: 96vw;
  }
  
  .blogMain > .blog_heading{
    display: block;
    float: right;
    text-align: right;
  }

  .blogMain > .blog_heading > h1{
    font-size: 3rem;
    margin-bottom: 0.6em;
    
  }

  .blogMain > .blog_content{
    margin-top: 3em;
    width: 70%;
  }

  .blog_content > p{
    font-size: 1rem;
  }

  .toolbar{
    width: 94vw;
  }

  .thumb_image{
    width: 30vw;
    height: 30vh;
  }

  .svg-inline--fa.fa-w-14, .thumbnail{
    float: right;
    margin-top: 10px;
    width: 1.3em;
    height: auto;
  }

  .thumbnail{
    display: block !important;
    margin-right: 2em;
    margin-top: 0.7em;
  }
}



@media only screen 
  and (min-width: 1023px) 
  and (max-width: 1179px) 
  and (orientation : landscape){

  .project_container{
    margin: 2em 3.3vw;
  }
  
  .project_container .selected, .slick-slide, .slick-slider {
    height: 50vw;
    width: 95.5vw;
  }

  .blogMain{
    margin: 0;
    margin-top: 3em;
    width: 96vw;
  }
  
  .blogMain > .blog_heading{
    display: block;
    float: right;
    text-align: right;
  }

  .blogMain > .blog_heading > h1{
    font-size: 3rem;
    margin-bottom: 0.6em;
    
  }

  .blogMain > .blog_content{
    margin-top: 3em;
    width: 70%;
  }

  .blog_content > p{
    font-size: 1rem;
  }

  .toolbar{
    width: 95.5vw;
  }

  .thumb_image{
    width: 30vw;
    height: 30vh;
  }

  .svg-inline--fa.fa-w-14, .thumbnail{
    float: right;
    margin-top: 10px;
    width: 1.3em;
    height: auto;
  }

  .thumbnail{
    display: block !important;
    margin-right: 2em;
    margin-top: 0.7em;
  }
}

@media only screen
  and (min-width: 1180px)
  and (max-width: 1194px)
  and (orientation: landscape){

  .project_container{
    margin: 2em 3.3vw;
  }

}

@media only screen 
  and (min-width : 1280px) 
  and (min-height : 740px) 
  and (max-height : 750px) {

    .project_container{
      margin: 2em 3.3vw;
    }

}




/*Ipad Landscape*/
@media only screen 
and (min-height : 768px)
and (max-width : 1024px)
and (orientation : landscape)
and (-webkit-min-device-pixel-ratio: 1){

  .fullscreen_view .slick-slide{
    -webkit-transform: translateY(10%);
            transform: translateY(10%);
  }

  .fullscreen_view .slick-slide{
    width: auto !important;
    height: 100% !important;
  }

  .project_container{
    margin: 2em 3.3vw;
  }

  .project_container .selected, .slick-slide, .slick-slider {
    height: 50vw;
    width: 95vw;
  }

  .blogMain{
    margin: 0;
    margin-top: 3em;
    width: 96vw;
  }
 
  .blogMain > .blog_heading{
    display: block;
    float: right;
    text-align: right;
  }

  .blogMain > .blog_heading > h1{
    font-size: 3rem;
    margin-bottom: 0.6em;
    
  }

  .blogMain > .blog_content{
    margin-top: 3em;
    width: 70%;
  }

  .blog_content > p{
    font-size: 1rem;
  }

  .toolbar{
    width: 95.5vw;
  }

  .thumb_image{
    width: 30vw;
    height: 30vh;
  }

  .svg-inline--fa.fa-w-14, .thumbnail{
    float: right;
    margin-top: 10px;
    width: 1.3em;
    height: auto;
  }

  .thumbnail{
    display: none;
  }

}

/* IPad Pro 10.5 Landscape */
@media only screen 
  and (min-height: 834px)
  and (max-width: 1112px) 
  and (orientation: landscape){

    .fullscreen_view .slick-slide{
      -webkit-transform: translateY(10%);
              transform: translateY(10%);
    }

    .project_container{
      margin: 2em 3.3vw;
    }

    .project_container .selected, .slick-slide, .slick-slider {
      height: 50vw;
      width: 95vw;
    }
  
    .blogMain{
      margin: 0;
      margin-top: 3em;
      margin-left: -0.5em;
      width: 96vw;
    }
   
    .blogMain > .blog_heading{
      display: block;
      float: right;
      text-align: right;
    }
  
    .blogMain > .blog_heading > h1{
      font-size: 3rem;
      margin-bottom: 0.6em;
      
    }
  
    .blogMain > .blog_content{
      margin-top: 3em;
      width: 55%;
    }
  
    .blog_content > p{
      font-size: 1rem;
    }
  
    .toolbar{
      width: 95vw;
    }
  
    .thumb_image{
      width: 30vw;
      height: 30vh;
    }
  
    .svg-inline--fa.fa-w-14, .thumbnail{
      float: right;
      margin-top: 10px;
      width: 1.3em;
      height: auto;
    }
  
    .thumbnail{
      display: block !important;
      margin-right: 2em;
      margin-top: 0.7em;
    }
}

/* IPad Pro 12.5 Landscape */
@media only screen
  and (min-width: 1024px)
  and (max-width: 1194px)
  and (orientation: landscape){

    .fullscreen_view .slick-slide{
      -webkit-transform: translateY(15%);
              transform: translateY(15%);
    }

    .project_container{
      margin: 2em 3.3vw;  
    }

    .project_container .selected, .slick-slide, .slick-slider {
      height: 50vw;
      width: 95.5vw;
    }
  
    .blogMain{
      margin: 0;
      margin-top: 3em;
      margin-left: -0.5em;
      width: 96vw;
    }
   
    .blogMain > .blog_heading{
      display: block;
      float: right;
      text-align: right;
    }
  
    .blogMain > .blog_heading > h1{
      font-size: 3rem;
      margin-bottom: 0.6em;
      
    }
  
    .blogMain > .blog_content{
      margin-top: 3em;
      width: 55%;
    }
  
    .blog_content > p{
      font-size: 1rem;
    }
  
    .toolbar{
      width: 95.5vw;
    }
  
    .thumb_image{
      width: 30vw;
      height: 30vh;
    }
  
    .svg-inline--fa.fa-w-14, .thumbnail{
      float: right;
      margin-top: 10px;
      width: 1.3em;
      height: auto;
    }
  
    .thumbnail{
      display: block;
      margin-right: 2em;
      margin-top: 0.7em;
    }
}


@media only screen
  and (device-width: 2732px)
  and (device-height: 2048px)
  and (orientation: landscape){
    .fullscreen_view  .slick-track{
      margin-top: 13vh;
    }

    .fullscreen_view  .slick-slide{
      /* height: 50% !important; */
      /* width: fit-content !important; */
      object-position: center;
      object-fit: cover !important;
      /* transform: translateY(150%); */
      /* margin-top: 13vh; */
    }
}
.gallery{
    position:fixed;
    top:0;
    left:0;
    bottom:0;
    right:0;
    height:100%;
    width:100%;
    background: rgba(255,255,255,.97);
    z-index: 1000;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.gallery_container{
    overflow-y: hidden;
    overflow-x: scroll;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(auto-fill,30vw);
    margin: 3em 3em;
}

.thumb_image{
    box-shadow: none !important;
}


@media only screen 
    and (min-width: 900px){

        .gallery_container{
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(auto-fill,27.5vw);
            grid-column-gap: 15px;
            -webkit-column-gap: 15px;
                    column-gap: 15px;
            margin-top: 3em;
            margin-left: 1.4em;
            margin-right: 1.4em;
        }

        .thumb_image{
            width: 45vw;
            height: 25vw;
            box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
        }
}

@media only screen 
    and (max-width: 790px){
        .gallery_container{
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(auto-fill,25vw);
            grid-column-gap: 15px;
            grid-column-gap: 15px;
            -webkit-column-gap: 15px;
                    column-gap: 15px;
            grid-row-gap: 15px;
            grid-row-gap: 20px;
            row-gap: 20px;
            margin-top: 3em;
            margin-left: 1.4em;
            margin-right: 1.4em;
        }
    
        .thumb_image{
            width: 45vw;
            height: 25vw;
            box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
        }
}


@media only screen 
    and (min-width: 790px){

    .gallery_container{
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(auto-fill,25.5vw);
        grid-column-gap: 15px;
        -webkit-column-gap: 15px;
                column-gap: 15px;
        grid-row-gap: 15px;
        row-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
    }

    .thumb_image{
        width: 45vw;
        height: 25vw;
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

}


/* Smartphone portrait */

@media only screen 
  and (min-width: 600px)
  and (max-width : 720px)
  and (orientation : portrait){
    .gallery{
        display: none;
    }
}

@media only screen
  and (min-width : 360px) 
  and (max-width : 428px)
  and (orientation : portrait){
    .gallery{
        display: none;
    }
}

@media only screen 
  and (min-width : 240px)
  and (max-width : 320px)
  and (orientation : portrait){

    .gallery{
        display: none;
    }
}


/* Smartphone Landscape */

@media only screen  
  and (min-width : 320px)
  and (max-width : 460px)
  and (orientation : landscape){
    .gallery{
        display: none;
    }
}

@media only screen  
  and (min-width : 500px)
  and (max-width : 850px)
  and (orientation : landscape){
  
    .gallery{
        /* display: none; */
    }
}


@media only screen  
  and (min-width : 896px)
  and (max-width : 900px)
  and (orientation : landscape){
    .gallery{
        display: none;
    }
}

@media only screen 
and (max-width: 768px)
and (orientation : portrait) {
    .gallery_container{
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(auto-fill,25vw);
        grid-row-gap: 100px;
        row-gap: 100px;
        grid-column-gap: 15px;
        -webkit-column-gap: 15px;
                column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        grid-row-gap: 15px;
        row-gap: 15px;
        margin-right: 1.4em;
    }
    .thumb_image{
        width: 45vw;
        height: 25vw;
    }
}

@media only screen 
  and (min-width: 768px) 
  and (max-width: 880px) 
  and (orientation : portrait){
    .gallery_container{
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(auto-fill,31vw);
        grid-row-gap: 100px;
        row-gap: 100px;
        grid-column-gap: 15px;
        -webkit-column-gap: 15px;
                column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        grid-row-gap: 15px;
        row-gap: 15px;
        margin-right: 1.4em;
    }

    .thumb_image{
        width: 45vw;
        height: 20vh;
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }
}

@media only screen 
  and (min-width: 768px) 
  and (min-height: 1280px) 
  and (orientation : portrait){
    .gallery_container{
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(auto-fill,32vw);
        grid-row-gap: 100px;
        row-gap: 100px;
        grid-column-gap: 15px;
        -webkit-column-gap: 15px;
                column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
        grid-row-gap: 15px;
        row-gap: 15px;
    }

    .thumb_image{
        width: 45vw;
        height: 20vh;
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }
}

/*Ipad Potrait*/
@media only screen 
and (min-width : 768px) 
and (max-width : 1024px)
and (max-height: 1024px) 
and (orientation : portrait) {
    .gallery_container{
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(auto-fill,25vw);
        grid-row-gap: 100px;
        row-gap: 100px;
        grid-column-gap: 15px;
        -webkit-column-gap: 15px;
                column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
        grid-row-gap: 15px;
        row-gap: 15px;
    }

    .thumb_image{
        width: 45vw;
        height: 25vw;
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }
}

/* IPad Pro 10.5 Portrait */
@media only screen 
  and (min-width: 834px)
  and (max-width: 834px) 
  and (orientation: portrait) {
    .gallery_container{
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(auto-fill,28vw);
        grid-column-gap: 15px;
        -webkit-column-gap: 15px;
                column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
        grid-row-gap: 15px;
        row-gap: 15px;
    }

    .thumb_image{
        width: 45vw;
        height: 20vh;
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }
}

/* IPad Pro 12.5 Portrait */
@media only screen
and (min-width: 1024px)
and (max-width: 1024px)
and (orientation: portrait){
    .gallery_container{
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(auto-fill,26.5vw);
        grid-column-gap: 15px;
        -webkit-column-gap: 15px;
                column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
    }

    .thumb_image{
        width: 45vw;
        height: 20vh;
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }
}

@media only screen 
  and (min-width: 1024px) 
  and (max-width: 1080px)  
  and (min-height : 800px) 
  and (max-height : 900px) {

    .gallery_container{
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(auto-fill,26.5vw);
        grid-column-gap: 15px;
        -webkit-column-gap: 15px;
                column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
        grid-row-gap: 15px;
        row-gap: 15px;
    }

    .thumb_image{
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }
}

@media only screen  and (min-width : 1280px) {

    .gallery_container{
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(auto-fill,21vw);
        grid-column-gap: 15px;
        -webkit-column-gap: 15px;
                column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
        grid-row-gap: 15px;
        row-gap: 15px;
    }

    .thumb_image{
        width: 30vw;
        height: 20vw;
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }
}

@media only screen 
  and (min-width : 1280px) 
  and (min-height : 740px) 
  and (max-height : 800px) {

    .gallery_container{
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(auto-fill,20.5vw);
        grid-column-gap: 15px;
        -webkit-column-gap: 15px;
                column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
        grid-row-gap: 15px;
        row-gap: 15px;
    }

    .thumb_image{
        width: 30vw;
        height: 20vw;
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }
}

/* Basic Laptop Screens */
@media only screen and (min-width : 1440px) {

    .gallery_container{
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(auto-fill,20.5vw);
        grid-column-gap: 15px;
        -webkit-column-gap: 15px;
                column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
        grid-row-gap: 15px;
        row-gap: 15px;
    }

    .thumb_image{
        width: 30vw;
        height: 20vw;
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }
}

@media only screen and (min-width: 1500px){

    .gallery_container{
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(auto-fill,16.5vw);
        grid-column-gap: 15px;
        -webkit-column-gap: 15px;
                column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
        grid-row-gap: 15px;
        row-gap: 15px;
    }

    .thumb_image{
        width: 23vw;
        height: 16vw;
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }
}

/* Macbook Pro */
@media only screen 
  and (min-width: 1700px) 
  and (max-width: 1800px)  
  and (min-height : 1080px) 
  and (max-height : 1100px) {
   .gallery_container{
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(auto-fill,16.5vw);
        grid-row-gap: 85px;
        row-gap: 85px;
        grid-column-gap: 10px;
        -webkit-column-gap: 10px;
                column-gap: 10px;
   }

   .thumb_image{
    width: 23vw;
    height: 16vw;
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

}



/* Landscape Orientation for Tablets and Mobiles */


/* Tablets and IPads */

@media only screen 
  and (min-width: 960px) 
  and (max-width: 1023px) 
  and (orientation : landscape){

    .gallery_container{
        grid-template-rows: repeat(auto-fill,25.5vw);
        grid-row-gap: 15px;
        row-gap: 15px;
        grid-column-gap: 15px;
        -webkit-column-gap: 15px;
                column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
    }

    .thumb_image{
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }


}

@media only screen 
  and (min-width: 1179px) 
  and (max-width: 1023px) 
  and (orientation : landscape){

    .gallery_container{
        grid-template-rows: repeat(auto-fill,20vw);
        grid-row-gap: 15px;
        row-gap: 15px;
        grid-column-gap: 15px;
        -webkit-column-gap: 15px;
                column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
    }

    .thumb_image{
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }


}

/*Ipad Landscape*/
@media only screen 
and (min-height : 1024px)
and (max-height : 1024px) 
and (min-width : 768px)
and (max-width : 768px) 
and (orientation : landscape) {

    .gallery_container{
        grid-template-rows: repeat(auto-fill,23.5vw);
        grid-row-gap: 15px;
        row-gap: 15px;
        grid-column-gap: 15px;
        -webkit-column-gap: 15px;
                column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
    }

    .thumb_image{
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }

}

/* IPad Pro 10.5 Landscape */
@media only screen 
  and (min-width: 834px)
  and (max-width: 834px) 
  and (orientation: landscape) {
    .gallery_container{
        
        grid-row-gap: 15px;
        
        row-gap: 15px;
        grid-column-gap: 15px;
        -webkit-column-gap: 15px;
                column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
    }

    .thumb_image{
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }
}

/* IPad Pro 12.5 Landscape */
@media only screen
and (min-width: 1024px)
and (max-width: 1024px)
and (orientation: landscape){
    .gallery_container{
        grid-template-rows: repeat(auto-fill,25.5vw);
        grid-row-gap: 15px;
        row-gap: 15px;
        grid-column-gap: 15px;
        -webkit-column-gap: 15px;
                column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
    }

    .thumb_image{
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }
}

@media only screen
  and (min-width: 1366px)
  and (max-width: 1366px)
  and (orientation: landscape){
    .gallery_container{
        grid-template-rows: repeat(auto-fill,20vw);
        grid-row-gap: 15px;
        row-gap: 15px;
        grid-column-gap: 15px;
        -webkit-column-gap: 15px;
                column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
    }

    .thumb_image{
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }
}

@media only screen
  and (min-width: 1112px)
  and (max-width: 1112px)
  and (orientation: landscape){
    .gallery_container{
        grid-template-rows: repeat(auto-fill,18.5vw);
        grid-row-gap: 15px;
        row-gap: 15px;
        grid-column-gap: 15px;
        -webkit-column-gap: 15px;
                column-gap: 15px;
        margin-top: 3em;
        margin-left: 1.4em;
        margin-right: 1.4em;
    }

    .thumb_image{
        height: 25vh;
        box-shadow: 0 5px 15px rgb(0 0 0 / 45%);
    }

    .gallery_container > div:hover{
        background-color: rgb(255 , 255 , 255, 0.25);
    }
}

.studioPage_studioPage__awT46 {
  font-family: "Roboto", sans-serif;
  width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  -ms-scroll-snap-type: y mandatory;
      scroll-snap-type: y mandatory;
  margin-top: 1em !important;
}
  
.studioPage_studioPage__awT46 h2 {
  letter-spacing: 5px;
  color: #9e9e9e;
  margin: 12vh 0;
}

.studioPage_nav__1YwKm{
  height: 5.2vw;
  background-color: white;
}


.studioPage_nav__1YwKm > ul {
  color: #9e9e9e;
  list-style-type: none;
  display: flex;
  font-family: Roboto;
  justify-content: space-between;
  margin: 0 30vw 2vw 30vw;
  font-size: 1.1em;
  position: relative;
  top: 50%;
}

.studioPage_nav_links__1QONy,
.studioPage_nav_links__1QONy:hover{
  color: #9e9e9e;
  text-decoration: none;
  
}

.studioPage_activeLink__ES-93{
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.studioPage_container__357kj{
  height: auto;
  padding-bottom: 10vh;
  margin: 2em 0;
}

  @media only screen 
    and (max-width: 900px)
    and (orientation : portrait){

    .studioPage_nav__1YwKm{
      height: 25vw;
    }

    .studioPage_nav__1YwKm > ul{
      margin: 0 25vw;
      justify-content: space-between;
    }
    
  }

  @media only screen 
    and (max-width : 900px)
    and (orientation : landscape){

    .studioPage_nav__1YwKm > ul{
      margin: 0 20vw;
      justify-content: space-between;
    }

  }
  
/* Smartphone portrait */

@media only screen 
  and (max-width:240px){

    .studioPage_container__357kj{
        margin: 2em 9vw;
    } 

}


@media only screen 
  and (min-width : 240px)
  and (max-width : 360px)
  and (orientation : portrait){

    .studioPage_container__357kj{
        margin: 2em 8vw;
    } 
}

@media only screen
  and (min-width : 360px) 
  and (max-width : 399px)
  and (orientation : portrait){

    .studioPage_container__357kj{
        margin: 2em 8vw;
    }     
}


@media only screen
  and (min-width : 400px) 
  and (max-width : 428px)
  and (orientation : portrait){
    .studioPage_container__357kj{
        margin: 2em 7.5vw;
    } 
}

@media only screen
  and (min-width : 428px) 
  and (max-width : 500px)
  and (orientation : portrait){
    .studioPage_container__357kj{
        margin: 2em 9vw;
    } 
}

@media only screen
  and (min-width : 500px) 
  and (max-width : 600px)
  and (orientation : portrait){

    .studioPage_container__357kj{
        margin: 2em 9vw;
    } 
    
}

@media only screen 
  and (min-width: 600px)
  and (max-width : 800px)
  and (orientation : portrait){

    .studioPage_container__357kj{
        margin: 2em 5.3vw;
    } 
    

}



/* Smartphone Landscape */

@media only screen  
  and (min-width : 320px)
  and (max-width : 900px)
  and (orientation : landscape){
    .studioPage_container__357kj{
        margin : 2em 4.9vw;
    } 
}

@media only screen  
  and (min-width : 320px)
  and (max-width : 499px)
  and (orientation : landscape){

}

@media only screen  
  and (min-width : 500px)
  and (max-width : 599px)
  and (orientation : landscape){

 
}

@media only screen  
  and (min-width : 600px)
  and (max-width : 650px)
  and (orientation : landscape){
 
}

@media only screen  
  and (min-width : 650px)
  and (max-width : 700px)
  and (orientation : landscape){
 
}



@media only screen  
  and (min-width : 700px)
  and (max-width : 750px)
  and (orientation : landscape){
 
}

@media only screen  
  and (min-width : 750px)
  and (max-width : 799px)
  and (orientation : landscape){
 
}

@media only screen  
  and (min-width : 800px)
  and (max-width : 890px)
  and (orientation : landscape){
 
}


@media only screen  
  and (min-width : 890px)
  and (max-width : 900px)
  and (orientation : landscape){
  
}







/* Desktops and laptops ----------- */

@media only screen  
  and (min-width : 720px) 
  and (min-height: 600px){

    .studioPage_container__357kj{
        margin: 2em 5.3vw;
    } 
  
}

@media only screen  
  and (min-width : 900px){
    
    .studioPage_container__357kj{
        margin : 2em 5vw;
    } 
}

@media only screen and (min-width: 1000px) {
    .studioPage_container__357kj{
        margin : 2em 3.3vw;
    } 
}



@media only screen and (min-width : 1280px) {
    .studioPage_container__357kj{
        margin : 2em 3.3vw;
    } 
}



/*LCD Displays*/
@media only screen 
  and (min-width: 1024px) 
  and (max-width: 1080px)  
  and (min-height : 800px) 
  and (max-height : 900px) {
    .studioPage_container__357kj{
        margin : 2em 3.3vw;
    } 
}


/* Basic Laptop Screens */
@media only screen and (min-width : 1440px) {
    .studioPage_container__357kj{
        margin : 2em 3.3vw;
    } 

}

/* Larger laptop screens */
@media only screen  and (min-width : 1500px) {
    .studioPage_container__357kj{
        margin : 2em 3.3vw;
    } 
}


/* Large screens ----------- */
@media only screen  and (min-width : 1600px) {
  
    .studioPage_container__357kj{
        margin : 2em 3.3vw;
    }   
}

@media only screen  and (min-width : 1700px) {
    .studioPage_container__357kj{
        margin : 2em 3.3vw;
    }   
}

@media only screen  and (min-width : 1900px) {
    .studioPage_container__357kj{
        margin : 2em 3.3vw;
    }  
 
}


@media only screen  and (min-width : 2000px) {
    .studioPage_container__357kj{
        margin : 2em 3.3vw;
    }  
  
}


/* Macbook Pro */
@media only screen 
  and (min-width: 1700px) 
  and (max-width: 1800px)  
  and (min-height : 1080px) 
  and (max-height : 1100px) {
    .studioPage_container__357kj{
        margin : 2em 3.3vw;
    } 
}


/* Potrait Orientation for Tablets and Mobiles */
@media only screen 
  and (min-width: 600px)
  and (orientation : portrait)
  and (-webkit-min-device-pixel-ratio: 1){
    .studioPage_container__357kj{
        margin : 2em 5.3vw;
    } 
}

@media only screen 
  and (min-width: 600px)
  and (max-width: 720px)
  and (orientation : portrait)
  and (-webkit-min-device-pixel-ratio: 1){
    .studioPage_container__357kj{
        margin : 2em 5.3vw;
    } 
}

@media only screen 
  and (min-width: 720px) 
  and (max-width: 769px) 
  and (orientation : portrait)
  and (-webkit-device-pixel-ratio: 1){
    .studioPage_container__357kj{
        margin : 2em 5.3vw;
    }
}


@media only screen 
  and (min-width: 769px) 
  and (max-width: 850px)
  and (max-height : 1300px)
  and (orientation : portrait){
    .studioPage_container__357kj{
        margin : 2em 5.3vw;
    }
}

@media only screen 
  and (min-width: 850px) 
  and (max-width: 899px) 
  and (orientation : portrait){
    .studioPage_container__357kj{
        margin : 2em 5.3vw;
    } 
}




@media only screen 
  and (min-width: 900px) 
  and (max-width: 959px) 
  and (orientation : portrait){
    .studioPage_container__357kj{
        margin : 2em 5.3vw;
    }
}


/*Ipad Potrait*/
@media only screen 
and (min-width : 768px) 
and (max-width : 1024px)
and (max-height: 1024px) 
and (orientation : portrait) 
and (-webkit-min-device-pixel-ratio: 1)
{
    .studioPage_container__357kj{
        margin : 2em 5.3vw;
    }
  

}

/* IPad Pro 10.5 Portrait */
@media only screen 
  and (min-width: 834px)
  and (max-width: 834px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1)
  {
    .studioPage_container__357kj{
        margin : 2em 5.3vw;
    }
}

/* IPad Pro 12.5 Portrait */
@media only screen
and (min-width: 1024px)
and (max-width: 1024px)
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 1)
{
    .studioPage_container__357kj{
        margin : 2em 5.3vw;
    }

}







/* Landscape Orientation for Tablets and Mobiles */


/* Tablets and IPads */




@media only screen 
  and (min-width: 960px) 
  and (max-width: 1023px) 
  and (orientation : landscape){
    .studioPage_container__357kj{
        margin : 2em 3.3vw;
    }
 
} 

@media only screen 
  and (min-width: 1023px) 
  and (max-width: 1179px) 
  and (orientation : landscape)
  and (-webkit-min-device-pixel-ratio: 2){

    .studioPage_container__357kj{
        margin : 2em 3.3vw;
    }
  
}


@media only screen
  and (min-width: 1180px)
  and (max-width: 1194px)
  and (orientation: landscape){
    .studioPage_container__357kj{
        margin : 2em 3.3vw;
    } 
}

@media only screen 
  and (min-width : 1280px) 
  and (min-height : 740px) 
  and (max-height : 750px) {
    .studioPage_container__357kj{
        margin : 2em 3.3vw;
    } 

}

/*Ipad Landscape*/
@media only screen 
and (min-height : 768px)
and (max-width : 1024px)
and (orientation : landscape)
and (-webkit-min-device-pixel-ratio: 1) {
    .studioPage_container__357kj{
        margin : 2em 3.3vw;
    }
 

}

/* IPad Pro 10.5 Landscape */
@media only screen 
  and (min-height: 834px)
  and (max-width: 1112px) 
  and (orientation: landscape){

    .studioPage_container__357kj{
        margin : 2em 3.3vw;
    }  
}

/* IPad Pro 12.5 Landscape */
@media only screen
  and (min-height: 1024px)
  and (max-height: 1024px)
  and (orientation: landscape){
    .studioPage_container__357kj{
        margin : 2em 3.3vw;
    }
}




.newsDiv_newsPage__xE1S6 {
  width: 100% !important;
  padding: 0 !important;
  margin: 0 0 4em 0 !important;
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
  -ms-scroll-snap-type: y mandatory;
      scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  line-height: 2.1em;
}

.newsDiv_mediaBox__1TO7v img {
  height: 100% !important;
  width: 100%;
}

.newsDiv_mediaBox__1TO7v {
  position: relative; 
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
  height: 35vw;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: overlay;
    /* overflow: hidden;
    text-align: center;
    margin: 0 auto; */
}
.newsDiv_mediaBox__1TO7v::before{
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  /* background-color: rgba(0,0,0,0.25); */

}
.newsDiv_mediaBox__1TO7v > h1{
  position: relative;
  color: #ffffff;
  font-weight: bold;  
  font-size: 8em;
  line-height: 0.9;
  text-align: center;
}



.newsDiv_newsMedia__2zoji {
  width: 40vw;
  height: 18vw;
  background-color: #e9e9e9;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: overlay;
}


.newsDiv_newsContent__Zlh42 {
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  margin-top: -20px;
}

.newsDiv_newsContent__Zlh42 > h1 {
  color: #808080;
  font-weight: 200;
  font-size: 2rem;
}
.newsDiv_newsContent__Zlh42 > p {
  color: #9e9e9e;
  display: block;
  text-align: justify;
  white-space: break-spaces;
  width: 100%;
  position: relative;
  font-size: 0.99vw;
  line-height: 2em;
}

/* .newsContainer > .news {
  display: grid;
  grid-auto-flow: column;
} */

.newsDiv_newsContainer__2ZRGr{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: column;
  margin: 30px 0 0 0 !important;
}

.newsDiv_newsContent__Zlh42 > .newsDiv_infoLink__2NZy7{
  text-align: left;
}

@media only screen and (max-width : 900px){
  .newsDiv_mediaBox__1TO7v > h1{
    font-size: 5em;
  }

  .newsDiv_newsContainer__2ZRGr{
    display: flex;
    flex-direction: column;
  }
  .newsDiv_mediaBox__1TO7v{
    height: 60vw;
  }
}

/* Smartphone portrait */

@media only screen 
  and (max-width:240px){

    .newsDiv_newsPage__xE1S6{
        margin: 2em 9vw;
    }
    
    .newsDiv_newsMedia__2zoji{
      width: 83vw;
      height: 45vw;
      margin-bottom: 2em;
    }

    .newsDiv_mediaBox__1TO7v > h1{
      font-size: 3em;
    }

}


@media only screen 
  and (min-width : 240px)
  and (max-width : 360px)
  and (orientation : portrait){

    .newsDiv_newsPage__xE1S6{
        margin: 2em 8vw;
    } 

    .newsDiv_newsMedia__2zoji{
      width: 83vw;
      height: 45vw;
      margin-bottom: 2em;
    }

    .newsDiv_right__1icpI{
      padding: 0;
    }

    .newsDiv_newsContent__Zlh42 > p{
      font-size: 0.9rem;
    }

    .newsDiv_mediaBox__1TO7v > h1{
      font-size: 3em;
    }

}

@media only screen
  and (min-width : 360px) 
  and (max-width : 399px)
  and (orientation : portrait){

    .newsDiv_newsPage__xE1S6{
        margin: 2em 8vw;
    }     

    .newsDiv_newsMedia__2zoji{
      width: 84vw;
      height: 45vw;
      margin-bottom: 2em;
    }

    .newsDiv_newsContent__Zlh42 > h1{
      font-size: 1.5rem;
    }

    .newsDiv_newsContent__Zlh42 > p{
      font-size: 0.9rem;
    }
    
    .newsDiv_mediaBox__1TO7v > h1{
      font-size: 3em;
    }

}


@media only screen
  and (min-width : 400px) 
  and (max-width : 428px)
  and (orientation : portrait){
    .newsDiv_newsPage__xE1S6{
        margin: 2em 7.5vw;
    } 

    .newsDiv_newsMedia__2zoji{
      width: 84.5vw;
      height: 45vw;
      margin-bottom: 2em;
    }

    .newsDiv_newsContent__Zlh42 > h1{
      font-size: 1.5rem;
    }

    .newsDiv_newsContent__Zlh42 > p{
      font-size: 0.9rem;
    }

    .newsDiv_mediaBox__1TO7v > h1{
      font-size: 3em;
    }

}

@media only screen
  and (min-width : 428px) 
  and (max-width : 500px)
  and (orientation : portrait){
    .newsDiv_newsPage__xE1S6{
        margin: 2em 9vw;
    } 

    .newsDiv_newsMedia__2zoji{
      width: 82vw;
      height: 45vw;
      margin-bottom: 2em;
    }

    .newsDiv_newsContent__Zlh42 > h1{
      font-size: 1.5rem;
    }

    .newsDiv_newsContent__Zlh42 > p{
      font-size: 0.9rem;
    }

    .newsDiv_mediaBox__1TO7v > h1{
      font-size: 3em;
    }

}

@media only screen
  and (min-width : 500px) 
  and (max-width : 600px)
  and (orientation : portrait){

    .newsDiv_newsPage__xE1S6{
        margin: 2em 9vw;
    } 

    .newsDiv_newsMedia__2zoji{
      width: 82vw;
      height: 45vw;
      margin-bottom: 2em;
    }

    .newsDiv_newsContent__Zlh42 > h1{
      font-size: 1.5rem;
    }

    .newsDiv_newsContent__Zlh42 > p{
      font-size: 0.9rem;
    }

    .newsDiv_mediaBox__1TO7v > h1{
      font-size: 3em;
    }
    
}

@media only screen 
  and (min-width: 600px)
  and (max-width : 800px)
  and (orientation : portrait){

    .newsDiv_newsPage__xE1S6{
        margin: 2em 5.3vw;
    } 

    .newsDiv_newsMedia__2zoji{
      width: 84.5vw;
      height: 45vw;
    }

    .newsDiv_newsContent__Zlh42 > h1{
      font-size: 1.5rem;
    }

    .newsDiv_newsContent__Zlh42 > p{
      font-size: 0.9rem;
    }

    .newsDiv_mediaBox__1TO7v > h1{
      font-size: 3em;
    }

}



/* Smartphone Landscape */

@media only screen  
  and (min-width : 320px)
  and (max-width : 900px)
  and (orientation : landscape){
    .newsDiv_newsPage__xE1S6{
        margin : 2em 4.9vw;
    }

    .newsDiv_newsMedia__2zoji{
      width: 92.2vw;
      height: 38.7vw;
      margin-bottom: 2em;
    }

    .newsDiv_newsContent__Zlh42 > p {
      font-size: 1rem;
    }



    .newsDiv_mediaBox__1TO7v > h1{
      font-size: 3em;
    }
}

@media only screen  
  and (min-width : 320px)
  and (max-width : 499px)
  and (orientation : landscape){

    .newsDiv_newsMedia__2zoji{
      width: 90vw;
      height: 50vw;
      margin-bottom: 2em;
    }

    .newsDiv_newsMedia__2zoji{
      width: 92.2vw;
      height: 38.7vw;
    }

  

    .newsDiv_newsContent__Zlh42 > p {
      font-size: 1rem;
    }

    .newsDiv_mediaBox__1TO7v > h1{
      font-size: 3em;
    }

  }

@media only screen  
  and (min-width : 500px)
  and (max-width : 599px)
  and (orientation : landscape){
    
    .newsDiv_newsMedia__2zoji{
      width: 43.4vw;
      height: 30vw;
      margin-bottom: 2em;
    }

    .newsDiv_newsMedia__2zoji{
      width: 92.2vw;
      height: 38.7vw;
    }

    .newsDiv_newsContent__Zlh42 > p {
      font-size: 1rem;
    }


    .newsDiv_mediaBox__1TO7v > h1{
      font-size: 3em;
    }
 
}

@media only screen  
  and (min-width : 600px)
  and (max-width : 650px)
  and (orientation : landscape){
 

    .newsDiv_newsMedia__2zoji{
      width: 92.2vw;
      height: 38.7vw;
      margin-bottom: 2em;
    }

    .newsDiv_newsContent__Zlh42 > p {
      font-size: 1rem;
    }

    .newsDiv_mediaBox__1TO7v > h1{
      font-size: 3em;
    }

}

@media only screen  
  and (min-width : 650px)
  and (max-width : 700px)
  and (orientation : landscape){


    .newsDiv_newsMedia__2zoji{
      width: 92.2vw;
      height: 38.7vw;
      margin-bottom: 2em;
    }

    .newsDiv_newsContent__Zlh42 > p {
      font-size: 1rem;
    }

    .newsDiv_mediaBox__1TO7v > h1{
      font-size: 3em;
    }

}


@media only screen  
  and (min-width : 700px)
  and (max-width : 750px)
  and (orientation : landscape){

    .newsDiv_newsMedia__2zoji{
      width: 92.2vw;
      height: 38.7vw;
      margin-bottom: 2em;
    }

    .newsDiv_newsContent__Zlh42 > p {
      font-size: 1rem;
    }


    .newsDiv_mediaBox__1TO7v > h1{
      font-size: 3em;
    }

}

@media only screen  
  and (min-width : 750px)
  and (max-width : 799px)
  and (orientation : landscape){
 

    .newsDiv_newsMedia__2zoji{
      width: 92.2vw;
      height: 38.7vw;
      margin-bottom: 2em;

    }

    .newsDiv_newsContent__Zlh42 > p {
      font-size: 1rem;
    }


    .newsDiv_mediaBox__1TO7v > h1{
      font-size: 3em;
    }

}

@media only screen  
  and (min-width : 800px)
  and (max-width : 890px)
  and (orientation : landscape){


    .newsDiv_newsMedia__2zoji{
      width: 92.2vw;
      height: 38.7vw;
      margin-bottom: 2em;
    }

    .newsDiv_newsContent__Zlh42 > p {
      font-size: 1rem;
    }


    .newsDiv_mediaBox__1TO7v > h1{
      font-size: 3em;
    }

}


@media only screen  
  and (min-width : 890px)
  and (max-width : 900px)
  and (orientation : landscape){
 

    .newsDiv_newsMedia__2zoji{
      width: 92.2vw;
      height: 38.7vw;
      margin-bottom: 2em;
    }

    .newsDiv_newsContent__Zlh42 > p {
      font-size: 1rem;
    }

    .newsDiv_mediaBox__1TO7v > h1{
      font-size: 3em;
    }

}







/* Desktops and laptops ----------- */

@media only screen  
  and (min-width : 720px) 
  and (min-height: 600px){

    .newsDiv_newsContainer__2ZRGr{
      display: flex;
    }

    .newsDiv_newsContent__Zlh42{
      width: 65%;
    }

    .newsDiv_newsPage__xE1S6{
        margin: 2em 5.3vw;
    } 

    .newsDiv_newsMedia__2zoji{
      width: 100%;
      height: 40vw;
      margin-bottom: 2em;
    }

    .newsDiv_newsContent__Zlh42{
      width: 100%;
    }
  
}

@media only screen  
  and (min-width : 900px){
    
    .newsDiv_newsPage__xE1S6{
        margin : 2em 5vw;
    }

    .newsDiv_newsContainer__2ZRGr{
      display: flex;
      flex-direction: column;
    }

    
    .newsDiv_newsContent__Zlh42 > p {
      font-size: 1rem;
    }
}

@media only screen and (min-width: 1000px) {
    .newsDiv_newsPage__xE1S6{
        margin : 2em 3.3vw;
    } 

    .newsDiv_newsContainer__2ZRGr{
      display: grid;
      grid-template-columns: 0.6fr 1fr;
    }

    .newsDiv_newsMedia__2zoji{
      width: 29.8vw;
      height: 20vw;
    }

    .newsDiv_newsContent__Zlh42{
      width: 75%;
    }
}



@media only screen and (min-width : 1280px) {
    .newsDiv_newsPage__xE1S6{
        margin : 2em 5vw;
    } 

    .newsDiv_newsContainer__2ZRGr{
      display: grid;
      grid-template-columns: 0.347fr 1fr;
    }
    
    .newsDiv_newsPage__xE1S6{
        margin : 2em 3.3vw;
    } 
  
    .newsDiv_newsMedia__2zoji{
      width: 22.3vw;
      height: 16vw;
    }
  
    .newsDiv_newsContent__Zlh42 > p{
      width: 95%;

    }
}



/*LCD Displays*/
@media only screen 
  and (min-width: 1024px) 
  and (max-width: 1080px)  
  and (min-height : 800px) 
  and (max-height : 900px) {
    .newsDiv_newsPage__xE1S6{
        margin : 2em 3.3vw;
    }

    .newsDiv_newsContainer__2ZRGr{
      display: grid;
      grid-template-columns: 0.5fr 1fr;
    }
}


/* Basic Laptop Screens */
@media only screen and (min-width : 1440px) {
  .newsDiv_newsPage__xE1S6{
      margin : 2em 3.3vw;
  } 

  .newsDiv_newsContainer__2ZRGr{
    display: grid;
    grid-template-columns: 0.347fr 1fr;
  }
  
  .newsDiv_newsPage__xE1S6{
      margin : 2em 3.3vw;
  } 

  .newsDiv_newsMedia__2zoji{
    width: 22.3vw;
    height: 16vw;
  }

  .newsDiv_newsContent__Zlh42 > p{
    width: 115%;
  }
    

}

/* Larger laptop screens */
@media only screen  and (min-width : 1500px) {

.newsDiv_newsContainer__2ZRGr{
  display: grid;
  grid-template-columns: 0.347fr 1fr;
}

.newsDiv_newsPage__xE1S6{
    margin : 2em 3.3vw;
} 

.newsDiv_newsMedia__2zoji{
  width: 22.3vw;
  height: 16vw;
}

.newsDiv_newsContent__Zlh42 > p{
  width: 95%;
}
    
}


/* Large screens ----------- */
@media only screen  and (min-width : 1600px) {

  .newsDiv_newsContainer__2ZRGr{
    display: grid;
    grid-template-columns: 0.347fr 1fr;
  }

  .newsDiv_newsPage__xE1S6{
      margin : 2em 3.3vw;
  } 

  .newsDiv_newsMedia__2zoji{
    width: 22.4vw;
    height: 16.6vw;
  }

  .newsDiv_newsContent__Zlh42 > p{
    width: 95%;
  }

  .newsDiv_newsPage__xE1S6{
      margin : 2em 3.3vw;
  }   
}

@media only screen  and (min-width : 1700px) {

  .newsDiv_newsContainer__2ZRGr{
    display: grid;
    grid-template-columns: 0.347fr 1fr;
  }

  .newsDiv_newsPage__xE1S6{
      margin : 2em 3.3vw;
  } 

  .newsDiv_newsMedia__2zoji{
    width: 22.5vw;
    height: 16.6vw;
  }

  .newsDiv_newsContent__Zlh42 > p{
    width: 95%;
  }

  .newsDiv_newsPage__xE1S6{
      margin : 2em 3.3vw;
  }   
}

@media only screen  and (min-width : 1900px) {

  .newsDiv_newsContainer__2ZRGr{
    display: grid;
    grid-template-columns: 0.347fr 1fr;
  }

  .newsDiv_newsPage__xE1S6{
      margin : 2em 3.3vw;
  } 

  .newsDiv_newsMedia__2zoji{
    width: 22.5vw;
    height: 16.6vw;;
  }

  .newsDiv_newsContent__Zlh42 > p{
    width: 95%;
  }

  .newsDiv_newsPage__xE1S6{
      margin : 2em 3.3vw;
  }  
 
}


@media only screen  and (min-width : 2000px) {

  .newsDiv_newsContainer__2ZRGr{
    display: grid;
    grid-template-columns: 0.347fr 1fr;
  }

  .newsDiv_newsPage__xE1S6{
      margin : 2em 3.3vw;
  } 

  .newsDiv_newsMedia__2zoji{
    width: 22.7vw;
    height: 16.6vw;
  }

  .newsDiv_newsContent__Zlh42 > p{
    width: 95%;
  }

  .newsDiv_newsPage__xE1S6{
      margin : 2em 3.3vw;
  }  

}


/* Macbook Pro */
@media only screen 
  and (min-width: 1700px) 
  and (max-width: 1800px)  
  and (min-height : 1080px) 
  and (max-height : 1100px) {

    .newsDiv_newsContainer__2ZRGr{
      display: grid;
      grid-template-columns: 0.5fr 1fr;
    }
  
    .newsDiv_newsPage__xE1S6{
        margin : 2em 3.3vw;
    } 

    .newsDiv_newsMedia__2zoji{
      width: 22.5vw;
      height: 16vw;
    }

    .newsDiv_newsContent__Zlh42 > p{
      width: 115%;
    }

  .newsDiv_newsPage__xE1S6{
      margin : 2em 3.3vw;
  } 
}


/* Potrait Orientation for Tablets and Mobiles */

@media only screen 
  and (min-width: 600px)
  and (max-width: 720px)
  and (orientation : portrait)
  and (-webkit-min-device-pixel-ratio: 1){
    .newsDiv_newsPage__xE1S6{
        margin : 2em 5.3vw;
    } 

    .newsDiv_newsContainer__2ZRGr{
      display: flex;
      flex-direction: column;
    }

    .newsDiv_newsMedia__2zoji{
      width: 100%;
      height: 35vw;
      margin-bottom: 2em;
    }

    .newsDiv_newsContent__Zlh42{
      width: 100%;
    }

    .newsDiv_newsContent__Zlh42 > p {
      font-size: 1rem;
    }
}

@media only screen 
  and (min-width: 720px) 
  and (max-width: 769px) 
  and (orientation : portrait)
  and (-webkit-device-pixel-ratio: 1){
    .newsDiv_newsPage__xE1S6{
        margin : 2em 5.3vw;
    }

    .newsDiv_newsContainer__2ZRGr{
      display: flex;
      flex-direction: column;
    }

    .newsDiv_newsMedia__2zoji{
      width: 100%;
      height: 35vw;
      margin-bottom: 2em;
    }

    .newsDiv_newsContent__Zlh42{
      width: 100%;
    }

    .newsDiv_newsContent__Zlh42 > p {
      font-size: 1rem;
    }
}


@media only screen 
  and (min-width: 769px) 
  and (max-width: 850px)
  and (max-height : 1300px)
  and (orientation : portrait){
    .newsDiv_newsPage__xE1S6{
        margin : 2em 5.3vw;
    }

    .newsDiv_newsContainer__2ZRGr{
      display: flex;
      flex-direction: column;
    }

    .newsDiv_newsMedia__2zoji{
      width: 100%;
      height: 35vw;
      margin-bottom: 2em;
    }

    .newsDiv_newsContent__Zlh42{
      width: 100%;
    }

    .newsDiv_newsContent__Zlh42 > p {
      font-size: 1rem;
    }
}

@media only screen 
  and (min-width: 850px) 
  and (max-width: 899px) 
  and (orientation : portrait){
    .newsDiv_newsPage__xE1S6{
        margin : 2em 5.3vw;
    } 

    .newsDiv_newsContainer__2ZRGr{
      display: flex;
      flex-direction: column;
    }

    .newsDiv_newsMedia__2zoji{
      width: 100%;
      height: 35vw;
      margin-bottom: 2em;
    }

    .newsDiv_newsContent__Zlh42{
      width: 100%;
    }

    .newsDiv_newsContent__Zlh42 > p {
      font-size: 1rem;
    }
}




@media only screen 
  and (min-width: 900px) 
  and (max-width: 959px) 
  and (orientation : portrait){
    .newsDiv_newsPage__xE1S6{
        margin : 2em 5.3vw;
    }

    .newsDiv_newsContainer__2ZRGr{
      display: flex;
      flex-direction: column;
    }

    .newsDiv_newsMedia__2zoji{
      width: 100%;
      height: 35vw;
      margin-bottom: 2em;
    }

    .newsDiv_newsContent__Zlh42{
      width: 100%;
    }

    .newsDiv_newsContent__Zlh42 > p {
      font-size: 1rem;
    }
}

@media only screen 
  and (min-width: 1000px)
  and (orientation : portrait){

    .newsDiv_newsContainer__2ZRGr{
      display: flex;
      flex-direction: column;
    }

    .newsDiv_newsPage__xE1S6{
        margin : 2em 5.3vw;
    }

    .newsDiv_newsMedia__2zoji{
      width: 100%;
      height: 35vw;
      margin-bottom: 2em;
    }

    .newsDiv_newsContent__Zlh42{
      width: 100%;
    }

    .newsDiv_newsContent__Zlh42 > p {
      font-size: 1rem;
    }
}


/*Ipad Potrait*/
@media only screen 
and (min-width : 768px) 
and (max-width : 1024px)
and (max-height: 1024px) 
and (orientation : portrait) 
and (-webkit-min-device-pixel-ratio: 1)
{
    .newsDiv_newsPage__xE1S6{
        margin : 2em 5.3vw;
    }

    .newsDiv_newsContainer__2ZRGr{
      display: flex;
      flex-direction: column;
    }

    .newsDiv_newsMedia__2zoji{
      width: 100%;
      height: 35vw;
      margin-bottom: 2em;
    }
  
    .newsDiv_newsContent__Zlh42{
      width: 100%;
    }

    .newsDiv_newsContent__Zlh42 > p {
      font-size: 1rem;
    }

}

/* IPad Pro 10.5 Portrait */
@media only screen 
  and (min-width: 834px)
  and (max-width: 834px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1)
  {
    .newsDiv_newsPage__xE1S6{
        margin : 2em 5.3vw;
    }

    .newsDiv_newsContainer__2ZRGr{
      display: flex;
      flex-direction: column;
    }
  
    .newsDiv_newsMedia__2zoji{
      width: 100%;
      height: 35vw; 
    }
    .newsDiv_newsContent__Zlh42{
      width: 100%;
    }

    .newsDiv_newsContent__Zlh42 > p {
      font-size: 1rem;
    }
}

/* IPad Pro 12.5 Portrait */
@media only screen
and (min-width: 1024px)
and (max-width: 1024px)
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 1)
{
  .newsDiv_newsContainer__2ZRGr{
    display: flex;
    flex-direction: column;
  }

  .newsDiv_newsMedia__2zoji{
    width: 100%;
    height: 35vw;
    margin-bottom: 2em;
  }


  .newsDiv_newsPage__xE1S6{
    margin : 2em 5.3vw;
  }



  .newsDiv_newsContent__Zlh42{
    width: 100%;
  }

  
  .newsDiv_newsContent__Zlh42 > p{
    font-size: 1.05rem;
  }


}







/* Landscape Orientation for Tablets and Mobiles */


/* Tablets and IPads */




@media only screen 
  and (min-width: 960px) 
  and (max-width: 1023px) 
  and (orientation : landscape){

    .newsDiv_newsContainer__2ZRGr{
      display: grid;
      grid-template-columns: 0.6fr 1fr;
    }

    .newsDiv_newsContent__Zlh42 > p{
      font-size: 1vw;
    }

    .newsDiv_newsContent__Zlh42{
      width: 75%;
    }

    .newsDiv_newsPage__xE1S6{
        margin : 2em 3.3vw;
    }

    .newsDiv_newsMedia__2zoji{
      width: 44vw;
      height: 32.5vw; 
    }


    .newsDiv_newsMedia__2zoji{
      width: 29.8vw;
      height: 20vw;
    }



 
} 

@media only screen 
  and (min-width: 1023px) 
  and (max-width: 1179px) 
  and (orientation : landscape)
  and (-webkit-min-device-pixel-ratio: 2){

    .newsDiv_newsContainer__2ZRGr{
      grid-template-columns: 0.6fr 1fr;
    }

    .newsDiv_newsContent__Zlh42 > p{
      font-size: 1vw;
    }

    .newsDiv_newsContent__Zlh42{
      width: 75%;
    }
    .newsDiv_newsPage__xE1S6{
        margin : 2em 3.3vw;
    }

    .newsDiv_newsMedia__2zoji{
      width: 29.8vw;
      height: 20vw;
    }


  
}


@media only screen
  and (min-width: 1180px)
  and (max-width: 1194px)
  and (orientation: landscape){

    .newsDiv_newsContainer__2ZRGr{
      grid-template-columns: 0.6fr 1fr;
    }

    .newsDiv_newsContent__Zlh42 > p{
      font-size: 1vw;
    }

    .newsDiv_newsContent__Zlh42{
      width: 70%;
    }

    .newsDiv_newsPage__xE1S6{
        margin : 2em 3.3vw;
    } 

    .newsDiv_newsMedia__2zoji{
      width: 29.8vw;
      height: 20vw;
    }


}

@media only screen 
  and (min-width : 1280px) 
  and (min-height : 740px) 
  and (max-height : 750px) {

    .newsDiv_newsContainer__2ZRGr{
      grid-template-columns: 0.6fr 1fr;
    }

    .newsDiv_newsContent__Zlh42 > p{
      font-size: 1vw;
    }

    .newsDiv_newsContent__Zlh42{
      width: 75%;
    }

    .newsDiv_newsPage__xE1S6{
        margin : 2em 3.3vw;
    } 
    .newsDiv_newsMedia__2zoji{
      width: 29.8vw;
      height: 20vw;
    }


}

/*Ipad Landscape*/
@media only screen 
and (min-height : 768px)
and (max-width : 1024px)
and (orientation : landscape)
and (-webkit-min-device-pixel-ratio: 1) {

  .newsDiv_newsContainer__2ZRGr{
    grid-template-columns: 0.6fr 1fr;
  }

  .newsDiv_newsContent__Zlh42 > p{
    font-size: 1vw;
  }

  .newsDiv_newsContent__Zlh42{
    width: 75%;
  }

    .newsDiv_newsPage__xE1S6{
        margin : 2em 3.3vw;
    }
 
    .newsDiv_newsMedia__2zoji{
      width: 29.8vw;
      height: 20vw;
    }

}

/* IPad Pro 10.5 Landscape */
@media only screen 
  and (min-height: 834px)
  and (max-width: 1112px) 
  and (orientation: landscape){

    .newsDiv_newsContainer__2ZRGr{
      display: grid;
      grid-template-columns: 0.6fr 1fr;
    }

    .newsDiv_newsContent__Zlh42 > p{
      font-size: 1vw;
    }

    .newsDiv_newsContent__Zlh42{
      width: 75%;
    }


    .newsDiv_newsPage__xE1S6{
        margin : 2em 3.3vw;
    }  

    .newsDiv_newsMedia__2zoji{
      width: 29.8vw;
      height: 20vw;
    }

}

/* IPad Pro 12.5 Landscape */
@media only screen
  and (min-height: 1024px)
  and (max-height: 1024px)
  and (orientation: landscape){

    .newsDiv_newsContainer__2ZRGr{
      grid-template-columns: 0.6fr 1fr;
    }

    .newsDiv_newsContent__Zlh42 > p{
      font-size: 1vw;
    }

    .newsDiv_newsContent__Zlh42{
      width: 75%;
    }
    .newsDiv_newsPage__xE1S6{
        margin : 2em 3.3vw;
    }

    .newsDiv_newsMedia__2zoji{
      width: 30.2vw;
      height: 23vw;
    }

}




.peopleDiv_peopleContainer__3meIE {
    margin: 0 0 4em 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .peopleDiv_mediaBox__20vuq img {
    width: 100%;
  }
  
  .peopleDiv_mediaBox__20vuq {
    position: relative; 
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
    height: 35vw;
    width: 100% !important ;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: overlay;
    /* overflow: hidden;
    text-align: center;
    margin: 0 auto; */
  }
  .peopleDiv_mediaBox__20vuq::before{
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    /* background-color: rgba(0,0,0,0.25); */
  
  }
  .peopleDiv_mediaBox__20vuq > h1{
    position: relative;
    color: #ffffff;
    font-weight: bold;  
    font-size: 8em;
    line-height: 0.9;
    text-align: center;
  }
  
  .peopleDiv_peopleCard__3q3Zg, .peopleDiv_card_overlay__XL9Yx {
    /* flex-grow: 1; */
    text-align: left;
  }
  
  .peopleDiv_headroom__18Enm, .peopleDiv_headroom--pinned__sDUTy, .peopleDiv_headroom--scrolled__Lt13k{
    z-index: 2;
  }

  .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx{
    position: absolute;
  }
  
  .peopleDiv_card_overlay__XL9Yx{
    display: block;
    position: relative; 
  }
  .peopleDiv_peopleCard__3q3Zg img {
    object-fit: cover;
  }
  
  .peopleDiv_card_overlay__XL9Yx{
    background-color: rgba(0 , 0 ,0 ,0.5);
    opacity: 0;
  }

  .peopleDiv_card_overlay__XL9Yx p{
    text-align: center;
    color: white;
    font-size: 20px;
    padding: 0;
    /* position: absolute; */
    margin: 0;
    /* line-height: 1.6 !important; */
    font-weight: 300 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    vertical-align: baseline;
    /* top: 45%; */
  }


  /* .peopleCard p {
    color: #7d7d7d;
    font-size: 12px;
    letter-spacing: 2px;
    display: flex;
    flex-wrap: wrap;
  } */
  
  .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
    width: 23vw;
    height: 16vw;
  }
  
  .peopleDiv_card_overlay__XL9Yx:hover{
    opacity: 1;
  }

  .peopleDiv_teamGrid__1l9mf {
    margin-top: 2em;
    width: 100% !important;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content;
    grid-column-gap: 21px;
    -webkit-column-gap: 21px;
            column-gap: 21px;
    grid-row-gap: 21px;
    row-gap: 21px;
    /* padding-bottom: 0; */
  }
  
  .peopleDiv_peopleCard__3q3Zg > div {
    margin-top: -0.85rem;
  }

  .peopleDiv_miniContent__jV7AI{
    font-size: 13px;
    color: #7d7d7d;
    width: 50%;
    text-align: center;
    overflow-wrap: break-word;
    margin-top: 3em;
  }

  .peopleDiv_miniContent__jV7AI > .peopleDiv_mini_head__3eEuJ{
    padding-bottom: 5px;
    font-size: 15px;
  }


@media only screen
  and (max-width : 900px) 
  and (orientation : portrait){

  .peopleDiv_teamGrid__1l9mf{
    display: flex;
    flex-direction: column;
  }

  .peopleDiv_miniContent__jV7AI{
    width: 100%;
  }
    
  .peopleDiv_mediaBox__20vuq > h1{
    font-size: 3em;
  }
  .peopleDiv_mediaBox__20vuq{
    height: 60vw;
  }

}


@media only screen
  and (max-width : 900px) 
  and (orientation : landscape){

    .peopleDiv_teamGrid__1l9mf{
      display: grid;
      grid-template-columns: repeat(2 , 1fr);
    }

    .peopleDiv_miniContent__jV7AI{
      width: 100%;
    }

    .peopleDiv_mediaBox__20vuq > h1{
      font-size: 3em;
    }
    .peopleDiv_mediaBox__20vuq{
      height: 60vw;
    }
}

/* Smartphone portrait */

@media only screen 
  and (max-width:240px){

  .peopleDiv_peopleContainer__3meIE{
      margin: 2em 9vw;
  } 

  .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
    width: 83vw;
    height: 45vw;
  }

  .peopleDiv_mediaBox__20vuq > h1{
    font-size: 3em;
  }

  .peopleDiv_teamGrid__1l9mf{
    display: grid;
    grid-template-columns: repeat(1 , 1fr);
  }


}


@media only screen 
  and (min-width : 240px)
  and (max-width : 360px)
  and (orientation : portrait){

    .peopleDiv_peopleContainer__3meIE{
        margin: 2em 8vw;
    }
    
    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 83vw;
      height: 45vw;
    }

    .peopleDiv_teamGrid__1l9mf{
     display: grid;
      grid-template-columns: repeat(1 , 1fr);
    }

}

@media only screen
  and (min-width : 360px) 
  and (max-width : 399px)
  and (orientation : portrait){

    .peopleDiv_peopleContainer__3meIE{
        margin: 2em 8vw;
    }     

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 84vw;
      height: 45vw;
    }

    .peopleDiv_teamGrid__1l9mf{
     display: grid;
      grid-template-columns: repeat(1 , 1fr);
    }

}


@media only screen
  and (min-width : 400px) 
  and (max-width : 428px)
  and (orientation : portrait){
    .peopleDiv_peopleContainer__3meIE{
        margin: 2em 7.5vw;
    } 

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 84.5vw;
      height: 45vw;
    }

    .peopleDiv_teamGrid__1l9mf{
     display: grid;
      grid-template-columns: repeat(1 , 1fr);
    }

}

@media only screen
  and (min-width : 428px) 
  and (max-width : 500px)
  and (orientation : portrait){
    .peopleDiv_peopleContainer__3meIE{
        margin: 2em 9vw;
    } 

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 82vw;
      height: 45vw;
    }

    .peopleDiv_teamGrid__1l9mf{
     display: grid;
      grid-template-columns: repeat(1 , 1fr);
    }
    
}

@media only screen
  and (min-width : 500px) 
  and (max-width : 600px)
  and (orientation : portrait){

    .peopleDiv_peopleContainer__3meIE{
        margin: 2em 9vw;
    } 

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 82vw;
      height: 45vw;
    }
    
    .peopleDiv_teamGrid__1l9mf{
      display: grid;
      grid-template-columns: repeat(1 , 1fr);
    }
}

@media only screen 
  and (min-width: 600px)
  and (max-width : 800px)
  and (orientation : portrait){

    .peopleDiv_peopleContainer__3meIE{
        margin: 2em 5.3vw;
    } 
    
    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 84.5vw;
      height: 45vw;
    }

    .peopleDiv_teamGrid__1l9mf{
     display: grid;
      grid-template-columns: repeat(1 , 1fr);
    }

}



/* Smartphone Landscape */

@media only screen  
  and (min-width : 320px)
  and (max-width : 900px)
  and (orientation : landscape){
    .peopleDiv_peopleContainer__3meIE{
        margin : 2em 4.9vw;
    } 

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 90vw;
      height: 50vw;  
    }

    .peopleDiv_teamGrid__1l9mf{
     display: grid;
      grid-template-columns: repeat(1 , 1fr);
    }

}

@media only screen  
  and (min-width : 320px)
  and (max-width : 499px)
  and (orientation : landscape){

  .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
    width: 90vw;
    height: 50vw;  
  }

  .peopleDiv_peopleContainer__3meIE{
    margin: 1em 5vw;
  } 

  .peopleDiv_teamGrid__1l9mf{
     display: grid;
    grid-template-columns: repeat(1 , 1fr);
  }

}

@media only screen  
  and (min-width : 500px)
  and (max-width : 599px)
  and (orientation : landscape){

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 43.4vw;
      height: 30vw;
    }

    .peopleDiv_peopleContainer__3meIE{
      margin: 2em 5vw;
    } 
    
    .peopleDiv_teamGrid__1l9mf{
     display: grid;
      grid-template-columns: repeat(2 , 1fr);
    }
}

@media only screen  
  and (min-width : 600px)
  and (max-width : 650px)
  and (orientation : landscape){

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 43.6vw;
      height: 34.2vw;
    }

    .peopleDiv_peopleContainer__3meIE{
      margin: 2em 5vw;
    } 

    .peopleDiv_teamGrid__1l9mf{
     display: grid;
      grid-template-columns: repeat(2 , 1fr);
    }

}

@media only screen  
  and (min-width : 650px)
  and (max-width : 700px)
  and (orientation : landscape){

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 43.6vw;
      height: 34.7vw;
    }

    .peopleDiv_peopleContainer__3meIE{
      margin: 2em 5vw;
    } 

    .peopleDiv_teamGrid__1l9mf{
     display: grid;
      grid-template-columns: repeat(2 , 1fr);
    }
}



@media only screen  
  and (min-width : 700px)
  and (max-width : 750px)
  and (orientation : landscape){

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 43.7vw;
      height: 36vw;
    }

    .peopleDiv_peopleContainer__3meIE{
      margin: 2em 5vw;
    } 

    .peopleDiv_teamGrid__1l9mf{
     display: grid;
      grid-template-columns: repeat(2 , 1fr);
    }

    .peopleDiv_peopleCard__3q3Zg p {
      margin-top: 10px;
    }
}

@media only screen  
  and (min-width : 750px)
  and (max-width : 799px)
  and (orientation : landscape){

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 44.1vw;
      height: 37.7vw;
    }

    .peopleDiv_teamGrid__1l9mf{
     display: grid;
      grid-template-columns: repeat(2 , 1fr);
    }
    
    .peopleDiv_peopleCard__3q3Zg p {
      margin-top: 10px;
    }
}

@media only screen  
  and (min-width : 800px)
  and (max-width : 890px)
  and (orientation : landscape){

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 44vw;
      height: 37.7vw;
    }

    .peopleDiv_peopleContainer__3meIE{
      margin: 2em 5vw;
    } 

    .peopleDiv_teamGrid__1l9mf{
     display: grid;
      grid-template-columns: repeat(2 , 1fr);
    }

    .peopleDiv_peopleCard__3q3Zg p {
      margin-top: 10px;
    }

}


@media only screen  
  and (min-width : 890px)
  and (max-width : 900px)
  and (orientation : landscape){
    
    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 44.2vw;
      height: 37.7vw;
    }

    .peopleDiv_peopleContainer__3meIE{
      margin: 2em 5vw;
    } 

    .peopleDiv_teamGrid__1l9mf{
     display: grid;
      grid-template-columns: repeat(2 , 1fr);
    }

    .peopleDiv_peopleCard__3q3Zg p {
      margin-top: 10px;
    }

}







/* Desktops and laptops ----------- */

@media only screen  
  and (min-width : 720px) 
  and (min-height: 600px){

    .peopleDiv_peopleContainer__3meIE{
        margin: 2em 5.3vw;
    } 

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 43.4vw;
      height: 30vw;
    }

    .peopleDiv_teamGrid__1l9mf{
      display: grid;
      grid-template-columns: repeat(2 , 1fr);
    }
  
}

@media only screen  
  and (min-width : 900px){
    
    .peopleDiv_peopleContainer__3meIE{
        margin : 2em 5vw;
    } 

    .peopleDiv_teamGrid__1l9mf{
     display: grid;
      grid-template-columns: repeat(2 , 1fr);
    }

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 44.2vw;
      height: 37.7vw;
    }
}

@media only screen and (min-width: 1000px) {
    .peopleDiv_peopleContainer__3meIE{
        margin : 2em 3.3vw;
    } 

    .peopleDiv_teamGrid__1l9mf{
      display: grid;
      grid-template-columns: repeat(3 , 1fr);
    }

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 29.8vw;
      height: 20vw;
    }

    .peopleDiv_peopleCard__3q3Zg p {
      margin-bottom: 1em;
    }

}



@media only screen and (min-width : 1280px) {
    .peopleDiv_peopleContainer__3meIE{
        margin : 2em 5vw;
    } 

    .peopleDiv_teamGrid__1l9mf{
     display: grid;
      grid-template-columns: repeat(4 , 1fr);
    }

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 22vw;
      height: 16vw;
    }
}



/*LCD Displays*/
@media only screen 
  and (min-width: 1024px) 
  and (max-width: 1080px)  
  and (min-height : 800px) 
  and (max-height : 900px) {
    .peopleDiv_peopleContainer__3meIE{
        margin : 2em 3.3vw;
    } 

    .peopleDiv_teamGrid__1l9mf{
     display: grid;
      grid-template-columns: repeat(4 , 1fr);
    }

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 22.9vw;
      height: 16vw;
    }
}


/* Basic Laptop Screens */
@media only screen and (min-width : 1440px) {
    .peopleDiv_peopleContainer__3meIE{
        margin : 2em 3.3vw;
    } 

    .peopleDiv_teamGrid__1l9mf{
     display: grid;
      grid-template-columns: repeat(4 , 1fr);
    }

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 22.3vw;
      height: 16vw;
    }

}

/* Larger laptop screens */
@media only screen  and (min-width : 1500px) {
    .peopleDiv_peopleContainer__3meIE{
        margin : 2em 3.3vw;
    } 

    .peopleDiv_teamGrid__1l9mf{
     display: grid;
      grid-template-columns: repeat(4 , 1fr);
    }

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 22.3vw;
      height: 16vw;
    }
}


/* Large screens ----------- */
@media only screen  and (min-width : 1600px) {
  
    .peopleDiv_peopleContainer__3meIE{
        margin : 2em 3.3vw;
    }   

    .peopleDiv_teamGrid__1l9mf{
     display: grid;
      grid-template-columns: repeat(4 , 1fr);
    }

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 22.4vw;
      height: 16.6vw;
    }
}

@media only screen  and (min-width : 1700px) {
    .peopleDiv_peopleContainer__3meIE{
        margin : 2em 3.3vw;
    }   

    .peopleDiv_teamGrid__1l9mf{
     display: grid;
      grid-template-columns: repeat(4 , 1fr);
    }

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 22.5vw;
      height: 16.6vw;
    }
}

@media only screen  and (min-width : 1900px) {
    .peopleDiv_peopleContainer__3meIE{
        margin : 2em 3.3vw;
    }  

    .peopleDiv_teamGrid__1l9mf{
     display: grid;
      grid-template-columns: repeat(4 , 1fr);
    }

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 22.5vw;
      height: 16.6vw;
    }

 
}


@media only screen  and (min-width : 2000px) {
    .peopleDiv_peopleContainer__3meIE{
        margin : 2em 3.3vw;
    }  

    .peopleDiv_teamGrid__1l9mf{
     display: grid;
      grid-template-columns: repeat(4 , 1fr);
    }

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 22.7vw;
      height: 16.6vw;
    }
  
}


/* Macbook Pro */
@media only screen 
  and (min-width: 1700px) 
  and (max-width: 1800px)  
  and (min-height : 1080px) 
  and (max-height : 1100px) {
    .peopleDiv_peopleContainer__3meIE{
        margin : 2em 3.3vw;
    } 

    .peopleDiv_teamGrid__1l9mf{
     display: grid;
      grid-template-columns: repeat(4 , 1fr);
    }

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 22.5vw;
      height: 16.6vw;
    }

}


/* Potrait Orientation for Tablets and Mobiles */

@media only screen 
  and (min-width: 600px)
  and (max-width: 720px)
  and (orientation : portrait)
  and (-webkit-min-device-pixel-ratio: 1){
    .peopleDiv_peopleContainer__3meIE{
        margin : 2em 5.3vw;
    } 

    .peopleDiv_teamGrid__1l9mf{
      display: grid;
      grid-template-columns: repeat(2 , 1fr);
    }

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 43.4vw;
      height: 36.4vw;
    }

}

@media only screen 
  and (min-width: 720px) 
  and (max-width: 769px) 
  and (orientation : portrait)
  and (-webkit-device-pixel-ratio: 1){
    .peopleDiv_peopleContainer__3meIE{
        margin : 2em 5.3vw;
    }

    .peopleDiv_teamGrid__1l9mf{
     display: grid;
      grid-template-columns: repeat(2 , 1fr);
    }

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 43.4vw;
      height: 30vw;
    }

}


@media only screen 
  and (min-width: 769px) 
  and (max-width: 850px)
  and (max-height : 1300px)
  and (orientation : portrait){
    .peopleDiv_peopleContainer__3meIE{
        margin : 2em 5.3vw;
    }

    .peopleDiv_teamGrid__1l9mf{
     display: grid;
      grid-template-columns: repeat(2 , 1fr);
    }

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 43.6vw;
      height: 32.5vw;
    }

}

@media only screen 
  and (min-width: 850px) 
  and (max-width: 899px) 
  and (orientation : portrait){
    .peopleDiv_peopleContainer__3meIE{
      margin : 2em 5.3vw;
  }

  .peopleDiv_teamGrid__1l9mf{
   display: grid;
  }

  .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
    width: 43.6vw;
    height: 32.5vw;
  }

}




@media only screen 
  and (min-width: 900px) 
  and (max-width: 959px) 
  and (orientation : portrait){
    .peopleDiv_peopleContainer__3meIE{
      margin : 2em 5.3vw;
  }

  .peopleDiv_teamGrid__1l9mf{
   display: grid;
    grid-template-columns: repeat(2 , 1fr);
  }

  .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
    width: 43.6vw;
    height: 32.5vw;
  }
}


/*Ipad Potrait*/
@media only screen 
and (min-width : 768px) 
and (max-width : 1024px)
and (max-height: 1024px) 
and (orientation : portrait) 
and (-webkit-min-device-pixel-ratio: 1)
{
    .peopleDiv_peopleContainer__3meIE{
        margin : 2em 5.3vw;
    }

    .peopleDiv_teamGrid__1l9mf{
     display: grid;
      grid-template-columns: repeat(2 , 1fr);
    }

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 43.6vw;
      height: 32.5vw;
    }

  

}

/* IPad Pro 10.5 Portrait */
@media only screen 
  and (min-width: 834px)
  and (max-width: 834px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1)
  {
    .peopleDiv_peopleContainer__3meIE{
        margin : 2em 5.3vw;
    }

    .peopleDiv_teamGrid__1l9mf{
      display: grid;
      grid-template-columns: repeat(2 , 1fr);
    }

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 43.6vw;
      height: 32.5vw;
    }

}

/* IPad Pro 12.5 Portrait */
@media only screen
and (min-width: 1024px)
and (max-width: 1024px)
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 1)
{
    .peopleDiv_peopleContainer__3meIE{
        margin : 2em 5.3vw;
    }

    .peopleDiv_teamGrid__1l9mf{
      display: grid;
      grid-template-columns: repeat(2 , 1fr);
    }

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 44vw;
      height: 32.5vw;
    }


}







/* Landscape Orientation for Tablets and Mobiles */


/* Tablets and IPads */




@media only screen 
  and (min-width: 960px) 
  and (max-width: 1023px) 
  and (orientation : landscape){
    .peopleDiv_peopleContainer__3meIE{
        margin : 2em 3.3vw;
    }

    .peopleDiv_teamGrid__1l9mf{
      display: grid;
      grid-template-columns: repeat(3 , 1fr);
    }

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 29.8vw;
      height: 20vw
    }
 
} 

@media only screen 
  and (min-width: 1023px) 
  and (max-width: 1179px) 
  and (orientation : landscape)
  and (-webkit-min-device-pixel-ratio: 2){

    .peopleDiv_peopleContainer__3meIE{
        margin : 2em 3.3vw;
    }

    .peopleDiv_teamGrid__1l9mf{
      display: grid;
      grid-template-columns: repeat(3 , 1fr);
    }

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 29.8vw;
      height: 20vw
    }
  
}


@media only screen
  and (min-width: 1180px)
  and (max-width: 1194px)
  and (orientation: landscape){

    .peopleDiv_peopleContainer__3meIE{
        margin : 2em 3.3vw;
    } 

    .peopleDiv_teamGrid__1l9mf{
      display: grid;
      grid-template-columns: repeat(3 , 1fr);
    }

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 29.8vw;
      height: 20vw
    }
}

@media only screen 
  and (min-width : 1280px) 
  and (min-height : 740px) 
  and (max-height : 750px) {

  .peopleDiv_peopleContainer__3meIE{
      margin : 2em 3.3vw;
  } 

  .peopleDiv_teamGrid__1l9mf{
    display: grid;
    grid-template-columns: repeat(2 , 1fr);
  }

  .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
    width: 29.8vw;
    height: 20vw
  }

}

/*Ipad Landscape*/
@media only screen 
and (min-height : 768px)
and (max-width : 1024px)
and (orientation : landscape)
and (-webkit-min-device-pixel-ratio: 1) {
  
  .peopleDiv_peopleContainer__3meIE{
      margin : 2em 3.3vw;
  }

  .peopleDiv_teamGrid__1l9mf{
    display: grid;
    grid-template-columns: repeat(3 , 1fr);
  }

  .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
    width: 29.8vw;
    height: 20vw
  }

 

}

/* IPad Pro 10.5 Landscape */
@media only screen 
  and (min-height: 834px)
  and (max-width: 1112px) 
  and (orientation: landscape){

    .peopleDiv_peopleContainer__3meIE{
        margin : 2em 3.3vw;
    }  

    .peopleDiv_teamGrid__1l9mf{
      display: grid;
      grid-template-columns: repeat(3 , 1fr);
    }

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 29.8vw;
      height: 20vw
    }
}

/* IPad Pro 12.5 Landscape */
@media only screen
  and (min-height: 1024px)
  and (max-height: 1024px)
  and (orientation: landscape){
    .peopleDiv_peopleContainer__3meIE{
        margin : 2em 3.3vw;
    }

    .peopleDiv_teamGrid__1l9mf{
      display: grid;
      grid-template-columns: repeat(3 , 1fr);
    }

    .peopleDiv_peopleCard__3q3Zg, .peopleDiv_imageContainer__3BQpk, .peopleDiv_card_overlay__XL9Yx, .peopleDiv_teamGrid__1l9mf a{
      width: 30.2vw;
      height: 23vw
    }
}




.aboutDiv_about_container__2bwnt{
  -ms-scroll-snap-type: y mandatory;
      scroll-snap-type: y mandatory;
  margin: 0 0 4em 0 !important;
  line-height: 2.1em;
}

.aboutDiv_mediaBox__2yAM5 img{
  height: 100% !important;
  width: 100%;
}

.aboutDiv_mediaBox__2yAM5,  .aboutDiv_mediaBox__2yAM5 > img {
  position: relative; 
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
  height: 35vw;
  width: 100% !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: overlay;
}

.aboutDiv_mediaBox__2yAM5::before{
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

}
.aboutDiv_mediaBox__2yAM5 > h1{
  position: relative;
  color: #ffffff;
  font-weight: bold;  
  font-size: 8em;
  line-height: 0.9;
  text-align: center;
}

.aboutDiv_left__1gi0x {
  color: rgb(0, 0, 0);
  text-align: left;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: 30px;
}
  
.aboutDiv_right__3ywXm {
  color: #808080;
  padding-left: 50%;
  text-align: right;
  margin-top: 30px;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

@media only screen and (max-width: 900px) {
  .aboutDiv_mediaBox__2yAM5{
    height: 60vw !important;
  }

}

/* Smartphone portrait */

@media only screen 
and (max-width:240px){


  .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
    font-size: 0.9rem;
    text-align: justify;
    line-height: 2em;
  }

    
  .aboutDiv_right__3ywXm {
    padding-left: 0%;
  }

}


@media only screen 
and (min-width : 240px)
and (max-width : 360px)
and (orientation : portrait){
  .aboutDiv_mediaBox__2yAM5,  .aboutDiv_mediaBox__2yAM5 > img {
      height: 35vw;
    }
    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 0.9rem;
      text-align: justify;
      line-height: 2em;
    }
  
    .aboutDiv_right__3ywXm {
      padding-left: 0%;
    }
}

@media only screen
and (min-width : 360px) 
and (max-width : 399px)
and (orientation : portrait){


  .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
    font-size: 0.9rem;
    text-align: justify;
    line-height: 2em;
  }

    
  .aboutDiv_right__3ywXm {
    padding-left: 0%;
  }
}


@media only screen
and (min-width : 400px) 
and (max-width : 428px)
and (orientation : portrait){

    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 0.9rem;
      text-align: justify;
      line-height: 2em;
    }
  
      
    .aboutDiv_right__3ywXm {
      padding-left: 0%;
    }
}

@media only screen
and (min-width : 428px) 
and (max-width : 500px)
and (orientation : portrait){

    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 0.9rem;
      text-align: justify;
      line-height: 2em;
    }
  
      
    .aboutDiv_right__3ywXm {
      padding-left: 0%;
    }
}

@media only screen
and (min-width : 500px) 
and (max-width : 600px)
and (orientation : portrait){


    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 0.9rem;
      text-align: justify;
      line-height: 2em;
    }
  
      
    .aboutDiv_right__3ywXm {
      padding-left: 0%;
    }
  
}

@media only screen 
and (min-width: 600px)
and (max-width : 800px)
and (orientation : portrait){


    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 0.9rem;
      text-align: justify;
      line-height: 2em;
    }
  
      
    .aboutDiv_right__3ywXm {
      padding-left: 0%;
    }

}



/* Smartphone Landscape */

@media only screen  
and (min-width : 320px)
and (max-width : 900px)
and (orientation : landscape){

  
    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
    }
}

@media only screen  
and (min-width : 320px)
and (max-width : 499px)
and (orientation : landscape){

  
    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
    }
}

@media only screen  
and (min-width : 500px)
and (max-width : 599px)
and (orientation : landscape){

    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
    }
}

@media only screen  
and (min-width : 600px)
and (max-width : 650px)
and (orientation : landscape){

  
    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
    }
}

@media only screen  
and (min-width : 650px)
and (max-width : 700px)
and (orientation : landscape){

  
    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
    }
}



@media only screen  
and (min-width : 700px)
and (max-width : 750px)
and (orientation : landscape){

  
    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
    }
}

@media only screen  
and (min-width : 750px)
and (max-width : 799px)
and (orientation : landscape){

  
    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
    }
}

@media only screen  
and (min-width : 800px)
and (max-width : 890px)
and (orientation : landscape){

  
    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
    }
}


@media only screen  
and (min-width : 890px)
and (max-width : 900px)
and (orientation : landscape){

  
    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
    }
}







/* Desktops and laptops ----------- */

@media only screen  
and (min-width : 720px) 
and (min-height: 600px){


  
    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
      text-align: left;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
      text-align: right;
    }

}

@media only screen  
and (min-width : 900px){
  

  
    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
    }
} 



@media only screen and (min-width: 1000px) {

  
    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
    }

}



@media only screen and (min-width : 1280px) {

  
    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
    } 

}



/*LCD Displays*/
@media only screen 
and (min-width: 1024px) 
and (max-width: 1080px)  
and (min-height : 800px) 
and (max-height : 900px) {

  
    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
    }

}


/* Basic Laptop Screens */
@media only screen and (min-width : 1440px) {

  
    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
    }

}

/* Larger laptop screens */
@media only screen  and (min-width : 1500px) {

  
    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
    } 
}


/* Large screens ----------- */
@media only screen  and (min-width : 1600px) {


  
    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
    }   
}

@media only screen  and (min-width : 1700px) {

  
    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
    }  
}

@media only screen  and (min-width : 1900px) {

  
    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
    } 

}


@media only screen  and (min-width : 2000px) {

  
    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
    }

}


/* Macbook Pro */
@media only screen 
and (min-width: 1700px) 
and (max-width: 1800px)  
and (min-height : 1080px) 
and (max-height : 1100px) {

  
    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
    }
}


/* Potrait Orientation for Tablets and Mobiles */

@media only screen 
and (min-width: 600px)
and (max-width: 720px)
and (orientation : portrait)
and (-webkit-min-device-pixel-ratio: 1){

  
    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
      text-align: left;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
      text-align: right;
    }
}

@media only screen 
and (min-width: 720px) 
and (max-width: 769px) 
and (orientation : portrait)
and (-webkit-device-pixel-ratio: 1){

  
    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
      text-align: left;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
      text-align: right;
    }
}


@media only screen 
and (min-width: 769px) 
and (max-width: 850px)
and (max-height : 1300px)
and (orientation : portrait){

  
    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
      text-align: left;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
      text-align: right;
    }
}

@media only screen 
and (min-width: 850px) 
and (max-width: 899px) 
and (orientation : portrait){

  
    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 1rem;
      line-height: 2em;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
      text-align: left;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
      text-align: right;
    }
}




@media only screen 
and (min-width: 900px) 
and (max-width: 959px) 
and (orientation : portrait){
  .aboutDiv_mediaBox__2yAM5,  .aboutDiv_mediaBox__2yAM5 > img {
      height: 25vh;
    }
  
    .aboutDiv_left__1gi0x, .aboutDiv_right__3ywXm{
      line-height: 1.8em;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
      text-align: left;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
      text-align: right;
    }
}


/*Ipad Potrait*/
@media only screen 
and (min-width : 768px) 
and (max-width : 1024px)
and (max-height: 1024px) 
and (orientation : portrait) 
and (-webkit-min-device-pixel-ratio: 1)
{
  .aboutDiv_mediaBox__2yAM5,  .aboutDiv_mediaBox__2yAM5 > img {
      height: 25vh;
    }
  
    .aboutDiv_left__1gi0x, .aboutDiv_right__3ywXm{
      line-height: 1.8em;
    }

    .aboutDiv_left__1gi0x {
      width: 70%;
      text-align: left;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
      text-align: right;
    }


}

/* IPad Pro 10.5 Portrait */
@media only screen 
and (min-width: 834px)
and (max-width: 834px) 
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 1){
    
  .aboutDiv_mediaBox__2yAM5,  .aboutDiv_mediaBox__2yAM5 > img {
      height: 25vh;
    }
  
    .aboutDiv_left__1gi0x, .aboutDiv_right__3ywXm{
      line-height: 1.8em;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
      text-align: left;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
      text-align: right;
    }
}

/* IPad Pro 12.5 Portrait */
@media only screen
and (min-width: 1024px)
and (max-width: 1024px)
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 1)
{
  .aboutDiv_mediaBox__2yAM5,  .aboutDiv_mediaBox__2yAM5 > img {
      height: 25vh;
    }
  
    .aboutDiv_left__1gi0x, .aboutDiv_right__3ywXm{
      line-height: 1.8em;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
      text-align: left;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
      text-align: right;
    }

}







/* Landscape Orientation for Tablets and Mobiles */


/* Tablets and IPads */




@media only screen 
and (min-width: 960px) 
and (max-width: 1023px) 
and (orientation : landscape){
  .aboutDiv_mediaBox__2yAM5,  .aboutDiv_mediaBox__2yAM5 > img {
      height: 45vh;
    }
  
    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 1.1rem;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
    }
} 

@media only screen 
and (min-width: 1023px) 
and (max-width: 1179px) 
and (orientation : landscape)
and (-webkit-min-device-pixel-ratio: 2){

  .aboutDiv_mediaBox__2yAM5,  .aboutDiv_mediaBox__2yAM5 > img {
      height: 45vh;
    }
  
    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 1.1rem;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
    }

}


@media only screen
and (min-width: 1180px)
and (max-width: 1194px)
and (orientation: landscape){
  .aboutDiv_mediaBox__2yAM5,  .aboutDiv_mediaBox__2yAM5 > img {
      height: 45vh;
    }
  
    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 1.1rem;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
    }
}

@media only screen 
and (min-width : 1280px) 
and (min-height : 740px) 
and (max-height : 750px) {
  .aboutDiv_mediaBox__2yAM5,  .aboutDiv_mediaBox__2yAM5 > img {
      height: 45vh;
    }
  
    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 1.1rem;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
    }
}

/*Ipad Landscape*/
@media only screen 
and (min-height : 768px)
and (max-width : 1024px)
and (orientation : landscape)
and (-webkit-min-device-pixel-ratio: 1) {
  .aboutDiv_mediaBox__2yAM5,  .aboutDiv_mediaBox__2yAM5 > img {
      height: 45vh;
    }
  
    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 1.1rem;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
    }


}

/* IPad Pro 10.5 Landscape */
@media only screen 
and (min-height: 834px)
and (max-width: 1112px) 
and (orientation: landscape){

  .aboutDiv_mediaBox__2yAM5,  .aboutDiv_mediaBox__2yAM5 > img {
      height: 45vh;
    }
  
    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 1.1rem;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
    }
}

/* IPad Pro 12.5 Landscape */
@media only screen
and (min-height: 1024px)
and (max-height: 1024px)
and (orientation: landscape){
  .aboutDiv_mediaBox__2yAM5,  .aboutDiv_mediaBox__2yAM5 > img {
      height: 45vh;
    }
  
    .aboutDiv_left__1gi0x , .aboutDiv_right__3ywXm{
      font-size: 1.1rem;
    }
  
    .aboutDiv_left__1gi0x {
      width: 70%;
    }
      
    .aboutDiv_right__3ywXm {
      padding-left: 30%;
    }
}




.contactPage_contactPage__3TRQp {
  font-family: "Roboto", sans-serif;
}

.contactPage_map__11Uxn {
  float: left;
  position: relative;
  
}

.contactPage_mapImage__akoyQ{
  height: 25vw;
  cursor: default;
  background-repeat: no-repeat;
  background-size: cover;
  
}

.contactPage_mapMarker__14m2N {
  cursor: pointer;
  color: red;
  position: absolute;
  top: 40%;
  left: 54%;
}

.contactPage_mapMarker__14m2N:hover {
  top: 40%;
  color: darkred;
}

.contactPage_mediaBox__JhHDF {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 10vh 2vh 4vh 0; */
  height: 35vw;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: overlay;
}

.contactPage_mediaBox__JhHDF::before{
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.contactPage_mediaBox__JhHDF > h1{
  position: relative;
  color: #ffffff;
  font-weight: bold;  
  font-size: 8em;
  line-height: 0.9;
  text-align: center;
}

.contactPage_contactPage__3TRQp > span{
  margin-top: 2em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}

.contactPage_info__ZPMuA {
  display: flex;
  flex-direction: column;
  text-align: right;
  color: #a4a4a4;
  letter-spacing: 2px;
  font-size: 1em;
}




.contactPage_info__ZPMuA a {
  color: #646464;
}

.contactPage_info__ZPMuA a:hover {
  text-decoration: none;
  color: #646464;
}

.contactPage_info__ZPMuA img {
  height: 23px;
  align-self: flex-end;
  float: right;
  /* margin-left: 6.5em; */
  width: 111.8px;
  /* margin-top: -25px; */
  margin-bottom: 10px;
  margin-top: 8px;
}

.contactPage_info__ZPMuA > p{
  margin-bottom: 10px;
  font-weight: 400;
  color: rgb(82, 86, 89);
}

.contactPage_dGrey__1jyW6 {
  color: #646464;
}

.contactPage_contactNumbers__2iisM{
  margin-top: 1em;
  display: grid;
  grid-row-gap: 30px;
  row-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: repeat(auto-fill , 1fr);
}

.contactPage_contactInfo__2Tyd7{
  text-align: right;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
  flex: auto;
}

/* .contactNumbers > .contactInfo{
  text-align: right;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
  flex: auto;
} */

.contactPage_contactInfo__2Tyd7 h1{
  font-size: 20px !important;
  font-weight: 200;
}

.contactPage_contactInfo__2Tyd7 h3 > a, .contactPage_contactInfo__2Tyd7 .contactPage_mobile__3ZMa9{
  margin: 0 !important;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px !important;
  color: rgb(82, 86, 89);
  font-weight: 400 !important;
}



@media only screen and (max-width: 900px)  and (orientation : portrait)  {
  .contactPage_info__ZPMuA{
    /* margin-top: -60px; */
    margin-top: -100px;
  }

  .contactPage_contactInfo__2Tyd7{
    /* margin-top: 60px; */
  }

  .contactPage_mapImage__akoyQ{
    /* margin-bottom: 70px !important; */
    
  }

  .contactPage_map__11Uxn{
    height: 0;
  }
}


/* Smartphone portrait */

@media only screen 
  and (max-width:240px){

  .contactPage_contactPage__3TRQp{
      margin: 2em 9vw;
  }

  .contactPage_contactNumbers__2iisM{
    grid-row-gap: 30px;
    row-gap: 30px;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(auto-fill , 1fr);
  }

  .contactPage_contactPage__3TRQp > span{
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .contactPage_mapImage__akoyQ{
    height: 45vw;
    margin-bottom: 50px;
  }

}


@media only screen 
  and (min-width : 240px)
  and (max-width : 360px)
  and (orientation : portrait){

  .contactPage_contactPage__3TRQp > span{
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .contactPage_contactPage__3TRQp{
      margin: 2em 8vw;
  }
  .contactPage_contactNumbers__2iisM{
    grid-row-gap: 30px;
    row-gap: 30px;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(auto-fill , 1fr);
  }
  .contactPage_mapImage__akoyQ{
    height: 45vw;
    margin-bottom: 50px;
  }

  .contactPage_contactInfo__2Tyd7 h1{
    font-size: 20px !important;
    margin-bottom: 0.1em;
  }

  .contactPage_contactInfo__2Tyd7 h3 > a, .contactPage_contactInfo__2Tyd7 .contactPage_mobile__3ZMa9{
    font-size: 12px !important;
  }


}

@media only screen
  and (min-width : 360px) 
  and (max-width : 399px)
  and (orientation : portrait){
    .contactPage_contactPage__3TRQp > span{
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    }
    .contactPage_contactPage__3TRQp{
        margin: 2em 8vw;
    } 
    .contactPage_contactNumbers__2iisM{
      grid-row-gap: 30px;
      row-gap: 30px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(auto-fill , 1fr);
    }
    .contactPage_mapImage__akoyQ{
      height: 45vw;
      margin-bottom: 50px;
    }
    
}


@media only screen
  and (min-width : 400px) 
  and (max-width : 428px)
  and (orientation : portrait){
    .contactPage_contactPage__3TRQp{
        margin: 2em 7.5vw;
    } 
    .contactPage_contactNumbers__2iisM{
      grid-row-gap: 30px;
      row-gap: 30px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(auto-fill , 1fr);
    }
    .contactPage_contactPage__3TRQp > span{
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    }  
    .contactPage_mapImage__akoyQ{
      height: 45vw;
      margin-bottom: 50px;
    }
}

@media only screen
  and (min-width : 428px) 
  and (max-width : 500px)
  and (orientation : portrait){
    .contactPage_contactPage__3TRQp{
        margin: 2em 9vw;
    } 
    .contactPage_contactNumbers__2iisM{
      grid-row-gap: 30px;
      row-gap: 30px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(auto-fill , 1fr);
    }
    .contactPage_contactPage__3TRQp > span{
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    }    
    .contactPage_mapImage__akoyQ{
      height: 45vw;
      margin-bottom: 50px;
    } 
}

@media only screen
  and (min-width : 500px) 
  and (max-width : 600px)
  and (orientation : portrait){

    .contactPage_contactPage__3TRQp{
        margin: 2em 9vw;
    } 
    
    .contactPage_contactNumbers__2iisM{
      grid-row-gap: 30px;
      row-gap: 30px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(auto-fill , 1fr);
    }
    .contactPage_contactPage__3TRQp > span{
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    } 
    .contactPage_mapImage__akoyQ{
      height: 45vw;
      margin-bottom: 50px;
    }
}

@media only screen 
  and (min-width: 600px)
  and (max-width : 800px)
  and (orientation : portrait){

    .contactPage_contactPage__3TRQp{
        margin: 2em 5.3vw;
    } 
    
    .contactPage_contactNumbers__2iisM{
      grid-row-gap: 30px;
      row-gap: 30px;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: repeat(auto-fill , 1fr);
    }
    .contactPage_contactPage__3TRQp > span{
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    } 
    .contactPage_mapImage__akoyQ{
      height: 45vw;
      margin-bottom: 50px;
    }
}



/* Smartphone Landscape */

@media only screen  
  and (min-width : 320px)
  and (max-width : 900px)
  and (orientation : landscape){
  .contactPage_contactPage__3TRQp{
      margin : 2em 4.9vw;
  } 

  
  .contactPage_contactNumbers__2iisM{
    grid-row-gap: 30px;
    row-gap: 30px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(auto-fill , 1fr);
  }
  .contactPage_contactPage__3TRQp > span{
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  } 
  .contactPage_mapImage__akoyQ{
    height: 45vw;
    margin-bottom: 50px;
  }

}

@media only screen  
  and (min-width : 320px)
  and (max-width : 499px)
  and (orientation : landscape){

}

@media only screen  
  and (min-width : 500px)
  and (max-width : 599px)
  and (orientation : landscape){

 
}

@media only screen  
  and (min-width : 600px)
  and (max-width : 650px)
  and (orientation : landscape){
 
}

@media only screen  
  and (min-width : 650px)
  and (max-width : 700px)
  and (orientation : landscape){
 
}



@media only screen  
  and (min-width : 700px)
  and (max-width : 750px)
  and (orientation : landscape){
 
}

@media only screen  
  and (min-width : 750px)
  and (max-width : 799px)
  and (orientation : landscape){
 
}

@media only screen  
  and (min-width : 800px)
  and (max-width : 890px)
  and (orientation : landscape){
 
}


@media only screen  
  and (min-width : 890px)
  and (max-width : 900px)
  and (orientation : landscape){
  
}







/* Desktops and laptops ----------- */

@media only screen  
  and (min-width : 720px) 
  and (min-height: 600px){

    .contactPage_contactPage__3TRQp{
        margin: 2em 5.3vw;
    } 
  
}

@media only screen  
  and (min-width : 900px){
    
    .contactPage_contactPage__3TRQp{
        margin : 2em 5vw;
    } 
}

@media only screen and (min-width: 1000px) {
    .contactPage_contactPage__3TRQp{
        margin : 2em 3.3vw;
    } 
}



@media only screen and (min-width : 1280px) {
    .contactPage_contactPage__3TRQp{
      margin: 2em 3.3vw;
    } 
}



/*LCD Displays*/
@media only screen 
  and (min-width: 1024px) 
  and (max-width: 1080px)  
  and (min-height : 800px) 
  and (max-height : 900px) {
    .contactPage_contactPage__3TRQp{
        margin : 2em 3.3vw;
    } 

    
}


/* Basic Laptop Screens */
@media only screen and (min-width : 1440px) {
    .contactPage_contactPage__3TRQp{
        margin : 2em 3.3vw;
    } 

}

/* Larger laptop screens */
@media only screen  and (min-width : 1500px) {
    .contactPage_contactPage__3TRQp{
        margin : 2em 3.3vw;
    } 
}


/* Large screens ----------- */
@media only screen  and (min-width : 1600px) {
  
    .contactPage_contactPage__3TRQp{
        margin : 2em 3.3vw;
    }   
}

@media only screen  and (min-width : 1700px) {
    .contactPage_contactPage__3TRQp{
        margin : 2em 3.3vw;
    }   
}

@media only screen  and (min-width : 1900px) {
    .contactPage_contactPage__3TRQp{
        margin : 2em 3.3vw;
    }  
 
}


@media only screen  and (min-width : 2000px) {
    .contactPage_contactPage__3TRQp{
        margin : 2em 3.3vw;
    }  
  
}


/* Macbook Pro */
@media only screen 
  and (min-width: 1700px) 
  and (max-width: 1800px)  
  and (min-height : 1080px) 
  and (max-height : 1100px) {
    .contactPage_contactPage__3TRQp{
        margin : 2em 3.3vw;
    } 
}


/* Potrait Orientation for Tablets and Mobiles */

@media only screen 
  and (min-width: 600px)
  and (max-width: 720px)
  and (orientation : portrait)
  and (-webkit-min-device-pixel-ratio: 1){
    .contactPage_contactPage__3TRQp{
        margin : 2em 5.3vw;
    } 

    .contactPage_contactNumbers__2iisM{
      grid-row-gap: 30px;
      row-gap: 30px;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: repeat(auto-fill , 1fr);
    }
    .contactPage_contactPage__3TRQp > span{
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    } 
    .contactPage_mapImage__akoyQ{
      height: 45vw;
      margin-bottom: 50px;
    }
}

@media only screen 
  and (min-width: 720px) 
  and (max-width: 769px) 
  and (orientation : portrait)
  and (-webkit-device-pixel-ratio: 1){
    .contactPage_contactPage__3TRQp{
        margin : 2em 5.3vw;
    }
    .contactPage_contactNumbers__2iisM{
      grid-row-gap: 30px;
      row-gap: 30px;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: repeat(auto-fill , 1fr);
    }
    .contactPage_contactPage__3TRQp > span{
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    } 
    .contactPage_mapImage__akoyQ{
      height: 45vw;
      margin-bottom: 50px;
    }
}


@media only screen 
  and (min-width: 769px) 
  and (max-width: 850px)
  and (max-height : 1300px)
  and (orientation : portrait){
    .contactPage_contactPage__3TRQp{
        margin : 2em 5.3vw;
    }
    .contactPage_contactNumbers__2iisM{
      grid-row-gap: 30px;
      row-gap: 30px;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: repeat(auto-fill , 1fr);
    }
    .contactPage_contactPage__3TRQp > span{
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    } 
    .contactPage_mapImage__akoyQ{
      height: 45vw;
      margin-bottom: 50px;
    }
}

@media only screen 
  and (min-width: 850px) 
  and (max-width: 899px) 
  and (orientation : portrait){
    .contactPage_contactPage__3TRQp{
        margin : 2em 5.3vw;
    } 
    .contactPage_contactNumbers__2iisM{
      grid-row-gap: 30px;
      row-gap: 30px;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: repeat(auto-fill , 1fr);
    }
    .contactPage_contactPage__3TRQp > span{
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    } 
    .contactPage_mapImage__akoyQ{
      height: 45vw;
      margin-bottom: 50px;
    }
}




@media only screen 
  and (min-width: 900px) 
  and (max-width: 959px) 
  and (orientation : portrait){
    .contactPage_contactPage__3TRQp{
        margin : 2em 5.3vw;
    }
    .contactPage_contactNumbers__2iisM{
      grid-row-gap: 30px;
      row-gap: 30px;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: repeat(auto-fill , 1fr);
    }
    .contactPage_contactPage__3TRQp > span{
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    } 
    .contactPage_mapImage__akoyQ{
      height: 45vw;
      margin-bottom: 50px;
    }
}


/*Ipad Potrait*/
@media only screen 
and (min-width : 768px) 
and (max-width : 1024px)
and (max-height: 1024px) 
and (orientation : portrait) 
and (-webkit-min-device-pixel-ratio: 1)
{
    .contactPage_contactPage__3TRQp{
        margin : 2em 5.3vw;
    }
  
    .contactPage_contactNumbers__2iisM{
      grid-row-gap: 30px;
      row-gap: 30px;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: repeat(auto-fill , 1fr);
    }
    .contactPage_contactPage__3TRQp > span{
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    } 
    .contactPage_mapImage__akoyQ{
      height: 45vw;
      margin-bottom: 50px;
    }

}

/* IPad Pro 10.5 Portrait */
@media only screen 
  and (min-width: 834px)
  and (max-width: 834px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1)
  {
    .contactPage_contactPage__3TRQp{
        margin : 2em 5.3vw;
    }

    .contactPage_contactNumbers__2iisM{
      grid-row-gap: 30px;
      row-gap: 30px;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: repeat(auto-fill , 1fr);
    }
    .contactPage_contactPage__3TRQp > span{
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    } 
    .contactPage_mapImage__akoyQ{
      height: 45vw;
      margin-bottom: 50px;
    }
}

/* IPad Pro 12.5 Portrait */
@media only screen
and (min-width: 1024px)
and (max-width: 1024px)
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 1)
{
    .contactPage_contactPage__3TRQp{
        margin : 2em 5.3vw;
    }

    .contactPage_contactNumbers__2iisM{
      grid-row-gap: 30px;
      row-gap: 30px;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: repeat(auto-fill , 1fr);
    }
    .contactPage_contactPage__3TRQp > span{
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 0.8fr;
    } 
    .contactPage_mapImage__akoyQ{
      height: 45vw;
      margin-bottom: 50px;
    }

}







/* Landscape Orientation for Tablets and Mobiles */


/* Tablets and IPads */




@media only screen 
  and (min-width: 960px) 
  and (max-width: 1023px) 
  and (orientation : landscape){
    .contactPage_contactPage__3TRQp{
        margin : 2em 3.3vw;
    }
 
} 

@media only screen 
  and (min-width: 1023px) 
  and (max-width: 1179px) 
  and (orientation : landscape)
  and (-webkit-min-device-pixel-ratio: 2){

    .contactPage_contactPage__3TRQp{
        margin : 2em 3.3vw;
    }
  
}


@media only screen
  and (min-width: 1180px)
  and (max-width: 1194px)
  and (orientation: landscape){
    .contactPage_contactPage__3TRQp{
        margin : 2em 3.3vw;
    } 
}

@media only screen 
  and (min-width : 1280px) 
  and (min-height : 740px) 
  and (max-height : 750px) {
    .contactPage_contactPage__3TRQp{
        margin : 2em 3.3vw;
    } 

}

/*Ipad Landscape*/
@media only screen 
and (min-height : 768px)
and (max-width : 1024px)
and (orientation : landscape)
and (-webkit-min-device-pixel-ratio: 1) {
    .contactPage_contactPage__3TRQp{
        margin : 2em 3.3vw;
    }
    

}

/* IPad Pro 10.5 Landscape */
@media only screen 
  and (min-height: 834px)
  and (max-width: 1112px) 
  and (orientation: landscape){

    .contactPage_contactPage__3TRQp{
        margin : 2em 3.3vw;
    }  
}

/* IPad Pro 12.5 Landscape */
@media only screen
  and (min-height: 1024px)
  and (max-height: 1024px)
  and (orientation: landscape){
    .contactPage_contactPage__3TRQp{
        margin : 2em 3.3vw;
    }
}




.contactDiv_contactPage__2kgdH {
  width: 100%;
  padding: 0 4vw;
  font-family: "Roboto", sans-serif;
  
}

.contactDiv_map__1hfYB {
  margin: 4vh 0vh;
  margin-left: -0.65rem;
  float: left;
  position: relative;
  
}

.contactDiv_map__1hfYB img {
  height: 46.5vh;
  width: 53.5vw;
  cursor: default;
  margin-bottom: 10vh;
}

.contactDiv_mapMarker__2-kF1 {
  cursor: pointer;
  color: red;
  position: absolute;
  top: 40%;
  left: 54%;
}

.contactDiv_mapMarker__2-kF1:hover {
  top: 40%;
  color: darkred;
}

.contactDiv_mediaBox__1Cmfa img {
  width: 100% !important;
  height: 100%;
}

.contactDiv_mediaBox__1Cmfa {
  position: relative; 
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10vh 2vh 4vh 0;
  height: 65vh;
  width: 96vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: overlay;
    /* overflow: hidden;
    text-align: center;
    margin: 0 auto; */
}
.contactDiv_mediaBox__1Cmfa::before{
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0,0,0,0.25);

}
.contactDiv_mediaBox__1Cmfa > h1{
  position: relative;
  color: #ffffff;
  font-weight: bold;  
  font-size: 8em;
  line-height: 0.9;
  text-align: center;
}

.contactDiv_info__1Kj9V {
  float: right;
  height: 45vh;
  margin: 3vh 0 0;
  display: flex;
  margin-left: 20em !important  ;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
  color: #a4a4a4;
  letter-spacing: 2px;
  font-size: 1em;
}

.contactDiv_dGrey__2HUHa {
  padding-bottom: 10%;
  width: 100%;
  margin-right: 30%;
}
.contactDiv_infoBox1__27X9P {
  margin-left: 25%;
}

.contactDiv_info__1Kj9V a {
  color: #646464;
}

.contactDiv_info__1Kj9V a:hover {
  text-decoration: none;
  color: #646464;
}

.contactDiv_info__1Kj9V img {
  height: 15px;
  float: right;
}

.contactDiv_dGrey__2HUHa {
  color: #646464;
}

.contactDiv_infoBox2__3JLhB p {
  margin: 10px 0;
}


/* Desktops and laptops ----------- */
@media only screen and (min-width : 1280px) {

  .contactDiv_contactPage__2kgdH{
    margin: 2em 1.5em;
    padding: 0 0 0 0;
  }

  .contactDiv_contactPage__2kgdH > span{
    display: flex;
    flex-direction: row;
  }
  
  .contactDiv_map__1hfYB{
    margin: 0;
  }

  .contactDiv_info__1Kj9V{
    margin: 0;
    margin-left: 19.5em !important;
  }

  .contactDiv_infoBox1__27X9P > img{
    height: 25px;
  }

  .contactDiv_infoBox2__3JLhB{
    margin-top: 5em;
  }

}

@media only screen 
  and (min-width : 1280px) 
  and (min-height : 740px) 
  and (max-height : 800px) {


    
}

/* Basic Laptop Screens */
@media only screen and (min-width : 1440px) {

  .contactDiv_contactPage__2kgdH{
    margin: 2em 1.5em;
    padding: 0 0 0 0;
  }

  .contactDiv_contactPage__2kgdH > span{
    display: flex;
    flex-direction: row;
  }
  
  .contactDiv_map__1hfYB{
    margin: 0;
  }

  .contactDiv_info__1Kj9V{
    margin: 0;
    margin-left: 23.5em !important;
  }

  .contactDiv_infoBox1__27X9P > img{
    height: 25px;
  }

  .contactDiv_infoBox2__3JLhB{
    margin-top: 5em;
  }

}

/* Larger laptop screens */
@media only screen  and (min-width : 1500px) {
  .contactDiv_contactPage__2kgdH{
    margin: 2em 1.5em;
    padding: 0 0 0 0;
  }

  .contactDiv_contactPage__2kgdH > span{
    display: flex;
    flex-direction: row;
  }
  
  .contactDiv_map__1hfYB{
    margin: 0;
  }
  .contactDiv_map__1hfYB > img{
    height: 55vh;
  }

  .contactDiv_info__1Kj9V{
    margin: 0;
    margin-left: 26.5em !important;
  }

  .contactDiv_infoBox1__27X9P > img{
    height: 25px;
  }

  .contactDiv_infoBox2__3JLhB{
    margin-top: 8em;
  }
}


/*LCD Displays*/
@media only screen 
  and (min-width: 1024px) 
  and (max-width: 1080px)  
  and (min-height : 800px) 
  and (max-height : 900px) {

    .contactDiv_contactPage__2kgdH{
      margin: 2em 1.5em;
      padding: 0 0 0 0;
    }
  
    .contactDiv_contactPage__2kgdH > span{
      display: flex;
      flex-direction: row;
    }
    
    .contactDiv_map__1hfYB{
      margin: 0;
    }
  
    .contactDiv_map__1hfYB > img {
      height: 50vh;
      width: 60vw;
    }

    .contactDiv_info__1Kj9V{
      margin: 0;
      margin-left: 8.5em !important;
    }
  
    .contactDiv_infoBox1__27X9P > img{
      height: 40px;
    }
  
    .contactDiv_infoBox2__3JLhB{
      margin-top: 5em;
    }

}

/* Large screens ----------- */
@media only screen  and (min-width : 100em) {

  .contactDiv_contactPage__2kgdH{
    margin: 2em 1.5em;
    padding: 0 0 0 0;
  }

  .contactDiv_contactPage__2kgdH > span{
    display: flex;
    flex-direction: row;
  }
  
  .contactDiv_map__1hfYB{
    margin: 0;
  }

  .contactDiv_info__1Kj9V{
    margin: 0;
    margin-left: 36.5em !important;
  }

  .contactDiv_infoBox2__3JLhB > img{
    height: 40px;
    margin-bottom: 3em;
  }

  .contactDiv_infoBox2__3JLhB{
    margin-top: 13em;
  }

}

/* Macbook Pro */
@media only screen 
  and (min-width: 1700px) 
  and (max-width: 1800px)  
  and (min-height : 1080px) 
  and (max-height : 1100px) {

    .contactDiv_contactPage__2kgdH{
      margin: 2em 1.5em;
      padding: 0 0 0 0;
    }
  
    .contactDiv_contactPage__2kgdH > span{
      display: flex;
      flex-direction: row;
    }
    
    .contactDiv_map__1hfYB{
      margin: 0;
    }

    .contactDiv_map__1hfYB > img{
      height: 45vh;
    }
  
    .contactDiv_info__1Kj9V{
      margin: 0;
      margin-left: 31.5em !important;
    }
  
    .contactDiv_infoBox1__27X9P > img{
      height: 25px;
    }
  
    .contactDiv_infoBox2__3JLhB{
      margin-top: 5em;
    }

}

/* Landscape Orientation for Tablets and Mobiles */


/* Tablets and IPads */


@media only screen 
  and (min-width: 960px) 
  and (max-width: 1023px) 
  and (orientation : landscape){

    .contactDiv_contactPage__2kgdH{
      margin: 2em 1.5em;
      padding: 0 0 0 0;
    }
  
    .contactDiv_contactPage__2kgdH > span{
      display: flex;
      flex-direction: row;
    }
    
    .contactDiv_map__1hfYB{
      margin: 0;
    }
    
    .contactDiv_map__1hfYB > img{
      height: 64.5vh;
      width: 59.5vw;
    }


    .contactDiv_info__1Kj9V{
      margin: 0;
      margin-left: 7.5em !important;
    }
  
    .contactDiv_infoBox1__27X9P > img{
      height: 25px;
    }
  
    .contactDiv_infoBox2__3JLhB{
      margin-top: 5em;
    }  
}

@media only screen 
  and (min-width: 1179px) 
  and (max-width: 1023px) 
  and (orientation : landscape){

    .contactDiv_contactPage__2kgdH{
      margin: 2em 1.5em;
      padding: 0 0 0 0;
    }
  
    .contactDiv_contactPage__2kgdH > span{
      display: flex;
      flex-direction: row;
    }
    
    .contactDiv_map__1hfYB{
      margin: 0;
    }
  
    .contactDiv_info__1Kj9V{
      margin: 0;
      margin-left: 19.5em !important;
    }
  
    .contactDiv_infoBox1__27X9P > img{
      height: 25px;
    }
  
    .contactDiv_infoBox2__3JLhB{
      margin-top: 5em;
    }
}

/*Ipad Landscape*/
@media only screen 
  and (min-height : 1024px)
  and (max-height : 1024px) 
  and (orientation : landscape) {
  .contactDiv_contactPage__2kgdH{
    margin: 2em 1.5em;
    padding: 0 0 0 0;
  }

  .contactDiv_contactPage__2kgdH > span{
    display: flex;
    flex-direction: row;
  }
  
  .contactDiv_map__1hfYB{
    margin: 0;
  }

  .contactDiv_map__1hfYB > img{
    height: 50vh;
    width: 55vw;
  }

  .contactDiv_info__1Kj9V{
    margin: 0;
    margin-left: 11.5em !important;
  }

  .contactDiv_infoBox1__27X9P > img{
    height: 25px;
  }

  .contactDiv_infoBox2__3JLhB{
    margin-top: 5em;
  }
}

/* IPad Pro 10.5 Landscape */
@media only screen 
  and (min-width: 834px)
  and (max-width: 834px) 
  and (orientation: landscape) {
  
    .contactDiv_contactPage__2kgdH{
      margin: 2em 1.5em;
      padding: 0 0 0 0;
    }
  
    .contactDiv_contactPage__2kgdH > span{
      display: flex;
      flex-direction: row;
    }
    
    .contactDiv_map__1hfYB{
      margin: 0;
    }
  
    .contactDiv_info__1Kj9V{
      margin: 0;
      margin-left: 15em !important;
    }
  
    .contactDiv_infoBox1__27X9P > img{
      height: 25px;
    }
  
    .contactDiv_infoBox2__3JLhB{
      margin-top: 5em;
    }
}

/* IPad Pro 12.5 Landscape */
@media only screen
and (min-width: 1024px)
and (max-width: 1024px)
and (min-height : 1366px)
and (orientation: landscape){
  
  .contactDiv_contactPage__2kgdH{
    margin: 2em 1.5em;
    padding: 0 0 0 0;
  }

  .contactDiv_contactPage__2kgdH > span{
    display: flex;
    flex-direction: row;
  }
  
  .contactDiv_map__1hfYB{
    margin: 0;
  }

  .contactDiv_info__1Kj9V{
    margin: 0;
    margin-left: 20.5em !important;
  }

  .contactDiv_infoBox1__27X9P > img{
    height: 25px;
  }

  .contactDiv_infoBox2__3JLhB{
    margin-top: 6em;
  }
}

@media only screen
and (min-width: 1366px)
and (max-width: 1366px)
and (min-height : 1024px)
  and (orientation: landscape){

  .contactDiv_contactPage__2kgdH{
    margin: 2em 1.5em;
    padding: 0 0 0 0;
  }

  .contactDiv_contactPage__2kgdH > span{
    display: flex;
    flex-direction: row;
  }
  
  .contactDiv_map__1hfYB{
    margin: 0;
  }

  .contactDiv_info__1Kj9V{
    margin: 0;
    margin-left: 20.5em !important;
  }

  .contactDiv_infoBox1__27X9P > img{
    height: 25px;
  }

  .contactDiv_infoBox2__3JLhB{
    margin-top: 5em;
  }
}

@media only screen
  and (min-width: 1112px)
  and (max-width: 1112px)
  and (orientation: landscape){
    
  .contactDiv_contactPage__2kgdH{
    margin: 2em 1.5em;
    padding: 0 0 0 0;
  }

  .contactDiv_contactPage__2kgdH > span{
    display: flex;
    flex-direction: row;
  }
  
  .contactDiv_map__1hfYB{
    margin: 0;
  }

  .contactDiv_info__1Kj9V{
    margin: 0;
    margin-left: 15em !important;
  }

  .contactDiv_infoBox1__27X9P > img{
    height: 25px;
  }

  .contactDiv_infoBox2__3JLhB{
    margin-top: 5em;
  }

}





/* Potrait Orientation for Tablets and Mobiles */


/* Tablets and IPads */
@media only screen 
  and (min-width: 768px) 
  and (max-width: 880px) 
  and (orientation : portrait){

}

/*Ipad Potrait*/
@media only screen 
and (min-width : 768px) 
and (max-width : 1024px)
and (max-height: 1024px) 
and (orientation : portrait) {


}

/* IPad Pro 10.5 Portrait */
@media only screen 
  and (min-width: 834px)
  and (max-width: 834px) 
  and (orientation: portrait) {


}

/* IPad Pro 12.5 Portrait */
@media only screen
and (min-width: 1024px)
and (max-width: 1024px)
and (orientation: portrait){

  

}



* {
  /* box-sizing: border-box; */
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  line-height: 1.5em;
}

.profilePage{
    margin-left: 3.3vw;
    margin-right: 3.3vw;
    display: flex;
    flex-direction: column;
}

.section{
    width: 100%;
}

.profileImage::before{
    content: "";
}

.profileImage{
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
}

.imageBox{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 35vw;
    height: 20vw;
}

.socials{
    display: inline;
    width: 100%;
    vertical-align: baseline;
    align-self: flex-end;
    text-align: left;
}

.socials svg , .socials a{
    width: 15px;
    height: 15px;
    margin: 0 0 -10px 10px;
}

.socials a {
    color: #646464;
}

.socials a:hover {
    color: unset;
}

.profileContent{
    width: 35vw;
    text-align: justify;
}

.name{
    font-size: 25px;
    padding: 20px 0 20px 0;
}

.keyProjects{
    margin-top: 50px;
}

svg.itemDash{
    height: 10px;
}

.knowMoreSection{
    margin-top: 50px;
    justify-self: center;
}

.knowMore{
    height: 60px;
    width: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 40%;
}

.blogImage{
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
}

@media only screen and (max-width:900px) {
    .profilePage{
        margin: 0 8vw 0 8vw;
    }
    .profileImage{
        display: block;
    }
    .imageBox{
        height: 55vw;
        width: 100%;
    }
    .profileContent{
        width: 100%;
    }
    .socials{
        display: none;
    }
    .knowMoreSection{
        display: block;
        position: relative;
        align-self: center;
        padding-right: 0;
    }
}


@media only screen and (aspect-ratio: 16/10){
  
}



@media screen and (min-width: 200px) and (max-width: 300px) and (max-height: 600px) and (orientation : portrait) {

}

@media screen and (min-width: 300px) and (max-width: 350px) and (orientation : portrait) {

}

@media screen and (min-width: 350px) and (max-width: 400px) and (orientation : portrait) {

}

@media screen and (min-width: 400px) and (max-width: 500px) and (orientation : portrait) {

}


@media screen and (min-width: 500px) and (max-width: 600px) and (orientation : portrait) {

}

@media screen and (min-width: 600px) and (max-width: 700px) and (orientation : portrait) {

}


@media screen and (min-width: 700px) and (max-width: 800px) and (orientation : portrait) {

}



@media screen and (min-width: 800px) and (max-width: 2900px) and (orientation : portrait) {
    .profilePage{
        display: flex;
        flex-direction: column;
        margin: 0 5.3vw 0 5.3vw;
    }
    .profileImage{
        display: block;
        align-self: center;
    }
    .imageBox{
        height: 40vw;
        width: 100%;
        background-position: center;
        background-size: contain;
    }
    .profileContent{
        padding: 0 15vw 0 15vw;
    }
    .socials{
        display: none;
    }
    .keyProjects{
        text-align: center;
    }
    .knowMoreSection{
        display: block;
        position: relative;
        align-self: center;
        padding-right: 0;
    }
    .knowMore{
    }
}






/* Smartphone portrait */



@media only screen 
  and (min-width : 240px)
  and (max-width : 399px)
  and (orientation : portrait){

    .profilePage{
        display: flex;
        flex-direction: column;
        margin: 0 9vw;
    }
    .profileImage{
        display: block;
    }
    .imageBox{
        height: 55vw;
        width: 100%;
    }
    .profileContent{
        width: 100%;
    }
    .socials{
        display: none;
    }
    .knowMoreSection{
        display: block;
        position: relative;
        align-self: center;
    }

}


@media only screen
  and (min-width : 400px)
  and (max-width : 600px)
  and (orientation : portrait){
    .profilePage{
        display: flex;
        flex-direction: column;
        margin: 0 9vw;
    }
    .profileImage{
        display: block;
    }
    .imageBox{
        height: 55vw;
        width: 100%;
    }
    .profileContent{
        width: 100%;
    }
    .socials{
        display: none;
    }
    .knowMoreSection{
        display: block;
        position: relative;
        align-self: center;
    }
}

@media only screen 
  and (min-width: 600px)
  and (max-width : 800px)
  and (orientation : portrait){

    .profilePage{
        display: flex;
        flex-direction: column;
        margin: 0 5.3vw;
    }
    .profileImage{
        display: block;
    }
    .imageBox{
        height: 55vw;
        width: 100%;
    }
    .profileContent{
        width: 100%;
    }
    .socials{
        display: none;
    }
    .knowMoreSection{
        display: block;
        position: relative;
        align-self: center;
    }
}



/* Smartphone Landscape */

@media only screen  
  and (min-width : 320px)
  and (max-width : 499px)
  and (orientation : landscape){

    .profilePage{
        display: flex;
        flex-direction: column;
        margin: 0 4.9vw;
    }
    .profileImage{
        display: block;
    }
    .imageBox{
        height: 55vw;
        width: 100%;
    }
    .profileContent{
        width: 100%;
    }
    .socials{
        display: none;
    }
    .knowMoreSection{
        display: block;
        position: relative;
        align-self: center;
    }


}

@media only screen  
  and (min-width : 500px)
  and (max-width : 599px)
  and (orientation : landscape){

    .profilePage{
        display: flex;
        flex-direction: column;
        margin: 0 4.9vw;
    }
    .profileImage{
        display: block;
    }
    .imageBox{
        height: 55vw;
        width: 100%;
    }
    .profileContent{
        width: 100%;
    }
    .socials{
        display: none;
    }
    .knowMoreSection{
        display: block;
        position: relative;
        align-self: center;
    }

}

@media only screen  
  and (min-width : 600px)
  and (max-width : 650px)
  and (orientation : landscape){

    .profilePage{
        display: flex;
        flex-direction: column;
        margin: 0 4.9vw;
    }
    .profileImage{
        display: block;
    }
    .imageBox{
        height: 55vw;
        width: 100%;
    }
    .profileContent{
        width: 100%;
    }
    .socials{
        display: none;
    }
    .knowMoreSection{
        display: block;
        position: relative;
        align-self: center;
    }

}

@media only screen  
  and (min-width : 650px)
  and (max-width : 700px)
  and (orientation : landscape){

    .profilePage{
        display: flex;
        flex-direction: column;
        margin: 0 4.9vw;
    }
    .profileImage{
        display: block;
    }
    .imageBox{
        height: 55vw;
        width: 100%;
    }
    .profileContent{
        width: 100%;
    }
    .socials{
        display: none;
    }
    .knowMoreSection{
        display: block;
        position: relative;
        align-self: center;
    }

}

@media only screen  
  and (min-width : 700px)
  and (max-width : 750px)
  and (orientation : landscape){

    .profilePage{
        display: flex;
        flex-direction: column;
        margin: 0 4.9vw;
    }
    .profileImage{
        display: block;
    }
    .imageBox{
        height: 55vw;
        width: 100%;
    }
    .profileContent{
        width: 100%;
    }
    .socials{
        display: none;
    }
    .knowMoreSection{
        display: block;
        position: relative;
        align-self: center;
    }

}

@media only screen  
  and (min-width : 750px)
  and (max-width : 799px)
  and (orientation : landscape){

    .profilePage{
        display: flex;
        flex-direction: column;
        margin: 0 4.9vw;
    }
    .profileImage{
        display: block;
    }
    .imageBox{
        height: 55vw;
        width: 100%;
    }
    .profileContent{
        width: 100%;
    }
    .socials{
        display: none;
    }
    .knowMoreSection{
        display: block;
        position: relative;
        align-self: center;
    }
  
}

@media only screen  
  and (min-width : 800px)
  and (max-width : 890px)
  and (orientation : landscape){
    .profilePage{
        display: flex;
        flex-direction: column;
        margin: 0 4.9vw;
    }
    .profileImage{
        display: block;
    }
    .imageBox{
        height: 55vw;
        width: 100%;
    }
    .profileContent{
        width: 100%;
    }
    .socials{
        display: none;
    }
    .knowMoreSection{
        display: block;
        position: relative;
        align-self: center;
    }
}

@media only screen  
  and (min-width : 890px)
  and (max-width : 900px)
  and (orientation : landscape){

    .profilePage{
        display: flex;
        flex-direction: column;
        margin: 0 4.9vw;
    }
    .profileImage{
        display: block;
    }
    .imageBox{
        height: 55vw;
        width: 100%;
    }
    .profileContent{
        width: 100%;
    }
    .socials{
        display: none;
    }
    .knowMoreSection{
        display: block;
        position: relative;
        align-self: center;
    }

}







/* Desktops and laptops ----------- */
@media only screen  
  and (min-width : 720px) 
  and (min-height: 600px){

}


@media only screen  
  and (min-width : 900px){

}

@media only screen and (min-width: 1000px) {


}

@media only screen and (min-width : 1110px) {

}

@media only screen and (min-width: 1024px) {

}


/*LCD Displays*/
@media only screen 
  and (min-width: 1024px) 
  and (max-width: 1080px)  
  and (min-height : 800px) 
  and (max-height : 900px) {

} 



@media only screen  and (min-width : 1280px) {

}


@media only screen  and (min-width : 1300px) {
 
}
/* Basic Laptop Screens */
@media only screen and (min-width : 1440px) {

}

/* Larger laptop screens */
@media only screen  and (min-width : 1500px) {


}



/* Large screens ----------- */
@media only screen  and (min-width : 1600px) {

}


@media only screen  and (min-width : 1700px) {
 
}

@media only screen  and (min-width : 1900px) {
 
}

@media only screen  and (min-width : 2000px) {

}

/* Macbook Pro */
@media only screen 
  and (min-width: 1700px) 
  and (max-width: 1800px)  
  and (min-height : 1080px) 
  and (max-height : 1100px) {

 
}

/* Potrait Orientation for Tablets and Mobiles */


/* Tablets and IPads */
@media only screen 
  and (min-width: 600px)
  and (max-width: 720px)
  and (orientation : portrait){
  

  
}

@media only screen 
  and (min-width: 720px) 
  and (max-width: 769px) 
  and (orientation : portrait)
  and (-webkit-device-pixel-ratio: 1){

 
}


@media only screen 
  and (min-width: 769px) 
  and (max-width: 850px)
  and (max-height : 1300px)
  and (orientation : portrait){


}

@media only screen 
  and (min-width: 850px) 
  and (max-width: 899px) 
  and (orientation : portrait){

 

}


@media only screen 
  and (min-width: 900px) 
  and (max-width: 959px) 
  and (orientation : portrait){

  

}


/*Ipad Potrait*/
@media only screen 
and (min-width : 768px) 
and (max-width : 1024px)
and (max-height: 1024px) 
and (orientation : portrait) {

}

/* IPad Pro 10.5 Portrait */
@media only screen 
  and (min-width: 834px)
  and (max-width: 834px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1){
 
}

/* IPad Pro 12.5 Portrait */
@media only screen
and (min-width: 1024px)
and (max-width: 1024px)
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 1){


}







/* Landscape Orientation for Tablets and Mobiles */


/* Tablets and IPads */

@media only screen 
  and (min-width: 960px) 
  and (max-width: 1023px) 
  and (orientation : landscape){

 
}



@media only screen 
  and (min-width: 1023px) 
  and (max-width: 1179px) 
  and (orientation : landscape){

 
}

@media only screen
  and (min-width: 1180px)
  and (max-width: 1194px)
  and (orientation: landscape){

 
}

@media only screen 
  and (min-width : 1280px) 
  and (min-height : 740px) 
  and (max-height : 750px) {

  
}




/*Ipad Landscape*/
@media only screen 
and (min-height : 768px)
and (max-width : 1024px)
and (orientation : landscape)
and (-webkit-min-device-pixel-ratio: 1){

 

}

/* IPad Pro 10.5 Landscape */
@media only screen 
  and (min-height: 834px)
  and (max-width: 1112px) 
  and (orientation: landscape){

 
}

/* IPad Pro 12.5 Landscape */
@media only screen
  and (min-height: 1024px)
  and (max-height: 1024px)
  and (orientation: landscape){


}


@media only screen
  and (device-width: 2732px)
  and (device-height: 2048px)
  and (orientation: landscape){
 
}
