@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");


.newsPage {
  width: 100% !important;
  padding: 0 !important;
  margin: 0 0 4em 0 !important;
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  line-height: 2.1em;
}

.mediaBox img {
  height: 100% !important;
  width: 100%;
}

.mediaBox {
  position: relative; 
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
  height: 35vw;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: overlay;
    /* overflow: hidden;
    text-align: center;
    margin: 0 auto; */
}
.mediaBox::before{
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  /* background-color: rgba(0,0,0,0.25); */

}
.mediaBox > h1{
  position: relative;
  color: #ffffff;
  font-weight: bold;  
  font-size: 8em;
  line-height: 0.9;
  text-align: center;
}



.newsMedia {
  width: 40vw;
  height: 18vw;
  background-color: #e9e9e9;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: overlay;
}


.newsContent {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  margin-top: -20px;
}

.newsContent > h1 {
  color: #808080;
  font-weight: 200;
  font-size: 2rem;
}
.newsContent > p {
  color: #9e9e9e;
  display: block;
  text-align: justify;
  white-space: break-spaces;
  width: 100%;
  position: relative;
  font-size: 0.99vw;
  line-height: 2em;
}

/* .newsContainer > .news {
  display: grid;
  grid-auto-flow: column;
} */

.newsContainer{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: column;
  margin: 30px 0 0 0 !important;
}

.newsContent > .infoLink{
  text-align: left;
}

@media only screen and (max-width : 900px){
  .mediaBox > h1{
    font-size: 5em;
  }

  .newsContainer{
    display: flex;
    flex-direction: column;
  }
  .mediaBox{
    height: 60vw;
  }
}

/* Smartphone portrait */

@media only screen 
  and (max-width:240px){

    .newsPage{
        margin: 2em 9vw;
    }
    
    .newsMedia{
      width: 83vw;
      height: 45vw;
      margin-bottom: 2em;
    }

    .mediaBox > h1{
      font-size: 3em;
    }

}


@media only screen 
  and (min-width : 240px)
  and (max-width : 360px)
  and (orientation : portrait){

    .newsPage{
        margin: 2em 8vw;
    } 

    .newsMedia{
      width: 83vw;
      height: 45vw;
      margin-bottom: 2em;
    }

    .right{
      padding: 0;
    }

    .newsContent > p{
      font-size: 0.9rem;
    }

    .mediaBox > h1{
      font-size: 3em;
    }

}

@media only screen
  and (min-width : 360px) 
  and (max-width : 399px)
  and (orientation : portrait){

    .newsPage{
        margin: 2em 8vw;
    }     

    .newsMedia{
      width: 84vw;
      height: 45vw;
      margin-bottom: 2em;
    }

    .newsContent > h1{
      font-size: 1.5rem;
    }

    .newsContent > p{
      font-size: 0.9rem;
    }
    
    .mediaBox > h1{
      font-size: 3em;
    }

}


@media only screen
  and (min-width : 400px) 
  and (max-width : 428px)
  and (orientation : portrait){
    .newsPage{
        margin: 2em 7.5vw;
    } 

    .newsMedia{
      width: 84.5vw;
      height: 45vw;
      margin-bottom: 2em;
    }

    .newsContent > h1{
      font-size: 1.5rem;
    }

    .newsContent > p{
      font-size: 0.9rem;
    }

    .mediaBox > h1{
      font-size: 3em;
    }

}

@media only screen
  and (min-width : 428px) 
  and (max-width : 500px)
  and (orientation : portrait){
    .newsPage{
        margin: 2em 9vw;
    } 

    .newsMedia{
      width: 82vw;
      height: 45vw;
      margin-bottom: 2em;
    }

    .newsContent > h1{
      font-size: 1.5rem;
    }

    .newsContent > p{
      font-size: 0.9rem;
    }

    .mediaBox > h1{
      font-size: 3em;
    }

}

@media only screen
  and (min-width : 500px) 
  and (max-width : 600px)
  and (orientation : portrait){

    .newsPage{
        margin: 2em 9vw;
    } 

    .newsMedia{
      width: 82vw;
      height: 45vw;
      margin-bottom: 2em;
    }

    .newsContent > h1{
      font-size: 1.5rem;
    }

    .newsContent > p{
      font-size: 0.9rem;
    }

    .mediaBox > h1{
      font-size: 3em;
    }
    
}

@media only screen 
  and (min-width: 600px)
  and (max-width : 800px)
  and (orientation : portrait){

    .newsPage{
        margin: 2em 5.3vw;
    } 

    .newsMedia{
      width: 84.5vw;
      height: 45vw;
    }

    .newsContent > h1{
      font-size: 1.5rem;
    }

    .newsContent > p{
      font-size: 0.9rem;
    }

    .mediaBox > h1{
      font-size: 3em;
    }

}



/* Smartphone Landscape */

@media only screen  
  and (min-width : 320px)
  and (max-width : 900px)
  and (orientation : landscape){
    .newsPage{
        margin : 2em 4.9vw;
    }

    .newsMedia{
      width: 92.2vw;
      height: 38.7vw;
      margin-bottom: 2em;
    }

    .newsContent > p {
      font-size: 1rem;
    }



    .mediaBox > h1{
      font-size: 3em;
    }
}

@media only screen  
  and (min-width : 320px)
  and (max-width : 499px)
  and (orientation : landscape){

    .newsMedia{
      width: 90vw;
      height: 50vw;
      margin-bottom: 2em;
    }

    .newsMedia{
      width: 92.2vw;
      height: 38.7vw;
    }

  

    .newsContent > p {
      font-size: 1rem;
    }

    .mediaBox > h1{
      font-size: 3em;
    }

  }

@media only screen  
  and (min-width : 500px)
  and (max-width : 599px)
  and (orientation : landscape){
    
    .newsMedia{
      width: 43.4vw;
      height: 30vw;
      margin-bottom: 2em;
    }

    .newsMedia{
      width: 92.2vw;
      height: 38.7vw;
    }

    .newsContent > p {
      font-size: 1rem;
    }


    .mediaBox > h1{
      font-size: 3em;
    }
 
}

@media only screen  
  and (min-width : 600px)
  and (max-width : 650px)
  and (orientation : landscape){
 

    .newsMedia{
      width: 92.2vw;
      height: 38.7vw;
      margin-bottom: 2em;
    }

    .newsContent > p {
      font-size: 1rem;
    }

    .mediaBox > h1{
      font-size: 3em;
    }

}

@media only screen  
  and (min-width : 650px)
  and (max-width : 700px)
  and (orientation : landscape){


    .newsMedia{
      width: 92.2vw;
      height: 38.7vw;
      margin-bottom: 2em;
    }

    .newsContent > p {
      font-size: 1rem;
    }

    .mediaBox > h1{
      font-size: 3em;
    }

}


@media only screen  
  and (min-width : 700px)
  and (max-width : 750px)
  and (orientation : landscape){

    .newsMedia{
      width: 92.2vw;
      height: 38.7vw;
      margin-bottom: 2em;
    }

    .newsContent > p {
      font-size: 1rem;
    }


    .mediaBox > h1{
      font-size: 3em;
    }

}

@media only screen  
  and (min-width : 750px)
  and (max-width : 799px)
  and (orientation : landscape){
 

    .newsMedia{
      width: 92.2vw;
      height: 38.7vw;
      margin-bottom: 2em;

    }

    .newsContent > p {
      font-size: 1rem;
    }


    .mediaBox > h1{
      font-size: 3em;
    }

}

@media only screen  
  and (min-width : 800px)
  and (max-width : 890px)
  and (orientation : landscape){


    .newsMedia{
      width: 92.2vw;
      height: 38.7vw;
      margin-bottom: 2em;
    }

    .newsContent > p {
      font-size: 1rem;
    }


    .mediaBox > h1{
      font-size: 3em;
    }

}


@media only screen  
  and (min-width : 890px)
  and (max-width : 900px)
  and (orientation : landscape){
 

    .newsMedia{
      width: 92.2vw;
      height: 38.7vw;
      margin-bottom: 2em;
    }

    .newsContent > p {
      font-size: 1rem;
    }

    .mediaBox > h1{
      font-size: 3em;
    }

}







/* Desktops and laptops ----------- */

@media only screen  
  and (min-width : 720px) 
  and (min-height: 600px){

    .newsContainer{
      display: flex;
    }

    .newsContent{
      width: 65%;
    }

    .newsPage{
        margin: 2em 5.3vw;
    } 

    .newsMedia{
      width: 100%;
      height: 40vw;
      margin-bottom: 2em;
    }

    .newsContent{
      width: 100%;
    }
  
}

@media only screen  
  and (min-width : 900px){
    
    .newsPage{
        margin : 2em 5vw;
    }

    .newsContainer{
      display: flex;
      flex-direction: column;
    }

    
    .newsContent > p {
      font-size: 1rem;
    }
}

@media only screen and (min-width: 1000px) {
    .newsPage{
        margin : 2em 3.3vw;
    } 

    .newsContainer{
      display: grid;
      grid-template-columns: 0.6fr 1fr;
    }

    .newsMedia{
      width: 29.8vw;
      height: 20vw;
    }

    .newsContent{
      width: 75%;
    }
}



@media only screen and (min-width : 1280px) {
    .newsPage{
        margin : 2em 5vw;
    } 

    .newsContainer{
      display: grid;
      grid-template-columns: 0.347fr 1fr;
    }
    
    .newsPage{
        margin : 2em 3.3vw;
    } 
  
    .newsMedia{
      width: 22.3vw;
      height: 16vw;
    }
  
    .newsContent > p{
      width: 95%;

    }
}



/*LCD Displays*/
@media only screen 
  and (min-width: 1024px) 
  and (max-width: 1080px)  
  and (min-height : 800px) 
  and (max-height : 900px) {
    .newsPage{
        margin : 2em 3.3vw;
    }

    .newsContainer{
      display: grid;
      grid-template-columns: 0.5fr 1fr;
    }
}


/* Basic Laptop Screens */
@media only screen and (min-width : 1440px) {
  .newsPage{
      margin : 2em 3.3vw;
  } 

  .newsContainer{
    display: grid;
    grid-template-columns: 0.347fr 1fr;
  }
  
  .newsPage{
      margin : 2em 3.3vw;
  } 

  .newsMedia{
    width: 22.3vw;
    height: 16vw;
  }

  .newsContent > p{
    width: 115%;
  }
    

}

/* Larger laptop screens */
@media only screen  and (min-width : 1500px) {

.newsContainer{
  display: grid;
  grid-template-columns: 0.347fr 1fr;
}

.newsPage{
    margin : 2em 3.3vw;
} 

.newsMedia{
  width: 22.3vw;
  height: 16vw;
}

.newsContent > p{
  width: 95%;
}
    
}


/* Large screens ----------- */
@media only screen  and (min-width : 1600px) {

  .newsContainer{
    display: grid;
    grid-template-columns: 0.347fr 1fr;
  }

  .newsPage{
      margin : 2em 3.3vw;
  } 

  .newsMedia{
    width: 22.4vw;
    height: 16.6vw;
  }

  .newsContent > p{
    width: 95%;
  }

  .newsPage{
      margin : 2em 3.3vw;
  }   
}

@media only screen  and (min-width : 1700px) {

  .newsContainer{
    display: grid;
    grid-template-columns: 0.347fr 1fr;
  }

  .newsPage{
      margin : 2em 3.3vw;
  } 

  .newsMedia{
    width: 22.5vw;
    height: 16.6vw;
  }

  .newsContent > p{
    width: 95%;
  }

  .newsPage{
      margin : 2em 3.3vw;
  }   
}

@media only screen  and (min-width : 1900px) {

  .newsContainer{
    display: grid;
    grid-template-columns: 0.347fr 1fr;
  }

  .newsPage{
      margin : 2em 3.3vw;
  } 

  .newsMedia{
    width: 22.5vw;
    height: 16.6vw;;
  }

  .newsContent > p{
    width: 95%;
  }

  .newsPage{
      margin : 2em 3.3vw;
  }  
 
}


@media only screen  and (min-width : 2000px) {

  .newsContainer{
    display: grid;
    grid-template-columns: 0.347fr 1fr;
  }

  .newsPage{
      margin : 2em 3.3vw;
  } 

  .newsMedia{
    width: 22.7vw;
    height: 16.6vw;
  }

  .newsContent > p{
    width: 95%;
  }

  .newsPage{
      margin : 2em 3.3vw;
  }  

}


/* Macbook Pro */
@media only screen 
  and (min-width: 1700px) 
  and (max-width: 1800px)  
  and (min-height : 1080px) 
  and (max-height : 1100px) {

    .newsContainer{
      display: grid;
      grid-template-columns: 0.5fr 1fr;
    }
  
    .newsPage{
        margin : 2em 3.3vw;
    } 

    .newsMedia{
      width: 22.5vw;
      height: 16vw;
    }

    .newsContent > p{
      width: 115%;
    }

  .newsPage{
      margin : 2em 3.3vw;
  } 
}


/* Potrait Orientation for Tablets and Mobiles */

@media only screen 
  and (min-width: 600px)
  and (max-width: 720px)
  and (orientation : portrait)
  and (-webkit-min-device-pixel-ratio: 1){
    .newsPage{
        margin : 2em 5.3vw;
    } 

    .newsContainer{
      display: flex;
      flex-direction: column;
    }

    .newsMedia{
      width: 100%;
      height: 35vw;
      margin-bottom: 2em;
    }

    .newsContent{
      width: 100%;
    }

    .newsContent > p {
      font-size: 1rem;
    }
}

@media only screen 
  and (min-width: 720px) 
  and (max-width: 769px) 
  and (orientation : portrait)
  and (-webkit-device-pixel-ratio: 1){
    .newsPage{
        margin : 2em 5.3vw;
    }

    .newsContainer{
      display: flex;
      flex-direction: column;
    }

    .newsMedia{
      width: 100%;
      height: 35vw;
      margin-bottom: 2em;
    }

    .newsContent{
      width: 100%;
    }

    .newsContent > p {
      font-size: 1rem;
    }
}


@media only screen 
  and (min-width: 769px) 
  and (max-width: 850px)
  and (max-height : 1300px)
  and (orientation : portrait){
    .newsPage{
        margin : 2em 5.3vw;
    }

    .newsContainer{
      display: flex;
      flex-direction: column;
    }

    .newsMedia{
      width: 100%;
      height: 35vw;
      margin-bottom: 2em;
    }

    .newsContent{
      width: 100%;
    }

    .newsContent > p {
      font-size: 1rem;
    }
}

@media only screen 
  and (min-width: 850px) 
  and (max-width: 899px) 
  and (orientation : portrait){
    .newsPage{
        margin : 2em 5.3vw;
    } 

    .newsContainer{
      display: flex;
      flex-direction: column;
    }

    .newsMedia{
      width: 100%;
      height: 35vw;
      margin-bottom: 2em;
    }

    .newsContent{
      width: 100%;
    }

    .newsContent > p {
      font-size: 1rem;
    }
}




@media only screen 
  and (min-width: 900px) 
  and (max-width: 959px) 
  and (orientation : portrait){
    .newsPage{
        margin : 2em 5.3vw;
    }

    .newsContainer{
      display: flex;
      flex-direction: column;
    }

    .newsMedia{
      width: 100%;
      height: 35vw;
      margin-bottom: 2em;
    }

    .newsContent{
      width: 100%;
    }

    .newsContent > p {
      font-size: 1rem;
    }
}

@media only screen 
  and (min-width: 1000px)
  and (orientation : portrait){

    .newsContainer{
      display: flex;
      flex-direction: column;
    }

    .newsPage{
        margin : 2em 5.3vw;
    }

    .newsMedia{
      width: 100%;
      height: 35vw;
      margin-bottom: 2em;
    }

    .newsContent{
      width: 100%;
    }

    .newsContent > p {
      font-size: 1rem;
    }
}


/*Ipad Potrait*/
@media only screen 
and (min-width : 768px) 
and (max-width : 1024px)
and (max-height: 1024px) 
and (orientation : portrait) 
and (-webkit-min-device-pixel-ratio: 1)
{
    .newsPage{
        margin : 2em 5.3vw;
    }

    .newsContainer{
      display: flex;
      flex-direction: column;
    }

    .newsMedia{
      width: 100%;
      height: 35vw;
      margin-bottom: 2em;
    }
  
    .newsContent{
      width: 100%;
    }

    .newsContent > p {
      font-size: 1rem;
    }

}

/* IPad Pro 10.5 Portrait */
@media only screen 
  and (min-width: 834px)
  and (max-width: 834px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1)
  {
    .newsPage{
        margin : 2em 5.3vw;
    }

    .newsContainer{
      display: flex;
      flex-direction: column;
    }
  
    .newsMedia{
      width: 100%;
      height: 35vw; 
    }
    .newsContent{
      width: 100%;
    }

    .newsContent > p {
      font-size: 1rem;
    }
}

/* IPad Pro 12.5 Portrait */
@media only screen
and (min-width: 1024px)
and (max-width: 1024px)
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 1)
{
  .newsContainer{
    display: flex;
    flex-direction: column;
  }

  .newsMedia{
    width: 100%;
    height: 35vw;
    margin-bottom: 2em;
  }


  .newsPage{
    margin : 2em 5.3vw;
  }



  .newsContent{
    width: 100%;
  }

  
  .newsContent > p{
    font-size: 1.05rem;
  }


}







/* Landscape Orientation for Tablets and Mobiles */


/* Tablets and IPads */




@media only screen 
  and (min-width: 960px) 
  and (max-width: 1023px) 
  and (orientation : landscape){

    .newsContainer{
      display: grid;
      grid-template-columns: 0.6fr 1fr;
    }

    .newsContent > p{
      font-size: 1vw;
    }

    .newsContent{
      width: 75%;
    }

    .newsPage{
        margin : 2em 3.3vw;
    }

    .newsMedia{
      width: 44vw;
      height: 32.5vw; 
    }


    .newsMedia{
      width: 29.8vw;
      height: 20vw;
    }



 
} 

@media only screen 
  and (min-width: 1023px) 
  and (max-width: 1179px) 
  and (orientation : landscape)
  and (-webkit-min-device-pixel-ratio: 2){

    .newsContainer{
      grid-template-columns: 0.6fr 1fr;
    }

    .newsContent > p{
      font-size: 1vw;
    }

    .newsContent{
      width: 75%;
    }
    .newsPage{
        margin : 2em 3.3vw;
    }

    .newsMedia{
      width: 29.8vw;
      height: 20vw;
    }


  
}


@media only screen
  and (min-width: 1180px)
  and (max-width: 1194px)
  and (orientation: landscape){

    .newsContainer{
      grid-template-columns: 0.6fr 1fr;
    }

    .newsContent > p{
      font-size: 1vw;
    }

    .newsContent{
      width: 70%;
    }

    .newsPage{
        margin : 2em 3.3vw;
    } 

    .newsMedia{
      width: 29.8vw;
      height: 20vw;
    }


}

@media only screen 
  and (min-width : 1280px) 
  and (min-height : 740px) 
  and (max-height : 750px) {

    .newsContainer{
      grid-template-columns: 0.6fr 1fr;
    }

    .newsContent > p{
      font-size: 1vw;
    }

    .newsContent{
      width: 75%;
    }

    .newsPage{
        margin : 2em 3.3vw;
    } 
    .newsMedia{
      width: 29.8vw;
      height: 20vw;
    }


}

/*Ipad Landscape*/
@media only screen 
and (min-height : 768px)
and (max-width : 1024px)
and (orientation : landscape)
and (-webkit-min-device-pixel-ratio: 1) {

  .newsContainer{
    grid-template-columns: 0.6fr 1fr;
  }

  .newsContent > p{
    font-size: 1vw;
  }

  .newsContent{
    width: 75%;
  }

    .newsPage{
        margin : 2em 3.3vw;
    }
 
    .newsMedia{
      width: 29.8vw;
      height: 20vw;
    }

}

/* IPad Pro 10.5 Landscape */
@media only screen 
  and (min-height: 834px)
  and (max-width: 1112px) 
  and (orientation: landscape){

    .newsContainer{
      display: grid;
      grid-template-columns: 0.6fr 1fr;
    }

    .newsContent > p{
      font-size: 1vw;
    }

    .newsContent{
      width: 75%;
    }


    .newsPage{
        margin : 2em 3.3vw;
    }  

    .newsMedia{
      width: 29.8vw;
      height: 20vw;
    }

}

/* IPad Pro 12.5 Landscape */
@media only screen
  and (min-height: 1024px)
  and (max-height: 1024px)
  and (orientation: landscape){

    .newsContainer{
      grid-template-columns: 0.6fr 1fr;
    }

    .newsContent > p{
      font-size: 1vw;
    }

    .newsContent{
      width: 75%;
    }
    .newsPage{
        margin : 2em 3.3vw;
    }

    .newsMedia{
      width: 30.2vw;
      height: 23vw;
    }

}



