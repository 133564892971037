@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");


.header {
  font-family: "Roboto", sans-serif;
  font-size: 1.3rem;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  margin: 2em 2em;
  align-items: center;
}

.header > .navMenu {
  margin: 0;
}

.logo {
  float: left;
  height: 30px;
  object-fit: cover;
  z-index: 1;
}

.navList{
  display: inline-block;

}

.navList > a{
  color: #9e9e9e;
  font-weight: 200;
  margin-left: 3vw;
}

.navList > a:hover {
  text-decoration: none;
  color: #484848;
}

.toggler {
  display: none;
  position: absolute;
  width: 1.6rem;
  height: 1.6rem;
  z-index: 999;
  opacity: 0;
}

.hamburger {
  align-self: flex-end;
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 1.6rem;
  height: 1.6rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1000;
}

.hamburger > div {
  width: 1.7rem;
  height: 0.15rem;
  border-radius: 10px;
  transition: all 0.2s linear;
  position: relative;
  transform-origin: 1px;
  clip-path: inset(0 0 0 0);
}

/* .toggler:hover + .hamburger > div:nth-child(2) {
  clip-path: inset(0 0 0 30%);
}

.toggler:hover + .hamburger > div:nth-child(3) {
  clip-path: inset(0 0 0 50%);
} */


.toggler:checked + .hamburger > div:first-child {
  transform: rotate(45deg) translateX(1px);
}

.toggler:checked + .hamburger > div:nth-child(2) {
  opacity: 0;
}

.toggler:checked + .hamburger > div:nth-child(3) {
  clip-path: inset(0 0 0 0);
  transform: rotate(-45deg);
}


.hamburger:focus {
  outline: none;
}


@media only screen
  and (max-width:900px) {
    
  .hamburger{
    display: flex;
  }
  .toggler{
    display: block;
  }
  .navList{
    display: none;
  }

}



/* Smartphone portrait */



@media only screen 
  and (min-width : 240px)
  and (max-width : 320px)
  and (orientation : portrait){

  .header{
    margin: 2em 9vw;
  }

    
}

@media only screen
  and (min-width : 320px) 
  and (max-width : 400px)
  and (orientation : portrait){
  .header{
    margin: 2em 8vw;
  }
}

@media only screen
  and (min-width : 400px) 
  and (max-width : 428px)
  and (orientation : portrait){
  .header{
    margin: 2em 9vw;
  }
}

@media only screen
  and (min-width : 429px) 
  and (max-width : 600px)
  and (orientation : portrait){

  .header{
    margin: 2em 9vw;
  }
  
}

  @media only screen 
  and (min-width: 600px)
  and (max-width : 800px)
  and (orientation : portrait){


    .header{
      margin: 2em 5.3vw;
    }

}





/* Smartphone Landscape */

@media only screen  
  and (min-width : 320px)
  and (max-width : 460px)
  and (orientation : landscape){

  .header{
    margin : 2em 4.9vw;
  }    
}

@media only screen  
  and (min-width : 500px)
  and (max-width : 850px)
  and (orientation : landscape){

  .header{
    margin : 2em 4.9vw;
  }


}


@media only screen  
  and (min-width : 890px)
  and (max-width : 900px)
  and (orientation : landscape){

  .header{
    margin : 2em 4.9vw;
  }
}




/* Desktops and laptops ----------- */

@media only screen  
  and (min-width : 720px) 
  and (min-height: 600px){
  .header{
    margin: 2em 5.3vw;
  }
}

@media only screen  
  and (min-width : 900px){

  .header{
    margin : 2em 5vw;
  }

}

@media only screen  
  and (min-width : 1000px){

  .header{
    margin : 2em 3.3vw;
  }

}


@media only screen and (min-width : 1110px) {
  .header{
    margin: 2em 3.3vw;
  }
}


@media only screen and (min-width : 1280px) {
  .header{
    margin: 2em 3.3vw;
  }

}

@media only screen 
  and (min-width : 1279px) 
  and (min-height : 740px) 
  and (max-height : 800px) {
    .header{
      margin: 2em 3.3vw;
    }
    
}

/* Basic Laptop Screens */
@media only screen and (min-width : 1440px) {
  .header{
    margin: 2em 3.3vw;
  }
  
}

/* Larger laptop screens */
@media only screen  and (min-width : 1500px) {
  
  .header{
    margin: 2em 3.3vw;
  }
}


/*LCD Displays*/
@media only screen 
  and (min-width: 1024px) 
  and (max-width: 1080px)  
  and (min-height : 800px) 
  and (max-height : 900px) {
    .header{
      margin: 2em 3.3vw;
    }
    
}

/* Large screens ----------- */
@media only screen  and (min-width : 100em) {
  .header{
    margin: 2em 3.3vw;
  }
  
}

/* Macbook Pro */
@media only screen 
  and (min-width: 1700px) 
  and (max-width: 1800px)  
  and (min-height : 1080px) 
  and (max-height : 1100px) {
    .header{
      margin: 2em 3.3vw;
    }
    
}



/* Potrait Orientation for Tablets and Mobiles */


/* Tablets and IPads */

@media only screen 
  and (min-width: 600px)
  and (max-width: 718px)
  and (orientation : portrait){

  .header{
    margin: 2em 5.3vw;
  }

  
}

@media only screen 
  and (min-width: 720px) 
  and (max-width: 768px) 
  and (orientation : portrait){

  .header{
    margin: 2em 5.3vw;
  }

    
}


@media only screen 
  and (min-width: 768px) 
  and (max-width: 880px) 
  and (orientation : portrait){

  .header{
    margin: 2em 5.3vw;
  }
    
}

/*Ipad Potrait*/
@media only screen 
and (min-width : 768px) 
and (max-width : 1024px)
and (max-height: 1024px) 
and (orientation : portrait) {

  .header{
    margin: 2em 5.3vw;
  }
  
}

/* IPad Pro 10.5 Portrait */
@media only screen 
  and (min-width: 834px)
  and (max-width: 834px) 
  and (orientation: portrait) {

  .header{
    margin: 2em 5.3vw;
  }

}

/* IPad Pro 12.5 Portrait */
@media only screen
and (min-width: 1024px)
and (max-width: 1024px)
and (orientation: portrait){

  .header{
    margin: 2em 5.3vw;
  }

  .navList > a{
    color: #9e9e9e;
    font-weight: 200;
    margin-left: 4vw;
  }

}







/* Landscape Orientation for Tablets and Mobiles */


/* Tablets and IPads */


@media only screen 
  and (min-width: 960px) 
  and (max-width: 1023px) 
  and (orientation : landscape){

  .header{
    margin: 2em 3.3vw;
  }

}

@media only screen 
  and (min-width: 1023px) 
  and (max-width: 1179px) 
  and (orientation : landscape)
  and (-webkit-min-device-pixel-ratio: 2){
  
  .header{
    margin: 2em 3.3vw;
  }

}


@media only screen
  and (min-width: 1180px)
  and (max-width: 1194px)
  and (orientation: landscape){

  .header{
    margin: 2em 3.3vw;
  }

}

@media only screen 
  and (min-width : 1280px) 
  and (min-height : 740px) 
  and (max-height : 800px) {

  .header{
    margin: 2em 3.3vw;
  }

}

/*Ipad Landscape*/
@media only screen 
and (min-height : 768px)
and (max-width : 1024px)
and (orientation : landscape)
and (-webkit-min-device-pixel-ratio: 1) {

  .header{
    margin: 2em 3.3vw;
  }

}

/* IPad Pro 10.5 Landscape */
@media only screen 
  and (min-height: 834px)
  and (max-width: 1112px) 
  and (orientation: landscape){

  .header{
    margin: 2em 3.3vw;
  }

}

/* IPad Pro 12.5 Landscape */
@media only screen
  and (min-height: 1024px)
  and (max-height: 1024px)
  and (orientation: landscape){

  .header{
    margin: 2em 3.3vw;
  }

}

