@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");


.peopleContainer {
    margin: 0 0 4em 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .mediaBox img {
    width: 100%;
  }
  
  .mediaBox {
    position: relative; 
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
    height: 35vw;
    width: 100% !important ;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: overlay;
    /* overflow: hidden;
    text-align: center;
    margin: 0 auto; */
  }
  .mediaBox::before{
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    /* background-color: rgba(0,0,0,0.25); */
  
  }
  .mediaBox > h1{
    position: relative;
    color: #ffffff;
    font-weight: bold;  
    font-size: 8em;
    line-height: 0.9;
    text-align: center;
  }
  
  .peopleCard, .card_overlay {
    /* flex-grow: 1; */
    text-align: left;
  }
  
  .headroom, .headroom--pinned, .headroom--scrolled{
    z-index: 2;
  }

  .peopleCard, .imageContainer, .card_overlay{
    position: absolute;
  }
  
  .card_overlay{
    display: block;
    position: relative; 
  }
  .peopleCard img {
    object-fit: cover;
  }
  
  .card_overlay{
    background-color: rgba(0 , 0 ,0 ,0.5);
    opacity: 0;
  }

  .card_overlay p{
    text-align: center;
    color: white;
    font-size: 20px;
    padding: 0;
    /* position: absolute; */
    margin: 0;
    /* line-height: 1.6 !important; */
    font-weight: 300 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    vertical-align: baseline;
    /* top: 45%; */
  }


  /* .peopleCard p {
    color: #7d7d7d;
    font-size: 12px;
    letter-spacing: 2px;
    display: flex;
    flex-wrap: wrap;
  } */
  
  .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
    width: 23vw;
    height: 16vw;
  }
  
  .card_overlay:hover{
    opacity: 1;
  }

  .teamGrid {
    margin-top: 2em;
    width: 100% !important;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: max-content;
    column-gap: 21px;
    row-gap: 21px;
    /* padding-bottom: 0; */
  }
  
  .peopleCard > div {
    margin-top: -0.85rem;
  }

  .miniContent{
    font-size: 13px;
    color: #7d7d7d;
    width: 50%;
    text-align: center;
    overflow-wrap: break-word;
    margin-top: 3em;
  }

  .miniContent > .mini_head{
    padding-bottom: 5px;
    font-size: 15px;
  }


@media only screen
  and (max-width : 900px) 
  and (orientation : portrait){

  .teamGrid{
    display: flex;
    flex-direction: column;
  }

  .miniContent{
    width: 100%;
  }
    
  .mediaBox > h1{
    font-size: 3em;
  }
  .mediaBox{
    height: 60vw;
  }

}


@media only screen
  and (max-width : 900px) 
  and (orientation : landscape){

    .teamGrid{
      display: grid;
      grid-template-columns: repeat(2 , 1fr);
    }

    .miniContent{
      width: 100%;
    }

    .mediaBox > h1{
      font-size: 3em;
    }
    .mediaBox{
      height: 60vw;
    }
}

/* Smartphone portrait */

@media only screen 
  and (max-width:240px){

  .peopleContainer{
      margin: 2em 9vw;
  } 

  .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
    width: 83vw;
    height: 45vw;
  }

  .mediaBox > h1{
    font-size: 3em;
  }

  .teamGrid{
    display: grid;
    grid-template-columns: repeat(1 , 1fr);
  }


}


@media only screen 
  and (min-width : 240px)
  and (max-width : 360px)
  and (orientation : portrait){

    .peopleContainer{
        margin: 2em 8vw;
    }
    
    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 83vw;
      height: 45vw;
    }

    .teamGrid{
     display: grid;
      grid-template-columns: repeat(1 , 1fr);
    }

}

@media only screen
  and (min-width : 360px) 
  and (max-width : 399px)
  and (orientation : portrait){

    .peopleContainer{
        margin: 2em 8vw;
    }     

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 84vw;
      height: 45vw;
    }

    .teamGrid{
     display: grid;
      grid-template-columns: repeat(1 , 1fr);
    }

}


@media only screen
  and (min-width : 400px) 
  and (max-width : 428px)
  and (orientation : portrait){
    .peopleContainer{
        margin: 2em 7.5vw;
    } 

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 84.5vw;
      height: 45vw;
    }

    .teamGrid{
     display: grid;
      grid-template-columns: repeat(1 , 1fr);
    }

}

@media only screen
  and (min-width : 428px) 
  and (max-width : 500px)
  and (orientation : portrait){
    .peopleContainer{
        margin: 2em 9vw;
    } 

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 82vw;
      height: 45vw;
    }

    .teamGrid{
     display: grid;
      grid-template-columns: repeat(1 , 1fr);
    }
    
}

@media only screen
  and (min-width : 500px) 
  and (max-width : 600px)
  and (orientation : portrait){

    .peopleContainer{
        margin: 2em 9vw;
    } 

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 82vw;
      height: 45vw;
    }
    
    .teamGrid{
      display: grid;
      grid-template-columns: repeat(1 , 1fr);
    }
}

@media only screen 
  and (min-width: 600px)
  and (max-width : 800px)
  and (orientation : portrait){

    .peopleContainer{
        margin: 2em 5.3vw;
    } 
    
    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 84.5vw;
      height: 45vw;
    }

    .teamGrid{
     display: grid;
      grid-template-columns: repeat(1 , 1fr);
    }

}



/* Smartphone Landscape */

@media only screen  
  and (min-width : 320px)
  and (max-width : 900px)
  and (orientation : landscape){
    .peopleContainer{
        margin : 2em 4.9vw;
    } 

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 90vw;
      height: 50vw;  
    }

    .teamGrid{
     display: grid;
      grid-template-columns: repeat(1 , 1fr);
    }

}

@media only screen  
  and (min-width : 320px)
  and (max-width : 499px)
  and (orientation : landscape){

  .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
    width: 90vw;
    height: 50vw;  
  }

  .peopleContainer{
    margin: 1em 5vw;
  } 

  .teamGrid{
     display: grid;
    grid-template-columns: repeat(1 , 1fr);
  }

}

@media only screen  
  and (min-width : 500px)
  and (max-width : 599px)
  and (orientation : landscape){

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 43.4vw;
      height: 30vw;
    }

    .peopleContainer{
      margin: 2em 5vw;
    } 
    
    .teamGrid{
     display: grid;
      grid-template-columns: repeat(2 , 1fr);
    }
}

@media only screen  
  and (min-width : 600px)
  and (max-width : 650px)
  and (orientation : landscape){

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 43.6vw;
      height: 34.2vw;
    }

    .peopleContainer{
      margin: 2em 5vw;
    } 

    .teamGrid{
     display: grid;
      grid-template-columns: repeat(2 , 1fr);
    }

}

@media only screen  
  and (min-width : 650px)
  and (max-width : 700px)
  and (orientation : landscape){

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 43.6vw;
      height: 34.7vw;
    }

    .peopleContainer{
      margin: 2em 5vw;
    } 

    .teamGrid{
     display: grid;
      grid-template-columns: repeat(2 , 1fr);
    }
}



@media only screen  
  and (min-width : 700px)
  and (max-width : 750px)
  and (orientation : landscape){

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 43.7vw;
      height: 36vw;
    }

    .peopleContainer{
      margin: 2em 5vw;
    } 

    .teamGrid{
     display: grid;
      grid-template-columns: repeat(2 , 1fr);
    }

    .peopleCard p {
      margin-top: 10px;
    }
}

@media only screen  
  and (min-width : 750px)
  and (max-width : 799px)
  and (orientation : landscape){

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 44.1vw;
      height: 37.7vw;
    }

    .teamGrid{
     display: grid;
      grid-template-columns: repeat(2 , 1fr);
    }
    
    .peopleCard p {
      margin-top: 10px;
    }
}

@media only screen  
  and (min-width : 800px)
  and (max-width : 890px)
  and (orientation : landscape){

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 44vw;
      height: 37.7vw;
    }

    .peopleContainer{
      margin: 2em 5vw;
    } 

    .teamGrid{
     display: grid;
      grid-template-columns: repeat(2 , 1fr);
    }

    .peopleCard p {
      margin-top: 10px;
    }

}


@media only screen  
  and (min-width : 890px)
  and (max-width : 900px)
  and (orientation : landscape){
    
    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 44.2vw;
      height: 37.7vw;
    }

    .peopleContainer{
      margin: 2em 5vw;
    } 

    .teamGrid{
     display: grid;
      grid-template-columns: repeat(2 , 1fr);
    }

    .peopleCard p {
      margin-top: 10px;
    }

}







/* Desktops and laptops ----------- */

@media only screen  
  and (min-width : 720px) 
  and (min-height: 600px){

    .peopleContainer{
        margin: 2em 5.3vw;
    } 

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 43.4vw;
      height: 30vw;
    }

    .teamGrid{
      display: grid;
      grid-template-columns: repeat(2 , 1fr);
    }
  
}

@media only screen  
  and (min-width : 900px){
    
    .peopleContainer{
        margin : 2em 5vw;
    } 

    .teamGrid{
     display: grid;
      grid-template-columns: repeat(2 , 1fr);
    }

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 44.2vw;
      height: 37.7vw;
    }
}

@media only screen and (min-width: 1000px) {
    .peopleContainer{
        margin : 2em 3.3vw;
    } 

    .teamGrid{
      display: grid;
      grid-template-columns: repeat(3 , 1fr);
    }

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 29.8vw;
      height: 20vw;
    }

    .peopleCard p {
      margin-bottom: 1em;
    }

}



@media only screen and (min-width : 1280px) {
    .peopleContainer{
        margin : 2em 5vw;
    } 

    .teamGrid{
     display: grid;
      grid-template-columns: repeat(4 , 1fr);
    }

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 22vw;
      height: 16vw;
    }
}



/*LCD Displays*/
@media only screen 
  and (min-width: 1024px) 
  and (max-width: 1080px)  
  and (min-height : 800px) 
  and (max-height : 900px) {
    .peopleContainer{
        margin : 2em 3.3vw;
    } 

    .teamGrid{
     display: grid;
      grid-template-columns: repeat(4 , 1fr);
    }

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 22.9vw;
      height: 16vw;
    }
}


/* Basic Laptop Screens */
@media only screen and (min-width : 1440px) {
    .peopleContainer{
        margin : 2em 3.3vw;
    } 

    .teamGrid{
     display: grid;
      grid-template-columns: repeat(4 , 1fr);
    }

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 22.3vw;
      height: 16vw;
    }

}

/* Larger laptop screens */
@media only screen  and (min-width : 1500px) {
    .peopleContainer{
        margin : 2em 3.3vw;
    } 

    .teamGrid{
     display: grid;
      grid-template-columns: repeat(4 , 1fr);
    }

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 22.3vw;
      height: 16vw;
    }
}


/* Large screens ----------- */
@media only screen  and (min-width : 1600px) {
  
    .peopleContainer{
        margin : 2em 3.3vw;
    }   

    .teamGrid{
     display: grid;
      grid-template-columns: repeat(4 , 1fr);
    }

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 22.4vw;
      height: 16.6vw;
    }
}

@media only screen  and (min-width : 1700px) {
    .peopleContainer{
        margin : 2em 3.3vw;
    }   

    .teamGrid{
     display: grid;
      grid-template-columns: repeat(4 , 1fr);
    }

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 22.5vw;
      height: 16.6vw;
    }
}

@media only screen  and (min-width : 1900px) {
    .peopleContainer{
        margin : 2em 3.3vw;
    }  

    .teamGrid{
     display: grid;
      grid-template-columns: repeat(4 , 1fr);
    }

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 22.5vw;
      height: 16.6vw;
    }

 
}


@media only screen  and (min-width : 2000px) {
    .peopleContainer{
        margin : 2em 3.3vw;
    }  

    .teamGrid{
     display: grid;
      grid-template-columns: repeat(4 , 1fr);
    }

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 22.7vw;
      height: 16.6vw;
    }
  
}


/* Macbook Pro */
@media only screen 
  and (min-width: 1700px) 
  and (max-width: 1800px)  
  and (min-height : 1080px) 
  and (max-height : 1100px) {
    .peopleContainer{
        margin : 2em 3.3vw;
    } 

    .teamGrid{
     display: grid;
      grid-template-columns: repeat(4 , 1fr);
    }

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 22.5vw;
      height: 16.6vw;
    }

}


/* Potrait Orientation for Tablets and Mobiles */

@media only screen 
  and (min-width: 600px)
  and (max-width: 720px)
  and (orientation : portrait)
  and (-webkit-min-device-pixel-ratio: 1){
    .peopleContainer{
        margin : 2em 5.3vw;
    } 

    .teamGrid{
      display: grid;
      grid-template-columns: repeat(2 , 1fr);
    }

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 43.4vw;
      height: 36.4vw;
    }

}

@media only screen 
  and (min-width: 720px) 
  and (max-width: 769px) 
  and (orientation : portrait)
  and (-webkit-device-pixel-ratio: 1){
    .peopleContainer{
        margin : 2em 5.3vw;
    }

    .teamGrid{
     display: grid;
      grid-template-columns: repeat(2 , 1fr);
    }

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 43.4vw;
      height: 30vw;
    }

}


@media only screen 
  and (min-width: 769px) 
  and (max-width: 850px)
  and (max-height : 1300px)
  and (orientation : portrait){
    .peopleContainer{
        margin : 2em 5.3vw;
    }

    .teamGrid{
     display: grid;
      grid-template-columns: repeat(2 , 1fr);
    }

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 43.6vw;
      height: 32.5vw;
    }

}

@media only screen 
  and (min-width: 850px) 
  and (max-width: 899px) 
  and (orientation : portrait){
    .peopleContainer{
      margin : 2em 5.3vw;
  }

  .teamGrid{
   display: grid;
  }

  .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
    width: 43.6vw;
    height: 32.5vw;
  }

}




@media only screen 
  and (min-width: 900px) 
  and (max-width: 959px) 
  and (orientation : portrait){
    .peopleContainer{
      margin : 2em 5.3vw;
  }

  .teamGrid{
   display: grid;
    grid-template-columns: repeat(2 , 1fr);
  }

  .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
    width: 43.6vw;
    height: 32.5vw;
  }
}


/*Ipad Potrait*/
@media only screen 
and (min-width : 768px) 
and (max-width : 1024px)
and (max-height: 1024px) 
and (orientation : portrait) 
and (-webkit-min-device-pixel-ratio: 1)
{
    .peopleContainer{
        margin : 2em 5.3vw;
    }

    .teamGrid{
     display: grid;
      grid-template-columns: repeat(2 , 1fr);
    }

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 43.6vw;
      height: 32.5vw;
    }

  

}

/* IPad Pro 10.5 Portrait */
@media only screen 
  and (min-width: 834px)
  and (max-width: 834px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1)
  {
    .peopleContainer{
        margin : 2em 5.3vw;
    }

    .teamGrid{
      display: grid;
      grid-template-columns: repeat(2 , 1fr);
    }

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 43.6vw;
      height: 32.5vw;
    }

}

/* IPad Pro 12.5 Portrait */
@media only screen
and (min-width: 1024px)
and (max-width: 1024px)
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 1)
{
    .peopleContainer{
        margin : 2em 5.3vw;
    }

    .teamGrid{
      display: grid;
      grid-template-columns: repeat(2 , 1fr);
    }

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 44vw;
      height: 32.5vw;
    }


}







/* Landscape Orientation for Tablets and Mobiles */


/* Tablets and IPads */




@media only screen 
  and (min-width: 960px) 
  and (max-width: 1023px) 
  and (orientation : landscape){
    .peopleContainer{
        margin : 2em 3.3vw;
    }

    .teamGrid{
      display: grid;
      grid-template-columns: repeat(3 , 1fr);
    }

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 29.8vw;
      height: 20vw
    }
 
} 

@media only screen 
  and (min-width: 1023px) 
  and (max-width: 1179px) 
  and (orientation : landscape)
  and (-webkit-min-device-pixel-ratio: 2){

    .peopleContainer{
        margin : 2em 3.3vw;
    }

    .teamGrid{
      display: grid;
      grid-template-columns: repeat(3 , 1fr);
    }

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 29.8vw;
      height: 20vw
    }
  
}


@media only screen
  and (min-width: 1180px)
  and (max-width: 1194px)
  and (orientation: landscape){

    .peopleContainer{
        margin : 2em 3.3vw;
    } 

    .teamGrid{
      display: grid;
      grid-template-columns: repeat(3 , 1fr);
    }

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 29.8vw;
      height: 20vw
    }
}

@media only screen 
  and (min-width : 1280px) 
  and (min-height : 740px) 
  and (max-height : 750px) {

  .peopleContainer{
      margin : 2em 3.3vw;
  } 

  .teamGrid{
    display: grid;
    grid-template-columns: repeat(2 , 1fr);
  }

  .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
    width: 29.8vw;
    height: 20vw
  }

}

/*Ipad Landscape*/
@media only screen 
and (min-height : 768px)
and (max-width : 1024px)
and (orientation : landscape)
and (-webkit-min-device-pixel-ratio: 1) {
  
  .peopleContainer{
      margin : 2em 3.3vw;
  }

  .teamGrid{
    display: grid;
    grid-template-columns: repeat(3 , 1fr);
  }

  .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
    width: 29.8vw;
    height: 20vw
  }

 

}

/* IPad Pro 10.5 Landscape */
@media only screen 
  and (min-height: 834px)
  and (max-width: 1112px) 
  and (orientation: landscape){

    .peopleContainer{
        margin : 2em 3.3vw;
    }  

    .teamGrid{
      display: grid;
      grid-template-columns: repeat(3 , 1fr);
    }

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 29.8vw;
      height: 20vw
    }
}

/* IPad Pro 12.5 Landscape */
@media only screen
  and (min-height: 1024px)
  and (max-height: 1024px)
  and (orientation: landscape){
    .peopleContainer{
        margin : 2em 3.3vw;
    }

    .teamGrid{
      display: grid;
      grid-template-columns: repeat(3 , 1fr);
    }

    .peopleCard, .imageContainer, .card_overlay, .teamGrid a{
      width: 30.2vw;
      height: 23vw
    }
}



